import React from 'react'
import { Cancel, CheckBox, CheckCircle, Visibility, Add } from "@mui/icons-material"
import { Autocomplete, Badge, Button, ButtonGroup, Chip, CircularProgress, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import moment from "moment"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { center } from "../../assets/css/theme/common"
import ClickButton from "../../components/button/ClickButton"
import SubmitButton from "../../components/button/SubmitButton"
import { StyledSearchBar, UserSearchBarNormal } from "../../components/inputs/SearchBar"
import { CenteredBox } from "../../components/layouts/common/boxes"
import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker"
import { openModal } from "../../store/actions/modalAction"
import { REIMBURSEMENT_STATUS, USER_ROLES } from "../../utils/constants"
import { createBadge } from "../../utils/helper"
import leaveTheme from "../../assets/css/theme/leaveTheme"
import { useCallback } from "react";
import UploadSheetController from './UploadSheetController'



const GenerateSalarySlipUi = () => {

    const dispatch = useDispatch();
    const uploadSheet = useCallback(() => {
        dispatch(openModal({
            title:"Upload Sheet",
            component:<UploadSheetController />,size: 'sm'
        }))
    }, [])

  return (
    <div>
        <Box m = {3} > 
        <Typography sx={{
                    fontWeight: 700,
                    fontSize: "24px",
                    lineHeight: "40px",
                    color: "#0D0D0D"
                }}>Upload Sheet</Typography>

<Button onClick={uploadSheet} sx={{
                    display: "flex",
                    height: "100%",
                    zIndex: 0,
                    textTransform: "capitalize",
                    boxShadow: "none",
                    border: "1px solid #393939",
                    width: "100%",
                    height: "56px",
                    ":hover": {
                        boxShadow: "none"
                    }
                }}>
                    <Add color="primary" /> &nbsp;Upload CSV
                </Button>


            
        </Box>
    </div>
  )
}

export default GenerateSalarySlipUi