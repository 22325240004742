import { Avatar, Box, CircularProgress, Typography } from "@mui/material"
import { getFileOrImage } from "../../apis/file.api"
import { useEffect, useState } from "react"

const ImageComponent = ({ src, alt, imgStyle = {} ,isAvatar,avtarTitle}) => {
    
    const [img, setImage] = useState(false)
    const [loading, setLoading] = useState(true)

    const fetchImage = async () => {
        try{
            const mediaStream = await getFileOrImage({ fileUrl: src })
        

            if (window.URL && window.URL.createObjectURL) {
                 setImage(window.URL.createObjectURL(mediaStream));
            }
            else if (window.webkitURL && window.webkitURL.createObjectURL) {
                setImage( window.webkit.createObjectURL(mediaStream))
            }
            else {
                const errMessage = "Your browsers does not support URL.createObjectURL.";                        
            }
            setLoading(false)
        }catch(e){
            setLoading(false)
        }
       
    }
    useEffect(() => {
        fetchImage()
    }, [src])
    if (loading)
        return <CircularProgress />
        if(isAvatar){
            return  <Avatar src={img} sizes="large" sx={{ width: "130px", height: "130px", bgcolor: "primary.main" }}>
            <Typography sx={{ fontSize: "3.5rem" }}>
                {
                    avtarTitle
                }
            </Typography>
        </Avatar>
        }
    return <Box sx={{ width: "100%", height: "100%", background: 'lightgrey' }}>
        <img src={img} style={{ height: "100%", width: "100%", objectFit: "contain", ...imgStyle }} />
    </Box>
}
export default ImageComponent