import axios from 'axios'
import { getHeaders } from '../utils/helper'
import endpoints from './endpoints'

export const getLeaveCounts = async (params) => {
  const callResponse = await axios({
    url: endpoints.leaveCount,
    method: 'get',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const getLeavesApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leaveBase,
    method: 'get',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const getPendingLeavesApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.pendingLeaveBase,
    method: 'get',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const getCoffApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.coffs,
    method: 'get',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const getPendingCoffApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.pendingCOff,
    method: 'get',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const raiseLeaveApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leaveBase,
    method: 'POST',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const raiseCoffApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.raiseCOff,
    method: 'POST',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const getLeaveByIdApi = async (id) => {
  const callResponse = await axios({
    url: endpoints.leaveById + id,
    method: 'get',
    headers: getHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const getCoffByIdApi = async (id) => {
  const callResponse = await axios({
    url: endpoints.coffById + id,
    method: 'get',
    headers: getHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const updateLeaveStatus = async (data) => {
  const callResponse = await axios({
    url: endpoints.leaveBase,
    method: 'PATCH',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const updateCoffStatus = async (data) => {
  const callResponse = await axios({
    url: endpoints.coffs,
    method: 'PATCH',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}



export const getLogsApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchOneMonthLogs,
    method: 'get',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
