import { Box, ButtonBase, Typography, styled } from "@mui/material"
import { CALENDAR_ITEM_TYPES } from "../../../utils/constants"
import { center } from "../../../assets/css/theme/common"
import { TASK_STATUS } from "../../../utils/task.constants"
import { setStringView } from "../../../utils/helper"
import moment from "moment"

const TaskItem = styled(ButtonBase)(({ theme, color }) => (
    {
        borderRadius: theme.shape.borderRadius,
        marginBottom: 1,
        color,
        position: "relative",
        "::after": {
            borderRadius: theme.shape.borderRadius,
            content: "' '",
            position: "absolute",
            opacity: "0.2",
            top: "0px",
            left: "0px",
            background: color,
            height: "100%",
            width: "100%"
        }
    }
))

const PerformanceInnerBox = ({ data, weeklyOf, weekDay }) => {
    const isCompanyHoliday = data.find((item) => item.type == CALENDAR_ITEM_TYPES.holiday)
    const todaysDate = moment().valueOf()
    const isWeeklyOf = weeklyOf.includes(weekDay)

    const findColor = (status, completedDate, dueDate) => {
        if (status == TASK_STATUS.COMPLETED) {
            if (completedDate) {
                if (moment(completedDate).valueOf() > moment(dueDate).valueOf()) {
                    return "rgba(255, 24, 24, 1)"//red
                }
                return "rgba(55, 221, 151, 1)"//success
            } else {
                if (todaysDate > moment(dueDate).valueOf()) {
                    return "rgba(255, 24, 24, 1)"//red
                }
                return "rgba(255, 149, 0, 1)"//orange
            }            
        } else {
            if (todaysDate > moment(dueDate).valueOf()) {
                return "rgba(255, 24, 24, 1)"//red
            }
            return "rgba(255, 149, 0, 1)"//orange
            
        }

        return "grey"
    }
    return <Box sx={{ height: "100%", width: "100%", flexDirection: "column", justifyContent: "flex-end", display: "flex", flex: 1 }}>

        {
            data && data.map((item, id) => { if (item.type == CALENDAR_ITEM_TYPES.task) return <TaskItem color={findColor(item.status, item.completed_date, item.date)} onClick={item.onClick} key={id} sx={{ zIndex: 1 }}><Typography variant="body2">{setStringView(item.title)}</Typography></TaskItem> })
        }

        {
            isCompanyHoliday && isCompanyHoliday != -1 && <Box sx={{ zIndex: 1, background: "#D58C37", width: "100%", ...center }}>
                <Typography color="light.main" variant="h5">{isCompanyHoliday.title}</Typography>
            </Box>
        }
        {isWeeklyOf && <Box sx={{ zIndex: 1, background: "#D58C37", width: "100%", ...center }}>
            <Typography color="light.main" variant="h5">WO</Typography>
        </Box>}
        {(isWeeklyOf || (isCompanyHoliday && isCompanyHoliday != -1)) && <Box sx={{ zIndex: 0, top: "0px", width: "100%", position: "absolute", height: "100%", width: "100%", left: "0px", background: '#f1f1f1', ...center }}>

        </Box>}
    </Box>
}
export default PerformanceInnerBox