import moment from "moment"
import MessageDilog from "../../components/MessageDilog"
import { actions } from "../../utils/constants"
import { callApiAction } from "./commonAction"
import { openModal } from "./modalAction"
import { fetchHolidays } from "../../apis/holiday.api"
import { fetchleaveTypes } from "../../apis/leaveTypes"

export const fetchLeaveTypeListAction = () => {
    return async (dispatch, getState) => {

        dispatch({ type: actions.START_LEAVE_TYPE_COUNT_LOADING })

        dispatch(callApiAction(
            async () => await fetchleaveTypes({ sort: 'priority', sortDirection: 1 }),
            (response) => {
                if (response && response.result)
                    dispatch({ type: actions.SET_LEAVE_TYPE_COUNT_DATA, value: response.result })

            },
            (err) => {
                dispatch(openModal({component:<MessageDilog title="Something went wrong" message={err} />,size:"md"}))
            }
        ))
    }
}

