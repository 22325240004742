import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserByIdApi, updateUserField } from '../../apis/user.api'
import { callApiAction } from '../../store/actions/commonAction'
import { loggedInUser } from '../../utils/helper'
import HolidayUI from './HolidayUI'
import {
  createHoliday,
  fetchHolidays,
  deleteHoliday,
} from '../../apis/holiday.api'
import moment from 'moment'
const HolidayController = ({ userId }) => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const [state, setState] = useState([])
  const [loading, setLoading] = useState(false)
  const [fields, setFields] = useState({ year: moment() })

  const fetchHolidaysApi = fetchHolidays
  const deleteHolidayApi = deleteHoliday


  const fetchList = () => {
    setLoading(true)
    dispatch(
      callApiAction(
        async () => await fetchHolidaysApi({ date: fields.year.valueOf() }),
        (response) => {
          setState(response)
          // console.log(state)
          setLoading(false)
        },
        (err) => {
          setLoading(false)
        },
      ),
    )
  }

  const DeleteHoliday = (id) => {
    
    setLoading(true)
    dispatch(
      callApiAction(
        async () => await deleteHolidayApi({ id }),
        (response) => {
          setLoading(false)
          window.location.reload(true)
          // console.log(response)
        },
        (err) => {
          setLoading(false)
          console.log(err)
        },
      ),
    )
  }

  useEffect(() => {
    fetchList()
  }, [fields])

  return (
    <HolidayUI
      setFields={setFields}
      fields={fields}
      setState={setState}
      loading={loading}
      callBack={fetchList}
      state={state}
      DeleteHoliday={DeleteHoliday}
    />
  )
}
export default HolidayController
