import { Box, Chip, CircularProgress, IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tabs, Typography } from "@mui/material"
import { memo, useContext, useState } from "react"
import ClickButton from "../../../components/button/ClickButton"
import { ActionButton } from "../../../components/button/SubmitButton"
import { Add, Visibility } from "@mui/icons-material"
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"
import { CenteredBox } from "../../../components/layouts/common/boxes"
import moment from "moment"
import { EmployeeTaskUserContext } from "../EmployeeMain"
import { useDispatch, useSelector } from "react-redux"

import PriorityButton from "./PriorityButton"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import TaskDetailsController from "./TaskDetails.controller"
import { TASK_STATUS } from "../../../utils/task.constants"


const TaskRow = memo(({
    taskId,
    dueDate,
    title,
    id,
    assignedBy,
    status,
    assignedTo,
    assignedDate,
    isReopened,
    isDelayed,
    priority,
}) => {
    const selectedUser = useContext(EmployeeTaskUserContext)
    const dispatch = useDispatch()
    const onViewMore = () => {
        dispatch(openModal({ title: "Task Details", component: <TaskDetailsController selectedUser={selectedUser} id={id}></TaskDetailsController>, size: "md" }))
    }

    return <TableRow sx={{ background: isReopened ? "rgba(252, 241, 232, 1)" : (isDelayed ? "#FFD966" : "unset" )}}>
        <TableCell>{taskId}</TableCell>
        <TableCell>{dueDate}</TableCell>
        <TableCell>{title}</TableCell>
        <TableCell>{assignedBy}</TableCell>
        <TableCell>{assignedDate}</TableCell>
        <TableCell>{<PriorityButton status={status} assignedTo={assignedTo} selectedUser={selectedUser} priority={priority} id={id} />}</TableCell>
        <TableCell><IconButton onClick={onViewMore}><Visibility /></IconButton></TableCell>

    </TableRow>
})
const TaskTable = ({
    loading,
    filters,
    list,

    fetchList,
    onTabChange,
    tab,
    setFilters,
}) => {
    return <Box mt={4}>
        {!loading && list && list.result && list.result.length > 0 && <TableContainer>

            <Table>
                <TableHead sx={(theme) => ({ background: theme.palette.grey.light })}>
                    <TableRow>
                        <TableCell sx={{ minWidth: "200px" }}>Task Id</TableCell>
                        <TableCell sx={{ minWidth: "200px" }}>Due Date</TableCell>
                        <TableCell sx={{ minWidth: "200px" }}>Task Title</TableCell>
                        <TableCell sx={{ minWidth: "200px" }}>Assigned By</TableCell>
                        <TableCell sx={{ minWidth: "200px" }}>Assigned Date</TableCell>
                        <TableCell sx={{ minWidth: "200px" }}>Priority</TableCell>
                        <TableCell sx={{ minWidth: "200px" }}>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        list.result.map((item) => {
                            return <TaskRow
                                taskId={item.task_id}
                                status={item.status}
                                assignedTo={item.assigned_to ? [item.assigned_to].map((assignedUser) => assignedUser) : []}
                                key={item._id}
                                dueDate={moment(item.due_date).format("DD MMM YYYY")}

                                title={item.name}
                                id={item._id}
                                assignedBy={item.assigned_by_name}
                                assignedDate={moment(item.start_date).format("DD MMM YYYY")}
                                priority={item.priority}
                                isDelayed={(item.status != TASK_STATUS.COMPLETED && item.status != TASK_STATUS.MARKED_AS_COMPLETED) &&( moment(item.due_date).valueOf() < moment().valueOf())}
                                isReopened={(item.child_task && item.child_task.length > 0) || (item.parent_task && item.parent_task.length > 0)}
                            />
                        })
                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}

                            count={list.total}
                            rowsPerPage={filters.pageSize}
                            page={filters.pageNo - 1}

                            onPageChange={(e, pageSize) => { setFilters({ ...filters, pageSize }) }}
                            onRowsPerPageChange={(e) => { setFilters({ ...filters, pageNo: parseInt(e.target.value, 10) }) }}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>}
        {
            loading && <CenteredBox><CircularProgress /></CenteredBox>
        }
        {
            !loading && (!list.result || list.result.length == 0) && <CenteredBox mt={4} mb={4}><Typography variant="h3">No Tasks Found</Typography></CenteredBox>
        }
    </Box>
}
export default TaskTable