import axios from 'axios'
import { refreshToken } from '../utils/helper'
import { getHeaders } from '../utils/helper'
import endpoints from './endpoints'

export const createDailyUpdate = async (data) => {
  const callResponse = await axios({
    url: endpoints.dailyUpdateBase,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => {
      
      return response.data
    })
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const getAllDailyUpdates = async (data) => {
  const callResponse = await axios({
    url: endpoints.getDailyUpdateOnDate,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const getDailyUpdateOnDate = async (params) => {
  const callResponse = await axios({
    url: endpoints.dailyUpdateBase,
    method: 'get',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
