import { Avatar, Box, Button, ButtonGroup, CircularProgress, Grid, IconButton, Link, Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography, TableBody, ButtonBase } from "@mui/material"
import { CenteredBox } from "../../components/layouts/common/boxes"
import { Cancel, CheckBox, CheckCircle, Visibility, Add } from "@mui/icons-material"
import DeleteIcon from '@mui/icons-material/Delete';
import { center } from "../../assets/css/theme/common"
import { Skeleton } from "@mui/material";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import AddBranchController from "./AddBranchController";
import LaunchIcon from '@mui/icons-material/Launch';
import UpdateBranchController from "./UpdateBranchController";

const BranchUI = ({ setState, callBack, loading, state, DeleteBranch }) => {
    const dispatch = useDispatch();
    const addholiday = useCallback(() => {
        dispatch(openModal({
            title:"Add Branch",
            component: <AddBranchController callBack={callBack} />, size: 'sm'
        }))
    }, [])
    const UpdateBranch = useCallback((id, intialName, initialAddress) => {
        dispatch(openModal({
            title:"Update Branch",
            component: <UpdateBranchController callBack={callBack} id={id} intialName={intialName} initialAddress={initialAddress} />, size: 'sm'
        }))
    }, [])
    return <>
        {
            !state && !loading && <CenteredBox><Typography variant="h3">Loading Data...</Typography></CenteredBox>
        }
        <Box m={3}>
            <Box>
                <Typography sx={{
                    fontWeight: 700,
                    fontSize: "24px",
                    lineHeight: "40px",
                    color: "#0D0D0D"
                }}>Branch</Typography>
                <Button onClick={addholiday} sx={{
                    display: "flex",
                    height: "100%",
                    zIndex: 0,
                    textTransform: "capitalize",
                    boxShadow: "none",
                    border: "1px solid #393939",
                    width: "100%",
                    height: "56px",
                    ":hover": {
                        boxShadow: "none"
                    }
                }}>
                    <Add color="primary" /> &nbsp;Add Branch
                </Button>
            </Box>
            <TableContainer component={Box} mt={3} sx={{
                boxShadow: "0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)",
                borderRadius: "4px",
                border: "1px solid #BFBFBF"
            }}>
                <Table aria-label="collapsible table" sx={{}}>
                    <TableHead sx={{ background: "#F2F2F2" }}>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            loading &&
                            <>
                                <TableRow ><TableCell colSpan={5}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                <TableRow ><TableCell colSpan={5}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                <TableRow ><TableCell colSpan={5}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                            </>
                        }
                        {!loading && state && state.map((branch) => (
                            <TableRow
                                key={branch._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{branch.name}</TableCell>
                                <TableCell>{branch.address}</TableCell>
                                <TableCell sx={{ display: "flex", gap: "3px" }}>
                                    <ButtonBase onClick={() => {
                                        
                                        DeleteBranch(branch._id)
                                    }}>
                                        <DeleteIcon />
                                    </ButtonBase>
                                    <ButtonBase onClick={() => {
                                        
                                        UpdateBranch(branch._id, branch.name, branch.address)
                                    }}>
                                        <LaunchIcon />
                                    </ButtonBase>
                                </TableCell>
                            </TableRow>
                        ))}
                        {!loading && state.length == 0 &&
                            <TableRow >
                                <TableCell colSpan={5} align="Center" ><Typography sx={{ ...center }} variant="h3" >No Data</Typography> </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </>
}
export default BranchUI