import { East, KeyboardArrowRight, West } from "@mui/icons-material"
import { ButtonBase, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { center } from "../../assets/css/theme/common"
import { CALENDAR_ITEM_TYPES, HALF_TYPE_OBJ } from "../../utils/constants"

const CalendarBox = ({ data, date, weekDay, weeklyOf, ...props }) => {

    const holiday = data.find((item) => item.type == CALENDAR_ITEM_TYPES.holiday)
    const isCompanyHoliday = !!holiday



    const isWeeklyOf = weeklyOf.includes(weekDay)


    return (
        <Box sx={{ height: "100%", width: "100%", flexDirection: "column", display: "flex", flex: 1 }}>
            {
                // for CHECKIN ,CHECKOUT logs 
            }
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }} p={1} mb={2}>
                {
                    data && Array.isArray(data) && data.map((item) => (item.type == CALENDAR_ITEM_TYPES.checkInLog || item.type == CALENDAR_ITEM_TYPES.checkOUTLog) ? (
                        <Box key={item.title}>
                            <Typography sx={{ color: "rgba(233, 159, 12, 1) !important" }} variant="subtitle2"> {item.type == CALENDAR_ITEM_TYPES.checkInLog && <East fontSize="inherit" />} {item.type == CALENDAR_ITEM_TYPES.checkOUTLog && <West fontSize="inherit" />} {item.title}</Typography>
                        </Box>
                    ) : (<></>))
                }
            </Box>

            {
                // for Leaves & coffs
            }
            <Box>
                {
                    data && Array.isArray(data) && data.map((item) =>
                        ((item.type != CALENDAR_ITEM_TYPES.checkInLog &&
                            item.type != CALENDAR_ITEM_TYPES.checkOUTLog) &&
                            (item.type == CALENDAR_ITEM_TYPES.coff || item.type == CALENDAR_ITEM_TYPES.leave))
                            ?
                            (
                                (isWeeklyOf || (isCompanyHoliday && isCompanyHoliday == true)) &&
                                    item.type == CALENDAR_ITEM_TYPES.leave ?
                                    <></>
                                    :
                                    ((item.isStarting && item.fromDuration != HALF_TYPE_OBJ.FULL_DAY) || (item.isEnding && item.toTimeDuration != HALF_TYPE_OBJ.FULL_DAY)) ? <Box sx={{ display: "flex" }}>
                                        {(item.toTimeDuration == HALF_TYPE_OBJ.SECOND_HALF || item.fromDuration == HALF_TYPE_OBJ.SECOND_HALF) && <Box sx={{ display: "flex", flex: 1 }}>

                                        </Box>}
                                        <Box sx={{ display: "flex", flex: 1 }}>
                                            <ButtonBase onClick={item.onClick} key={item.title} sx={{ width: "100%", background: item.color ?? "grey", ...center, zIndex: 1 }}>
                                                <Typography color="light.main" variant="h5">{item.title}</Typography>
                                            </ButtonBase>
                                        </Box>
                                        {(item.toTimeDuration == HALF_TYPE_OBJ.FIRST_HALF || item.fromDuration == HALF_TYPE_OBJ.FIRST_HALF) && <Box sx={{ display: "flex", flex: 1 }}>

                                        </Box>}

                                    </Box> :
                                        <ButtonBase onClick={item.onClick} key={item.title} sx={{ width: "100%", background: item.color ?? "grey", ...center, zIndex: 1 }}>
                                            <Typography color="light.main" variant="h5">{item.title}</Typography>
                                        </ButtonBase>
                            ) : (<></>))
                }
            </Box>


            {
                // for weekly off & holidaY 
            }
            {(isWeeklyOf || isCompanyHoliday) && <Box sx={{ zIndex: 1, background: "#D58C37", width: "100%", ...center }}>
                <Typography color="light.main" variant="h5"> {isCompanyHoliday ? holiday.title : 'WO'}</Typography>
            </Box>}
            {(isWeeklyOf || isCompanyHoliday) && <Box sx={{ zIndex: 0, top: "0px", width: "100%", position: "absolute", height: "100%", width: "100%", left: "0px", background: '#f1f1f1', ...center }}>

            </Box>}

        </Box>
    )
}
export default CalendarBox