import { Grid, Paper, Skeleton, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { center } from '../../assets/css/theme/common'
import { fetchLeaveBalanceCountAction } from '../../store/actions/leaveBalanceAction'
import { fetchUserDetails } from '../../store/actions/userReducerAction'
import LeaveBalanceLogo from '../../assets/images/LeaveBalance.png'
import leaveTheme from '../../assets/css/theme/leaveTheme'
import moment from 'moment'

const LeavecountBoxOuter = styled(Box)(({ theme, color }) => ({
  display: "flex",
  height: "100%",
  width: '100%',
  // borderRadius: theme.shape.borderRadius,
  position: "relative",

  backdropFilter: "opacity(0)",
  alignItems: "center",
  color: "black",
  '&::before': {
    content: '" "',
    display: 'block',
    position: "absolute",
    left: "0px",
    backgroundColor: color,
    width: "3px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    height: "50%",
    top: "25%"
  },

  '&::after': {
    content: '" "',
    display: 'block',
    position: "absolute",
    left: "0px",
    zIndex: -1,
    top: "0px",
    backgroundColor: color,
    borderRadius: theme.shape.borderRadius,
    opacity: 0.1,
    width: "100%",
    height: "100%",

  }

}))


const LeaveCountBox = ({ title, subTitle, count, color }) => {
  return <LeavecountBoxOuter color={color} p={2}>
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }} p={1} pl={2}>
      <Typography variant='h6'>
        {title}
      </Typography>
      <Typography variant='h6' fontWeight={"bold"}>
        ({subTitle})
      </Typography>
    </Box>
    <Box sx={{ display: "flex" }} p={1} pr={2}>
      <Typography variant='h1'>
        {count}
      </Typography>
    </Box>
  </LeavecountBoxOuter>
}

const LeaveBalance = ({ direction, userId }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { leaveBalance } = useSelector((state) => state)

  useEffect(() => {
    dispatch(fetchLeaveBalanceCountAction({ userId, date: moment().valueOf() }))
  }, [userId])


  const isSmallView = useMediaQuery(theme.breakpoints.down('md'))

  direction = isSmallView?"column":direction

  return (
    <Box sx={{ display: 'flex', alignItems: "center", flexWrap: "wrap", height: "100%" }}>
      {direction != "column" &&
        <Box sx={{ display: "flex", alignItems: 'center', mr: 2 }}>
          <Box mr={3}>
            <img src={LeaveBalanceLogo} height="52px" width="52px" />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant='h3' mb={1}>
              Leave
            </Typography>
            <Typography variant='h3'>
              Balance
            </Typography>
          </Box>
        </Box>}
      <Box sx={{ display: "flex", flex: 1, height: "100%" }}>

        {
          leaveBalance.loading && <Grid container spacing={2}>
            <Grid item xs={direction == "column" ? 6 : 3}>
              <Skeleton width="100%" height={"65px"} />
            </Grid>
            <Grid item xs={direction == "column" ? 6 : 3}>
              <Skeleton width="100%" height={"65px"} />
            </Grid>
            <Grid item xs={direction == "column" ? 6 : 3}>
              <Skeleton width="100%" height={"65px"} />
            </Grid>
            <Grid item xs={direction == "column" ? 6 : 3}>
              <Skeleton width="100%" height={"65px"} />
            </Grid>

          </Grid>
        }


        {
          !leaveBalance.loading && leaveBalance.data && leaveBalance.data.data && leaveBalance.data.data.leaves && <Grid container spacing={2}>
            {leaveBalance.data.data.leaves.map((leave) => (<Grid item xs={direction == "column" ? 6 : (12 /( leaveBalance.data.data.leaves.length+1))} >
              <LeaveCountBox
                title={leave.leave_type && leave.leave_type.name}
                subTitle={leave.leave_type && leave.leave_type.short_name}
                count={leave.count}
                color={leave.leave_type.color}
              />
            </Grid>
            ))}


{!leaveBalance.loading && leaveBalance.data && leaveBalance.data.data && leaveBalance.data.data &&<Grid item xs={direction == "column" ? 6 : (12 /( leaveBalance.data.data.leaves.length+1))} >
              <LeaveCountBox
                title={"LWP"}
                subTitle={"LWP"}
                count={leaveBalance.data.data.lwp}
                color={'red'}
              />
            </Grid>}
          </Grid>
        }


      </Box>
    </Box>
  )


  // if (direction == 'column')
  //   return (
  //     <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
  //       <Typography variant="h3" color={'primary.main'}>
  //         Leave Balance
  //       </Typography>
  //       <Grid container columnSpacing={'14px'} rowSpacing={'14px'} mt={'0px'}>
  //         {leaveBalance.loading && (
  //           <>
  //             <Grid item xs={6}>
  //               <Skeleton
  //                 sx={{
  //                   flexDirection: 'column',
  //                   height: '100%',
  //                   ...center,
  //                   flex: 1,
  //                   position: 'relative',
  //                 }}
  //                 animation="wave"
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               <Skeleton
  //                 sx={{
  //                   flexDirection: 'column',
  //                   height: '100%',
  //                   ...center,
  //                   flex: 1,
  //                   position: 'relative',
  //                 }}
  //                 animation="wave"
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               <Skeleton
  //                 sx={{
  //                   flexDirection: 'column',
  //                   height: '100%',
  //                   ...center,
  //                   flex: 1,
  //                   position: 'relative',
  //                 }}
  //                 animation="wave"
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               <Skeleton
  //                 sx={{
  //                   flexDirection: 'column',
  //                   height: '100%',
  //                   ...center,
  //                   flex: 1,
  //                   position: 'relative',
  //                 }}
  //                 animation="wave"
  //               />
  //             </Grid>
  //           </>
  //         )}

  //         {!leaveBalance.loading &&
  //           leaveBalance.data.data &&
  //           leaveBalance.data.data.leaves &&
  //           leaveBalance.data.data.leaves.map((item) => {
  //             if (item.leave_type) {
  //               return (
  //                 <Grid item xs={6}>
  //                   <LeaveViewContainerSmall
  //                     key={item.itemType}
  //                     sx={{ backgroundColor: `lt.${item.leave_type.short_name}.light` }}
  //                   >
  //                     <Box
  //                       sx={smallBox}
  //                       bgcolor={`lt.${item.leave_type.short_name}.dark`}
  //                     ></Box>
  //                     <Box sx={{ position: 'absolute', left: '6px' }}>
  //                       <Typography
  //                         align="left"
  //                         sx={{ fontSize: '14px' }}
  //                         color={'text.300'}
  //                       >
  //                         {item.leave_type &&
  //                           item.leave_type.name}
  //                       </Typography>
  //                       <Typography
  //                         align="left"
  //                         fontWeight={500}
  //                         sx={{ fontSize: '18px' }}
  //                       >
  //                         ({item.leave_type.short_name.toUpperCase()})
  //                       </Typography>
  //                     </Box>
  //                     <Box>
  //                       <Typography variant="h2" color={'primary.main'}>
  //                         {item.leaveBalance}
  //                       </Typography>
  //                     </Box>
  //                   </LeaveViewContainerSmall>
  //                 </Grid>
  //               )
  //             }
  //           })}
  //       </Grid>
  //     </Box>
  //   )


  // return (
  //   <Box
  //     sx={{
  //       display: 'flex',
  //       height: '48px',
  //       width: '100%',
  //       alignItems: 'center',
  //       marginTop: '28px',
  //       marginBottom: '0',
  //     }}
  //     mt={3}
  //     mb={3}
  //   >
  //     <Box
  //       sx={{
  //         width: '15%',
  //         ...center,
  //         justifyContent: 'flex-start',
  //         gap: '11px',
  //       }}
  //     >
  //       <Box>
  //         <img src={LeaveBalanceLogo} height="52px" width="52px" />
  //       </Box>
  //       <Box
  //         sx={{ ...center, alignItems: 'flex-start', flexDirection: 'column' }}
  //       >
  //         <Typography variant="h3" color={'primary'} sx={{ fontWeight: 500 }}>
  //           Leave
  //         </Typography>
  //         <Typography variant="h3" color={'primary'} sx={{ fontWeight: 500 }}>
  //           Balance
  //         </Typography>
  //       </Box>
  //     </Box>
  //     {leaveBalance.loading && (
  //       <Box sx={{ display: 'flex', flex: 1 }}>
  //         {[0, 1, 2, 3].map((index) => (
  //           <LeaveViewContainer key={index}>
  //             <Skeleton
  //               sx={{ minHeight: '100px', width: '100%' }}
  //               animation="wave"
  //             />
  //           </LeaveViewContainer>
  //         ))}
  //       </Box>
  //     )}


  //     {!leaveBalance.loading && leaveBalance.data.data && leaveBalance.data.data.leaves && <Grid container sx={{ width: "85%" }} spacing={2}>

  //       {leaveBalance.data.data.leaves.map((item) => {

  //         if (item.leave_type) {
  //           return (

  //             <LeaveViewContainer key={item.leave_type.name} sx={{ backgroundColor:'red'}}>
  //               <Box sx={smallBox} bgcolor={item.leave_type.color}></Box>
  //               <LeaveViewTextContainer>
  //                 <Box sx={{ position: 'absolute', left: '6px' }}>
  //                   <Typography
  //                     align="left"
  //                     variant="h6"
  //                     color={'text.300'}
  //                     sx={LeaveTitle}
  //                   >
  //                     {item.leave_type.name}
  //                   </Typography>
  //                   <Typography align="left" variant="h6" sx={{ fontWeight: 400 }}>
  //                     {item.leave_type.short_name}
  //                   </Typography>
  //                 </Box>
  //                 <Typography
  //                   variant="h1"
  //                   color={'primary.main'}
  //                   sx={{
  //                     fontWeight: 500,
  //                     position: 'absolute',
  //                     right: '3px',
  //                     fontSize: '40px',
  //                   }}
  //                 >
  //                  {item.count}
  //                 </Typography>
  //               </LeaveViewTextContainer>
  //             </LeaveViewContainer>
  //             // <Grid item sm={3}>
  //             //     <LeaveViewContainer key={item.itemType}>
  //             //         <Box sx={smallBox}></Box>
  //             //         <LeaveViewTextContainer>
  //             //             <Box sx={{ position: "absolute", left: "6px" }}>
  //             //                 <Typography align="left" variant="h6" color={"text.300"} sx={LeaveTitle}>
  //             //                     {item.leave_type && item.leave_type.name}
  //             //                 </Typography>
  //             //                 <Typography align="left" variant="h3" sx={{ fontWeight: 400 }}>
  //             //                     ({item.leave_type.short_name.toUpperCase()})
  //             //                 </Typography>
  //             //             </Box>
  //             //             <Typography variant="h1" color={"primary.main"} sx={{ fontWeight: 500, position: "absolute", right: "3px" }}>
  //             //                 {item.count}
  //             //             </Typography>
  //             //         </LeaveViewTextContainer>
  //             //     </LeaveViewContainer>
  //             // </Grid>


  //           )
  //         }
  //       })}


  //     </Grid>}
  //     {/* {!leaveBalance.loading && leaveBalance.data.data && leaveBalance.data.data.leaves && (
  //       <Box sx={{ display: 'flex', flex: 1, height: '72px' }}>
  //         <LeaveViewContainer sx={{ backgroundColor: leaveTheme.cl.light }}>
  //           <Box sx={smallBox} bgcolor={leaveTheme.cl.dark}></Box>
  //           <LeaveViewTextContainer>
  //             <Box sx={{ position: 'absolute', left: '6px' }}>
  //               <Typography
  //                 align="left"
  //                 variant="h6"
  //                 color={'text.300'}
  //                 sx={LeaveTitle}
  //               >
  //                 {leaveBalance.data.data.leaves[1].leave_type &&
  //                   leaveBalance.data.data.leaves[1].leave_type.name}
  //               </Typography>
  //               <Typography align="left" variant="h6" sx={{ fontWeight: 400 }}>
  //                 ({leaveBalance.data.data.leaves[1].leaveType.toUpperCase()})
  //               </Typography>
  //             </Box>
  //             <Typography
  //               variant="h1"
  //               color={'primary.main'}
  //               sx={{
  //                 fontWeight: 500,
  //                 position: 'absolute',
  //                 right: '3px',
  //                 fontSize: '40px',
  //               }}
  //             >
  //               {leaveBalance.data.data.leaves[1].leaveBalance}
  //             </Typography>
  //           </LeaveViewTextContainer>
  //         </LeaveViewContainer>
  //         <LeaveViewContainer
  //           sx={{ marginLeft: '14px', backgroundColor: leaveTheme.sl.light }}
  //         >
  //           <Box sx={smallBox} bgcolor={leaveTheme.sl.dark}></Box>
  //           <LeaveViewTextContainer>
  //             <Box sx={{ position: 'absolute', left: '6px' }}>
  //               <Typography
  //                 align="left"
  //                 variant="h6"
  //                 color={'text.300'}
  //                 sx={LeaveTitle}
  //               >
  //                 {leaveBalance.data.data.leaves[0].leave_type &&
  //                   leaveBalance.data.data.leaves[0].leave_type.name}
  //               </Typography>
  //               <Typography align="left" variant="h6" sx={{ fontWeight: 400 }}>
  //                 ({leaveBalance.data.data.leaves[0].leaveType.toUpperCase()})
  //               </Typography>
  //             </Box>
  //             <Typography
  //               variant="h1"
  //               color={'primary.main'}
  //               sx={{
  //                 fontWeight: 500,
  //                 position: 'absolute',
  //                 right: '3px',
  //                 fontSize: '40px',
  //               }}
  //             >
  //               {leaveBalance.data.data.leaves[0].leaveBalance}
  //             </Typography>
  //           </LeaveViewTextContainer>
  //         </LeaveViewContainer>
  //         <LeaveViewContainer
  //           sx={{ marginLeft: '14px', backgroundColor: leaveTheme.pl.light }}
  //         >
  //           <Box sx={smallBox} bgcolor={leaveTheme.pl.dark}></Box>
  //           <LeaveViewTextContainer>
  //             <Box sx={{ position: 'absolute', left: '6px' }}>
  //               <Typography
  //                 align="left"
  //                 variant="h6"
  //                 color={'text.300'}
  //                 sx={LeaveTitle}
  //               >
  //                 {leaveBalance.data.data.leaves[2].leave_type &&
  //                   leaveBalance.data.data.leaves[2].leave_type.name}
  //               </Typography>
  //               <Typography align="left" variant="h6" sx={{ fontWeight: 400 }}>
  //                 ({leaveBalance.data.data.leaves[2].leaveType.toUpperCase()})
  //               </Typography>
  //             </Box>
  //             <Typography
  //               variant="h1"
  //               color={'primary.main'}
  //               sx={{
  //                 fontWeight: 500,
  //                 position: 'absolute',
  //                 right: '3px',
  //                 fontSize: '40px',
  //               }}
  //             >
  //               {leaveBalance.data.data.leaves[2].leaveBalance}
  //             </Typography>
  //           </LeaveViewTextContainer>
  //         </LeaveViewContainer>
  //         <LeaveViewContainer
  //           sx={{ marginLeft: '14px', backgroundColor: leaveTheme.coff.light }}
  //         >
  //           <Box sx={smallBox} bgcolor={leaveTheme.coff.dark}></Box>
  //           <LeaveViewTextContainer>
  //             <Box sx={{ position: 'absolute', left: '6px' }}>
  //               <Typography
  //                 align="left"
  //                 variant="h6"
  //                 color={'text.300'}
  //                 sx={LeaveTitle}
  //               >
  //                 {leaveBalance.data.data.leaves[3].leave_type &&
  //                   leaveBalance.data.data.leaves[3].leave_type.name}
  //               </Typography>
  //               <Typography align="left" variant="h6" sx={{ fontWeight: 400 }}>
  //                 ({leaveBalance.data.data.leaves[3].leaveType.toUpperCase()})
  //               </Typography>
  //             </Box>
  //             <Typography
  //               variant="h1"
  //               color={'primary.main'}
  //               sx={{
  //                 fontWeight: 500,
  //                 position: 'absolute',
  //                 right: '3px',
  //                 fontSize: '40px',
  //               }}
  //             >
  //               {leaveBalance.data.data.leaves[3].leaveBalance}
  //             </Typography>
  //           </LeaveViewTextContainer>
  //         </LeaveViewContainer>
  //       </Box>
  //     )} */}
  //   </Box>
  // )
}
export default memo(LeaveBalance)
