import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import useValidate from '../../../store/hooks/useValidator'

import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../store/actions/commonAction'

import moment from 'moment'

import { useNavigate } from 'react-router-dom'

import { TASK_PRIORITY, TASK_STATUS, TASK_TYPES } from '../../../utils/task.constants'
import { createTaskApi, fetchTasksApi, updateTasksPriorityApi } from '../../../apis/task.api'
import { callSnackBar } from '../../../store/actions/snackbarAction'
import { SNACK_BAR_VARIETNS } from '../../../utils/constants'
import { useSnackbar } from 'notistack'
import TaskListUi from './TasksList'
import { EmployeeTaskUserContext } from '../EmployeeMain'
import { getDateFiltersTime } from '../../../utils/helper'
import TaskTable from './TaskTable'

const TaskController = ({ notMe, callBack = () => { } }) => {
  const validate = useValidate()
  const dispatch = useDispatch()
  const { leaveBalance, holiday, user } = useSelector((state) => state)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const selectedUser = useContext(EmployeeTaskUserContext)
  const [filters, setFilters] = useState({
    err: '',
    pageNo: 1,
    pageSize: 25,
    startDate: null,
    endDate: null,
    status:notMe?TASK_STATUS.MARKED_AS_COMPLETED: undefined,
    onlyMe: !notMe
  })
  const [list, setList] = useState({})
  const [tab, setTab] = useState(0)


  const fetchList = async (e) => {
    setLoading(true)
    dispatch(
      callApiAction(
        async () => await fetchTasksApi({ ...filters, userId: selectedUser ? selectedUser._id : null }),
        async (response) => {
          setLoading(false)
          setList(response)
        },
        (err) => {
          setLoading(false)
          setList({})
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
        },
      ),
    )

  }



  useEffect(() => {
    fetchList()
  }, [filters, selectedUser])


  const onTabChange = (e, tabIndex) => {
    setTab(tabIndex)
    
    if (tabIndex == 0) {
      setFilters({
        ...filters,
        startDate: null,
        endDate: null,
        status: undefined
      })
    }


    if (tabIndex == 1) {
      const dates = getDateFiltersTime('today')
      setFilters({
        ...filters,
        startDate: dates.startDate,
        endDate: dates.endDate,
        status: undefined
      })
    }
    if (tabIndex == 2) {
      const dates = getDateFiltersTime('this_week')
      setFilters({
        ...filters,
        startDate: dates.startDate,
        endDate: dates.endDate,
        status: undefined
      })
    }
    if (tabIndex == 3) {
      setFilters({
        ...filters,
        startDate: null,
        endDate: null,
        status: TASK_STATUS.MARKED_AS_COMPLETED
      })
    }
    if (tabIndex == 4) {
      setFilters({
        ...filters,
        startDate: null,
        endDate: null,
        status: TASK_STATUS.COMPLETED
      })
    }


  }
  const onAddButtonClick = () => {
    navigate('/tasks/my-tasks/create-task')
  }
  if (notMe)
 return   <TaskTable

      loading={loading}
      filters={filters}
      list={list}
      fetchList={fetchList}

      setFilters={setFilters}
    />

  return (
    <TaskListUi

      onAddButtonClick={onAddButtonClick}
      loading={loading}
      filters={filters}
      list={list}
      fetchList={fetchList}
      onTabChange={onTabChange}
      tab={tab}
      setFilters={setFilters}
    />
  )
}
export default memo(TaskController)
