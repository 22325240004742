import {
    Box,
    Button,
    Checkbox,
    Collapse,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Slide,
    Typography,
    TextField,
    CircularProgress, Autocomplete, Switch
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomInput from "../../components/inputs/CustomInput";
import { CenteredBox } from "../../components/layouts/common/boxes";
import SubmitButton from "../../components/button/SubmitButton";

const AddLeaveTypesUI = ({ loading, setFields, fields, fetchLoading, addLeaveType, isUpdate }) => {
    return (
        <>
            <Box
                component="form"
                onSubmit={addLeaveType}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    position: "relative",
                }}
                maxHeight="100%"
            >                
                
                <Typography color="error">{fields.err}</Typography>
                
                
                    {
                        fetchLoading && <CenteredBox><CircularProgress /></CenteredBox>
                    }
                    {
                        !fetchLoading && <>

                            <CustomInput
                                disabled={loading}
                                value={fields.name}
                                onChange={(e) => setFields({ ...fields, name: e.target.value })}
                                type="text"
                                placeholder="Write Name..."
                                label="Name*"
                            />
                            <CustomInput
                                disabled={loading}
                                value={fields.short_name}
                                onChange={(e) => setFields({ ...fields, short_name: e.target.value })}
                                type="text"
                                placeholder="Write Name..."
                                label="Short Name*"
                            />

                            <CustomInput
                                disabled={loading}
                                value={fields.color}
                                onChange={(e) => setFields({ ...fields, color: e.target.value })}
                                type="color"
                                placeholder="Select color..."
                                label=" Color*"
                            />
                            <CustomInput
                                disabled={loading}
                                value={fields.priority}
                                onChange={(e) => setFields({ ...fields, priority: e.target.value })}
                                type="number"
                                placeholder="Select priority..."
                                label=" Priority*"
                            />
                            <CustomInput
                                disabled={loading}
                                value={fields.per_month_count}
                                onChange={(e) => setFields({ ...fields, per_month_count: e.target.value })}
                                type="number"
                                placeholder="Allowed leave per month"
                                label=" Allowed leave per month*"
                            />
                            <CustomInput
                                disabled={loading}
                                value={fields.identifier}
                                onChange={(e) => setFields({ ...fields, identifier: e.target.value })}
                                type="text"
                                placeholder="identififer(devloper purpose)"
                                label=" Identifier*"
                            />
                            <FormControlLabel
                                
                                control={<Switch checked={fields.yearly_carry_forward} onChange={(e) => setFields({ ...fields, yearly_carry_forward: e.target.checked })} color="primary" />}
                                label="Is yearly carry forward"
                                labelPlacement="top"
                            />
                            
                        </>
                    }
                
                <DialogActions>
                    <Box sx={{ float: "right", marginTop: "7px" }}>
                        <SubmitButton
                            loading={loading}
                            type="submit"
                            variant="contained"
                            title={`${isUpdate ? "Update" : "Add"} Leave`}
                            color="primary"
                        >
                            
                        </SubmitButton>
                    </Box>
                </DialogActions>
            </Box>
        </>
    );
};

export default AddLeaveTypesUI;