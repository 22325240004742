import { memo, useEffect, useMemo, useState } from 'react'
import useValidate from '../../../store/hooks/useValidator'

import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../store/actions/commonAction'

import { closeModal } from '../../../store/actions/modalAction'

import LeaveRaiseUi from './LeaveRaiseUi'
import { raiseLeaveApi } from '../../../apis/leave.api'
import moment from 'moment'
import { HALF_TYPE, HALF_TYPE_OBJ } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'

const LeaveRaiseController = ({ userId, weeklyOf, callBack = () => { } }) => {
  const validate = useValidate()
  const dispatch = useDispatch()

  const { leaveBalance, holiday, user } = useSelector((state) => state)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const [fields, setFields] = useState({
    err: '',
    applicationDate: moment(),
    userId: userId,
    leave_type: null,
    lwp: false,
    remarks: '',
    from: moment(),
    to: moment(),
    from_time_duration: HALF_TYPE_OBJ.FULL_DAY,
    to_time_duration: HALF_TYPE_OBJ.FULL_DAY,

    availableLeave: 0,
    moreThanOneDay: false,

    leave_count: 0,
  })

  const validationSchema = useMemo(
    () => [
      {

        value: fields.leave_type,
        custom: () => {
          if (fields.leave_count  > 1|| fields.lwp) {
            return true
          } else {
            if (!fields.leave_type || fields.leave_type == '')
              return false
            return true
          }
        },
        field: 'Leave Type',
      },
      {
        required: true,
        value: fields.from,
        field: 'Leave From Date ',
        custom: () => {
          return !disableDates(fields.from)
        },
      },
      {
        required: true,
        value: fields.to,
        field: 'Leave To Date ',
        custom: () => {
          return !disableDates(fields.to)
        },
      },
      {
        required: true,
        value: fields.remarks,
        field: 'Remarks',
      },
    ],
    [fields],
  )

  const disableDates = (date) => {
    let isHoliday = false
    if (holiday.data && Array.isArray(holiday.data)) {
      for (let i = 0; i < holiday.data.length; i++) {
        const item = holiday.data[i]
        const incomingDate = moment(item.date)
        isHoliday =
          incomingDate.get('dates') == date.get('dates') &&
          incomingDate.get('months') == date.get('months') &&
          incomingDate.get('years') == date.get('years')
        if (isHoliday) break
      }
    }
    let isWeeklyOf = false
    if (!isHoliday) {

      if (weeklyOf && Array.isArray(weeklyOf) && weeklyOf.includes(date.day())) {
        isWeeklyOf = true
      } else if (user.data && user.data.weekly_of && Array.isArray(user.data.weekly_of) && user.data.weekly_of.includes(date.day())) {
        isWeeklyOf = true
      }
    }
    return isWeeklyOf || isHoliday
  }

  const onSubmit = async (e) => {
    if (holiday.loading) {
      return
    }
    e.preventDefault()

    const validationResponse = validate(validationSchema)

    if (validationResponse === true) {
      const leaveData = { ...fields }


      leaveData.from = fields.from.format('YYYY-MM-DD')
      leaveData.to = fields.to.format('YYYY-MM-DD')
      setLoading(true)
      dispatch(
        callApiAction(
          async () => await raiseLeaveApi(leaveData),
          async (response) => {

            setLoading(false)
            navigate("/calendar/")
          },
          (err) => {
            setLoading(false)
            setFields({ ...fields, err })
          },
        ),
      )
    } else {
      setFields({ ...fields, err: validationResponse })
    }
  }


  const genrateLeaveCount = () => {
    const startseconds = fields.from.valueOf()
    const endseconds = fields.to.valueOf()

    const millisecondsPerDay = 1000 * 60 * 60 * 24

    const count = (endseconds - startseconds) / millisecondsPerDay

    let deductCount = 0

    if (fields.to_time_duration) {
      HALF_TYPE.forEach((half) => {
        if (fields.to_time_duration == half.value) {
          deductCount -= half.deduct
        }
      })
    }

    if (fields.from_time_duration) {
      HALF_TYPE.forEach((half) => {
        if (fields.from_time_duration == half.value) {
          deductCount += half.add
        }
      })
    }

    const loopStart = moment()
      .set('years', fields.from.get('years'))
      .set('dates', fields.from.get('dates'))
      .set('months', fields.from.get('months'))
      .set('hours', 0)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0)
      .valueOf()
    const loopEnd = moment()
      .set('years', fields.to.get('years'))
      .set('dates', fields.to.get('dates'))
      .set('months', fields.to.get('months'))
      .set('hours', 0)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0)
      .valueOf()
    for (let i = loopStart; i <= loopEnd; i += millisecondsPerDay)
      if (disableDates(moment(i))) {
        deductCount -= 1
      }

    setFields({
      ...fields,
      leave_count: parseFloat((count + deductCount).toFixed(1)),
    })
  }

  useEffect(() => {
    if (
      !leaveBalance.loading &&
      leaveBalance.data &&
      leaveBalance.data.leaves &&
      leaveBalance.data.leaves
    ) {
      leaveBalance.data.leaves.forEach((leave) => {
        if (leave.leave_type == fields.leave_type) {
          setFields({ ...fields, availableLeave: leave.leaveBalance })
        }
      })
    }
  }, [fields.leave_type])

  useEffect(() => {
    genrateLeaveCount()
  }, [
    fields.from_time_duration,
    fields.to_time_duration,
    fields.to,
    fields.from,
  ])

  return (
    <LeaveRaiseUi
      disableDates={disableDates}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  )
}
export default memo(LeaveRaiseController)
