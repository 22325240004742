import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getUserByIdApi, updateUserField } from "../../apis/user.api"
import { callApiAction } from "../../store/actions/commonAction"
import { loggedInUser } from "../../utils/helper"
import { fetchBranches,deleteBranch } from "../../apis/branch.api"
import BranchUI from "./BranchUi"

const BranchController = ({ userId }) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [state, setState] = useState([])
    const [loading, setLoading] = useState(false)
    const onUpdateValue = () => {

    }
    const fetchBranchesApi = fetchBranches
    const deleteBranchApi = deleteBranch

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchBranchesApi(),
            (response) => {
                setState(response.result)
                
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    const DeleteBranch = (id) => {
       
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteBranchApi({id}),
            (response) => {
                setLoading(false)
                
                window.location.reload(true)
            },
            (err) => {
                setLoading(false)
                console.log(err)
            }
        ))
    }


    useEffect(() => {
        
            fetchList()
        
    }, [])

    return <BranchUI setState={setState} callBack={fetchList} loading={loading} state={state} DeleteBranch={DeleteBranch}/>
}
export default BranchController