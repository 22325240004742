export const mode = 'cosmo' //process.env.REACT_APP_APP_MODE;
let domain = ''

switch (mode) {
  case 'ip':
    domain = 'http://192.168.29.101:8000/'
    break
  case 'local':
    domain = 'http://localhost:8000/'
    break
  case 'development':
    domain = 'https://payrollv2-production.up.railway.app/'
    break
  case 'production':
    domain = 'https://payrollv2-production-6bc2.up.railway.app/'
    break
  case 'cosmo':
    domain = 'https://payrollv2-production-fda2.up.railway.app/'
    break
  default:
    domain = 'http://localhost:8000/'
}

export default {
  root: domain,
  getUserById: `${domain}api/task/getUserById`,
  signIn: `${domain}api/auth/login`,
  resetToken: `${domain}api/auth/reset-token`,
  resetPass: `${domain}api/auth/reset-pass`,

  userAndSystemFetchByToken: `${domain}api/user/fetch-by-token`,
  userBase: `${domain}api/user/`,
  userPassword: `${domain}api/user/password`,
  userById: `${domain}api/user/fetch-by-id`,
  updateUserState: `${domain}api/user/enable-or-disable`,
  leaveById: `${domain}api/leave/fetch-by-id/`,
  leaveBase: `${domain}api/leave/`,
  pendingLeaveBase: `${domain}api/leave/pending`,
  coffById: `${domain}api/leave/coff/fetch-by-id/`,
  leaveCount: `${domain}api/leave/balance`,
  // updateLeaveStatus: `${domain}api/leave/send/leavestatus`,
  updateCoffStatus: `${domain}api/leave/send/coffstatus`,
  raiseLeaves: `${domain}api/leave/create`,
  raiseCOff: `${domain}api/leave/coff`,
  pendingCOff: `${domain}api/leave/pending/coff`,
  monthlyLeaves: `${domain}api/leave/fetch/monthly`,

  fetchOneMonthLogs: `${domain}api/log/fetch-one-month`,
  coffs: `${domain}api/leave/coff`,
  reimberesementBase: `${domain}api/reimbersement/`,
  pendingReimberesementBase: `${domain}api/reimbersement/pending`,
  reimberesementCount: `${domain}api/reimbersement/counts/`,
  reimberesementById: `${domain}api/reimbersement/id/`,
  
  notificationBase: `${domain}api/notification/`,
  notificationFetch: `${domain}api/notification/fetch`,

  holiday: `${domain}api/holiday`,

  salaryUpload: `${domain}api/salary/upload`,
  fetchSalary: `${domain}api/salary`,

  branch: `${domain}api/branch`,

  department: `${domain}api/department`,
  leaveType: `${domain}api/leave-type`,
  leaveTypeById: `${domain}api/leave-type/by-id`,

  employeeType: `${domain}api/employee-type`,
  fileOrImage: `${domain}api/files`,
  image: `${domain}api/files/image`,
  file: `${domain}api/files/file`,

  createDailyUpdate: `${domain}api/dailyupdate/createDailyUpdate`,
  getAllDailyUpdates: `${domain}api/dailyupdate/getAllDailyUpdates`,
  dailyUpdateBase: `${domain}api/dailyupdate/`,
  taskBase: `${domain}api/task/`,
  taskCalendar: `${domain}api/task/calendar`,
  taskById: `${domain}api/task/id/`,
  taskPriority: `${domain}api/task/priority/`,
  taskSttaus: `${domain}api/task/status/`,
  taskPerformance: `${domain}api/task/performance/`,


  performenceEvaluation: `${domain}api/task/performenceEvaluation/`, //:id,
  performenceEvaluationWithId: `${domain}api/task/performenceEvaluationWithId/`, //:id,
  getReopenReport: `${domain}api/task/getReopenReport/`, //id
  getReopenReporttWithUserId: `${domain}api/task/getReopenReporttWithUserId/`, //id
  getReopenReoprtWithTaskId: `${domain}api/task/getReopenReoprtWithTaskId/`, //id
  GetAllReopenTasks: `${domain}api/task/GetAllReopenTasks`,
  getTaskCalender: `${domain}api/task/getTaskCalender`,
  getPerformenceCalender: `${domain}api/task/getPerformenceCalender`,
  getPerformenceCalenderWithId: `${domain}api/task/getPerformenceCalenderWithId/`,
  GetApprovedTaskOtherRoles: `${domain}api/task/GetApprovedTaskOtherRoles`,
  Mark_As_Completed_TasksOtherRoles: `${domain}api/task/Mark_As_Completed_TasksOtherRoles`,
  GetTodayDueTaskOtherRoles: `${domain}api/task/GetTodayDueTaskOtherRoles`,
  CurrentWeekTaskOtherRoles: `${domain}api/task/CurrentWeekTaskOtherRoles`,
  getAllMyTasksOtherRoles: `${domain}api/task/getAllMyTasksOtherRoles`,
}
