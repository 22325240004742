import { Cancel, CheckBox, CheckCircle, Visibility, Add } from "@mui/icons-material"
import { Autocomplete, Badge, Button, ButtonGroup, Chip, CircularProgress, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import moment from "moment"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { center } from "../../assets/css/theme/common"
import ClickButton from "../../components/button/ClickButton"
import SubmitButton, { ActionButton } from "../../components/button/SubmitButton"
import { StyledSearchBar, UserSearchBarNormal } from "../../components/inputs/SearchBar"
import { CenteredBox } from "../../components/layouts/common/boxes"
import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker"
import { openModal } from "../../store/actions/modalAction"
import { REIMBURSEMENT_STATUS, USER_ROLES } from "../../utils/constants"
import { createBadge } from "../../utils/helper"
import ProfileController from "../profile/ProfileController"
import ProfileDialog from "../profile/ProfileDialog"
import ReimberesementDetailsController from "./ReimberesementDetailsController"
import ReimburesmentCounts from "./ReimburesmentCounts"


const ReimburesmentRow = memo(
  ({ id, appliedBy, appliedById, type, amount, purpose, date, status, onUpdateField, expenseBy }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()


    const onOpenViewMore = () => {
      dispatch(
        openModal(
          {
            title: "Reimburesement Details",
            component: <ReimberesementDetailsController reimbursementId={id} />,
            size: 'lg'
          }
        ),
      )
    }

    const [statusLoading, setStatusLoading] = useState(false)
    const [currentStatus, setcurrentStatus] = useState(status)
    const onStatusApproved = () => {
      setcurrentStatus(REIMBURSEMENT_STATUS.approved)
      setStatusLoading(true)
      onUpdateField('status', REIMBURSEMENT_STATUS.approved, id, () => { setStatusLoading(false) })
    }
    const onStatusRejected = () => {
      setcurrentStatus(REIMBURSEMENT_STATUS.rejected)
      setStatusLoading(true)
      onUpdateField('status', REIMBURSEMENT_STATUS.rejected, id, () => { setStatusLoading(false) })
    }
    return (
      <>
        <TableRow >
          <TableCell>{moment(date).format("DD-MM-YYYY")}</TableCell>
          <TableCell>{expenseBy}</TableCell>
          <TableCell sx={{ textTransform: "uppercase" }}  >{type}</TableCell>
          <TableCell >₹{amount}</TableCell>
          <TableCell >{purpose}</TableCell>
          {!statusLoading && <TableCell >{(currentStatus == REIMBURSEMENT_STATUS.pending && user.data._id != appliedById) ? <>
            <IconButton onClick={onStatusApproved} size="inherit">
              <CheckCircle color="success" fontSize="inherit" />
            </IconButton>
            <IconButton onClick={onStatusRejected} size="inherit">
              <Cancel color="error" fontSize="inherit" />
            </IconButton>
          </> : createBadge(currentStatus)}</TableCell>}
          {
            statusLoading && <TableCell ><CircularProgress /></TableCell>
          }



          {<TableCell>
            <Tooltip title={`view  details`}>
              <IconButton onClick={onOpenViewMore}>
                <Visibility />
              </IconButton>
            </Tooltip>
          </TableCell>}
        </TableRow>
      </>
    )
  }
)


const PendingReimberesementUI = ({ listLoading, isForEmployee, counts, filters, setFilters, list, onUpdateField, loading, }) => {
  const user = useSelector(state => state.user)
  return (
    <>
      <Box mt={3} mb={3}>

        <Paper elevation={2} sx={{ width: "100%" }} p={4} component={Box}>

          <Box>
            <Typography sx={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "40px",
              color: "#0D0D0D"
            }}>{filters.date && filters.date.format('MMMM')} Expense</Typography>
          </Box>
          <TableContainer component={Box} mt={3} sx={{
            boxShadow: "0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)",
            borderRadius: "4px",
            border: "1px solid #BFBFBF"
          }}>
            <Table aria-label="collapsible table" sx={{}}>
              <TableHead sx={{ background: "#F2F2F2" }}>
                <TableRow>
                  <TableCell >Expense Date</TableCell>
                  <TableCell >Expense By</TableCell>
                  <TableCell >Expense Type</TableCell>
                  <TableCell >Amount</TableCell>
                  <TableCell >Purpose</TableCell>
                  <TableCell >Status</TableCell>
                  {<TableCell  ></TableCell>}
                </TableRow>
              </TableHead>

              <TableBody>
                {listLoading && (
                  <>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Skeleton width={'100%'} height={'50px'} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Skeleton width={'100%'} height={'50px'} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Skeleton width={'100%'} height={'50px'} />
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {!listLoading && list && list.result && list.result.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="Center">
                      <Typography sx={{ ...center }} variant="h3">
                        No Data
                      </Typography>{' '}
                    </TableCell>
                  </TableRow>
                )}
                {!listLoading &&
                  list &&
                  list.result &&
                  list.result.map((row, index) => (
                    <ReimburesmentRow
                      key={row._id}
                      id={row._id}
                      expenseBy={row.userId ? (row.userId.first_name +  " " + row.userId.last_name) : ""}
                      date={row.expenseDate}
                      status={row.status}
                      appliedById={row.userId}
                      type={row.type}
                      amount={row.amount}
                      purpose={row.purpose}
                      onUpdateField={onUpdateField}
                    />
                  ))}
              </TableBody>
            </Table>
            {list && list.total > 0 && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={list.total}
                rowsPerPage={parseInt(filters.pageSize)}
                page={parseInt(filters.pageNo) - 1}
                onPageChange={(e, page) => {
                  setFilters({ ...filters, pageNo: page + 1 })
                }}
                onRowsPerPageChange={(e) => {
                  setFilters({ ...filters, pageSize: e.target.value })
                }}
              />
            )}
          </TableContainer>
        </Paper>
      </Box>
    </>
  )
}


export default PendingReimberesementUI
