import moment from "moment"
import MessageDilog from "../../components/MessageDilog"
import { actions } from "../../utils/constants"
import { callApiAction } from "./commonAction"
import { openModal } from "./modalAction"
import { fetchHolidays } from "../../apis/holiday.api"

export const fetchHolidayListAction = () => {
    return async (dispatch, getState) => {

        dispatch({ type: actions.START_HOLIDAY_COUNT_LOADING })

        dispatch(callApiAction(
            async () => await fetchHolidays({ date: moment().toISOString() }),
            (response) => {
                if (response )
                    dispatch({ type: actions.SET_HOLIDAY_COUNT_DATA, value: response })

            },
            (err) => {
                dispatch(openModal({component:<MessageDilog title="Something went wrong" message={err} />,size:"md"}))
            }
        ))
    }
}

