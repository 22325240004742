import { Box, Chip, CircularProgress, IconButton, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tabs, Typography } from "@mui/material"
import { memo, useContext, useState } from "react"
import { Add, FileOpen } from "@mui/icons-material"

import { CenteredBox } from "../../../components/layouts/common/boxes"
import { findObjectKeyByValue } from "../../../utils/helper"
import { TASK_PRIORITY } from "../../../utils/task.constants"






const PerformanceListUi = ({
    loading,

    list,


}) => {

    return (
        <>
            
                <Box >
                    <Typography variant="h3" mb={3} mt={3}>Performance Stats</Typography>
                    {!loading && list && list.length > 0 && <TableContainer>

                        <Table>
                            <TableHead sx={(theme) => ({ background: theme.palette.grey.light })}>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell sx={{ minWidth: "200px" }}>A (On Time)</TableCell>
                                    <TableCell sx={{ minWidth: "200px" }}>B ({"<"}2 Days)</TableCell>
                                    <TableCell sx={{ minWidth: "200px" }}>C (2-4 Days)</TableCell>
                                    <TableCell sx={{ minWidth: "200px" }}>D (4+ days)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    list.map((row, index) => {
                                        return <TableRow key={index}>
                                            <TableCell  sx={(theme) => ({ background: theme.palette.grey.light })}>
                                                {findObjectKeyByValue(index + 1, TASK_PRIORITY)}
                                            </TableCell>
                                            {row.map((item, index) => <TableCell key={index}>{item}</TableCell>)}
                                        </TableRow>
                                    })
                                }
                            </TableBody>

                        </Table>
                    </TableContainer>}
                    {
                        loading && <CenteredBox><CircularProgress /></CenteredBox>
                    }
                    {
                        !loading && (!list || list.length == 0) && <CenteredBox mt={4} mb={4}><Typography variant="h3">No performance analysis available</Typography></CenteredBox>
                    }
                </Box>
            
        </>
    )
}
export default memo(PerformanceListUi)