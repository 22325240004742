import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getCoffByIdApi, getLeaveByIdApi, updateCoffStatus, updateLeaveStatus } from "../../../apis/leave.api"
import { getReimberesementByIdApi, updateReimberesementField } from "../../../apis/reimbersement.api"

import { callApiAction } from "../../../store/actions/commonAction"

import CoffDetailsUi from "./CoffDetailsUi"
import { LEAVE_STATUS } from "../../../utils/leave.constants"
import { callSnackBar } from "../../../store/actions/snackbarAction"


const CoffDetailsController = ({ leaveId, callBack = () => { }, isFromModal }) => {


    const [id, setId] = useState(leaveId)

    const dispatch = useDispatch()
    const params = useParams()
    const [state, setState] = useState(null)
    const [loading, setLoading] = useState(false)
    const onUpdateValue = () => {

    }

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getCoffByIdApi(id),
            (response) => {
                setState(response)
                setLoading(false)
                
            },
            (err) => {
                setLoading(false)
            }
        ))
    }


    const [fieldLoading, setFieldLoading] = useState({
        fieldName: "",
        loading: false
    })

    const onApprove = () => {

        setFieldLoading({
            fieldName: "accept",
            loading: true
        })


        dispatch(callApiAction(
            async () => await updateCoffStatus({  id, remarks: state.action_remarks, status: LEAVE_STATUS.APPROVED }),
            (response) => {
                setFieldLoading({
                    fieldName: "",
                    loading: false
                })
                setState({ ...state, 'status': LEAVE_STATUS.APPROVED })
                callBack(LEAVE_STATUS.APPROVED)
            },
            (err) => {
                setFieldLoading({
                    fieldName: "",
                    loading: false
                })
                dispatch(callSnackBar(err, 'error'))
            }
        ))

    }

    const onReject = () => {

        setFieldLoading({
            fieldName: "reject",
            loading: true
        })


        dispatch(callApiAction(
            async () => await updateCoffStatus({  id, remarks: state.action_remarks, status: LEAVE_STATUS.REJECTED }),
            (response) => {
                setFieldLoading({
                    fieldName: "",
                    loading: false
                })
                setState({ ...state, 'status': LEAVE_STATUS.REJECTED })
                
                callBack(LEAVE_STATUS.REJECTED)
            },
            (err) => {
                setFieldLoading({
                    fieldName: "",
                    loading: false
                })
                dispatch(callSnackBar(err, 'error'))
            }
        ))

    }

    useEffect(() => {
        if (leaveId) {
            fetchList()
        } else {
            setId(params.id)
        }
    }, [params.id])

    useEffect(() => {
        if (id && id == params.id) {
            fetchList()
        }
    }, [id])




    return <CoffDetailsUi setState={setState} loading={loading} state={state} onApprove={onApprove} onReject={onReject} fieldLoading={fieldLoading} isFromModal={isFromModal} onUpdateValue={onUpdateValue} />
}
export default CoffDetailsController