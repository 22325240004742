import { Box, Chip, CircularProgress, IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tabs, Typography } from "@mui/material"
import { memo, useContext, useState } from "react"

import { ActionButton } from "../../../components/button/SubmitButton"
import { Add, FileOpen } from "@mui/icons-material"

import TaskTable from "./TaskTable"




const TaskListUi = ({
    loading,
    filters,
    list,

    fetchList,
    onTabChange,
    tab,
    setFilters,
    onAddButtonClick
}) => {

    return (
        <>
            <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "flex", flex: 1 }}>

                    <Tabs value={tab} onChange={onTabChange} sx={{ width: "100%", display: "flex" }}>
                        <Tab sx={{ display: "flex" }} label="Task List" />
                        <Tab sx={{ display: "flex" }} label="Due Today" />
                        <Tab sx={{ display: "flex" }} label="This Week" />
                        <Tab sx={{ display: "flex" }} label="Mark As Completed" />
                        <Tab sx={{ display: "flex" }} label="Approved" />
                    </Tabs>
                </Box>
                {/* <Box><ActionButton onClick={onAddButtonClick} title="Add Task" icon={<Add />} ></ActionButton></Box> */}



            </Box>

            <TaskTable
                loading={loading}
                filters={filters}
                list={list}

                fetchList={fetchList}
                onTabChange={onTabChange}

                setFilters={setFilters}

            />
        </>
    )
}
export default memo(TaskListUi)