import { Button, ButtonGroup, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, Input, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { Fragment } from "react"
import { useDispatch, useSelector } from 'react-redux'

import SubmitButton, { ResetButton } from "../../components/button/SubmitButton"

import CustomInput from "../../components/inputs/CustomInput"
import { UserSearchBarNormal } from "../../components/inputs/SearchBar"
import { closeModal } from "../../store/actions/modalAction"
import { FOOD_TYPES, FUEL_PRICE_PER_KM, REIMBURSEMENT_FUEL_TYPE, REIMBURSEMENT_STATUS, REIMBURSEMENT_TRAVEL_TYPE, REIMBURSEMENT_TYPE, USER_ROLES } from "../../utils/constants"
import CustomRadioButtons from "../../components/layouts/common/CustomRadioButtons"
import FileInput from "../../components/inputs/FileInput"


const TravelTypeFields = ({ fields, setFields, loading ,defaultData}) => {
    return <>
        <Grid container mt={3} columnSpacing={4} rowSpacing={4}>
            <Grid item md={4}  >
                <Typography variant="h3" mb={2}>Place Of Visit*</Typography>
                <CustomInput
                    disabled={loading}
                    value={fields.place}
                    onChange={(e) => setFields({ ...fields, err: '', place: e.target.value })}
                    type="text"


                />
            </Grid>
            <Grid item md={8}  >
                <Typography variant="h3" mb={2}>Purpose Of Visit*</Typography>
                <CustomInput
                    disabled={loading}
                    value={fields.purpose}
                    onChange={(e) => setFields({ ...fields, err: '', purpose: e.target.value })}
                    type="text"


                />
            </Grid>

            <Grid item md={9}  >
                <Typography variant="h3" mb={2}>Vehichle Type*</Typography>
                <CustomRadioButtons
                    value={fields.vehichleType}
                    onChange={(e) => setFields({ ...fields, err: '', vehichleType: e.target.value })}
                    options={Object.keys(REIMBURSEMENT_TRAVEL_TYPE).map((key) => ({
                        label: REIMBURSEMENT_TRAVEL_TYPE[key].toUpperCase(),
                        value: REIMBURSEMENT_TRAVEL_TYPE[key]
                    }))} />
            </Grid>
            <Grid item md={3}  >
                <Typography variant="h3" mb={2}>Amount*</Typography>
                <OutlinedInput
                    disabled={loading}
                    value={fields.amount}
                    onChange={(e) => setFields({ ...fields, err: '', amount: e.target.value })}
                    type="number"
                    min={0}


                />
            </Grid>

        </Grid>

    </>
}

const FuelTypeFields = ({ fields, setFields, loading ,defaultData}) => {
    return <>

        <Grid container mt={3} columnSpacing={4} rowSpacing={4}>
            <Grid item md={4}  >
                <Typography variant="h3" mb={2}>Place Of Visit*</Typography>
                <CustomInput
                    disabled={loading}
                    value={fields.place}
                    onChange={(e) => setFields({ ...fields, err: '', place: e.target.value })}
                    type="text"


                />
            </Grid>

            <Grid item md={8}  >
                <Typography variant="h3" mb={2}> Purpose Of Visit*</Typography>
                <CustomInput
                    disabled={loading}
                    value={fields.purpose}
                    onChange={(e) => setFields({ ...fields, err: '', purpose: e.target.value })}
                    type="text"


                />
            </Grid>
            <Grid item md={4}  >
                <Typography variant="h3" mb={2}> Vehichle Type*</Typography>
                <CustomRadioButtons
                    value={fields.vehichleType}
                    onChange={(e) => {
                        let noOfKms = 0

                        for (let fuelType of REIMBURSEMENT_FUEL_TYPE) {
                            if (fuelType.label == e.target.value) {
                                noOfKms = fuelType.kmPrice
                            }
                        }
                        setFields({ ...fields, vehichleType: e.target.value, amount: parseFloat(noOfKms) * parseFloat(fields.noOfKMS) })
                    }}


                    options={REIMBURSEMENT_FUEL_TYPE.map((item) => ({
                        label: item.label,
                        value: item.label
                    }))} />
            </Grid>

            <Grid item md={4}  >
                <Typography variant="h3" mb={2}> No Of KMS(To & FRO)*</Typography>
                <OutlinedInput
                    min={0}

                    disabled={loading || fields.vehichleType == ''}
                    value={fields.noOfKMS}
                    endAdornment={<InputAdornment position="end" >
                        <Box sx={{ borderLeft: "2px solid lightgrey" }} pl={2} >
                            <Typography fontSize="inherit">
                                ₹ {fields.amount && !isNaN(fields.amount) ? fields.amount : 0}
                            </Typography>

                        </Box>
                    </InputAdornment>}
                    onChange={(e) => setFields({
                        ...fields, err: '', noOfKMS: e.target.value, amount: REIMBURSEMENT_FUEL_TYPE.reduce((preVal, curr) => {
                            if (curr.label == fields.vehichleType) {
                                return curr.kmPrice
                            }
                            return preVal
                        }, 1) * parseFloat(e.target.value)
                    })}
                    type="number"
                />
            </Grid>

        </Grid >


    </>
}

const FoodTypeFields = ({ fields, setFields, loading ,defaultData}) => {
    return <>



        <Grid container mt={3} columnSpacing={4} rowSpacing={4}>
            <Grid item md={6}  >
                <Typography variant="h3" mb={2}> Food For*</Typography>

                <CustomRadioButtons

                    value={fields.foodType}
                    onChange={(e) => {
                        setFields({ ...fields, foodType: e.target.value })
                    }}
                    options={Object.keys(FOOD_TYPES).map((key) => ({
                        label: FOOD_TYPES[key],
                        value: FOOD_TYPES[key]
                    }))} />
            </Grid>

            <Grid item md={3}  >
                <Typography variant="h3" > Amount*</Typography>

                <CustomInput
                    disabled={loading || fields.type == REIMBURSEMENT_TYPE.fuel}
                    value={fields.amount}
                    onChange={(e) => setFields({ ...fields, err: '', amount: e.target.value })}
                    type="number"

                />
            </Grid>


            <Grid item md={12}  >
                <Typography variant="h3" mb={2}> Purpose*</Typography>
                <CustomInput
                    disabled={loading}
                    value={fields.purpose}
                    onChange={(e) => setFields({ ...fields, err: '', purpose: e.target.value })}
                    type="text"
                    rows={3}
                    multiline

                />
            </Grid>


        </Grid>


    </>
}


const OtherTypeFields = ({ fields, setFields, loading ,defaultData}) => {
    return <>
        <Grid container mt={3} columnSpacing={4} rowSpacing={4}>
            <Grid item md={12}  >
                <Typography variant="h3" mb={2}> Purpose*</Typography>
                <CustomInput
                    disabled={loading}
                    value={fields.purpose}
                    onChange={(e) => setFields({ ...fields, err: '', purpose: e.target.value })}
                    type="text"
                    rows={3}
                    multiline

                />
            </Grid>

            <Grid item md={12}  >
                <Typography variant="h3" mb={2}> Amount*</Typography>
                <CustomInput
                    disabled={loading}
                    value={fields.amount}
                    onChange={(e) => setFields({ ...fields, err: '', amount: e.target.value })}
                    type="number"
             

                />
            </Grid>


        </Grid>

    </>
}
const ReimberesementCreateUI = ({ setFiles, fields, setFields, loading, onSubmit,defaultData }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    return <>
        <Box component="form" sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }} maxHeight="100%" onSubmit={onSubmit} >
            <DialogTitle variant="h2">{'Apply Reimburesement'}
                <Box component={'div'} ><Typography variant="h4" color={'red'}>{fields.err} </Typography></Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ width: "100%", maxWidth: "900px" }}>


                    <Grid container columnSpacing={4} rowSpacing={4} >
                        <Grid item md={4}>
                            <Typography variant="h3" mb={2}>Date</Typography>
                            <DesktopDatePicker
                                renderInput={(props) => {
                                    return <TextField {...props} />
                                }}

                                inputFormat="DD-MM-yyyy"
                                disabled={loading}
                                value={fields.expenseDate}
                                onChange={(e) => {
                                    
                                    setFields({ ...fields, err: '', expenseDate: e._d.toISOString() })

                                }
                                }
                                type="date"


                            />

                        </Grid>
                        <Grid item md={8} >
                            <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                <Typography variant="h3" mb={2}>Select Expense Type</Typography>
                                <ButtonGroup disableElevation fullWidth sx={{ display: "flex", flex: 1, width: "100%" }} >
                                    {
                                        Object.keys(REIMBURSEMENT_TYPE).map((key) => {

                                            return <Button sx={{ minWidth: "200px", height: "100%" }} variant={REIMBURSEMENT_TYPE[key] == fields.type ? "contained" : "outlined"}
                                             onClick={() => { setFields({ ...fields, err: '',...defaultData,expenseDate:fields.expenseDate, type: REIMBURSEMENT_TYPE[key] }) }} >
                                                {REIMBURSEMENT_TYPE[key].toUpperCase()}
                                            </Button>

                                        })
                                    }
                                </ButtonGroup>
                            </Box>
                        </Grid>
                    </Grid>

                    {fields.type && fields.type == REIMBURSEMENT_TYPE.food && <FoodTypeFields fields={fields} loading={loading} setFields={setFields} />}
                    {fields.type && (fields.type == REIMBURSEMENT_TYPE.fuel) && <FuelTypeFields fields={fields} loading={loading} setFields={setFields} />}
                    {fields.type && fields.type == REIMBURSEMENT_TYPE.travel && <TravelTypeFields fields={fields} loading={loading} setFields={setFields} />}
                    {fields.type && fields.type == REIMBURSEMENT_TYPE.other && <OtherTypeFields fields={fields} loading={loading} setFields={setFields} />}

                    {fields.type && fields.type != '' &&

                        <>


                            <Typography variant="h3" mt={4} mb={3}>

                                Attach Bills If Any
                            </Typography>


                            <FileInput
                                onChange={(files,filesList) => {
                                    setFields({ ...fields, bills: filesList })
                                }}
                                multiple
                                accept=".pdf"
                                inputProps={{ accept: ".pdf" }}
                                title={`Attach Bills ${fields.type==REIMBURSEMENT_TYPE.food||fields.type==REIMBURSEMENT_TYPE.travel?"*":""}`}
                                subTitle="Only pdf files are allowed! less than 2 MB"
                            />


                        </>

                    }

                    <Box sx={{ display: "flex", justifyContent: "flex-end" }} mt={4}>

                        <Box>
                            <ResetButton variant='outlined' onClick={handleClose} title={'Close'}></ResetButton>
                        </Box>
                        <Box ml={3}>
                            <SubmitButton loading={loading} type="submit" title="Apply" ></SubmitButton>
                        </Box>

                    </Box>

                </Box>

            </DialogContent>



        </Box>

    </>
}
export default ReimberesementCreateUI