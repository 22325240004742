import { useContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { EmployeeTaskUserContext } from "../EmployeeMain"
import { TASK_PRIORITY, TASK_STATUS } from "../../../utils/task.constants"
import { findObjectKeyByValue } from "../../../utils/helper"
import { Chip, CircularProgress, MenuItem, Select } from "@mui/material"
import { callApiAction } from "../../../store/actions/commonAction"
import { updateTasksPriorityApi } from "../../../apis/task.api"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants"

const PriorityButton = ({ selectedUser, priority, id, assignedTo, status }) => {

    const [loading, setLoading] = useState(false)
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    // const selectedUser = useContext(EmployeeTaskUserContext)

    const activeUser = selectedUser ? selectedUser : user.data
    const [currentPriority, setCurrentPriority] = useState(priority)

    const colors = {
        [TASK_PRIORITY.HIGH]: "error",
        [TASK_PRIORITY.MEDIUM]: "warning",
        [TASK_PRIORITY.LOW]: "default"
    }

    const onPriorityChange = (e) => {
        setLoading(true)
        setCurrentPriority(e.target.value)
        dispatch(
            callApiAction(
                async () => await updateTasksPriorityApi({ id, priority: e.target.value }),
                async (response) => {
                    setLoading(false)
                },
                (err) => {

                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                },
            ),
        )
    }

    const getPriorityButton = (priorityVal) => {

        if (!activeUser || status != TASK_STATUS.PENDING || (assignedTo && Array.isArray(assignedTo) && assignedTo.includes(activeUser._id))) {
            return <Chip size="small" label={findObjectKeyByValue(priorityVal, TASK_PRIORITY)} color={colors[priorityVal] ? colors[priorityVal] : "default"} />
        }

        else {
            return <Select value={currentPriority} size="small" sx={(theme) => ({ background: "rgba(255, 229, 230, 1)", border: "none", fontSize: theme.typography.body2.fontSize, color: colors[priorityVal] ? colors[priorityVal] : "default" })} disableUnderline onChange={onPriorityChange}>
                {
                    Object.keys(TASK_PRIORITY).map((key) => {
                        return <MenuItem key={key} value={TASK_PRIORITY[key]} >{key}</MenuItem>
                    })
                }
            </Select>
        }
    }
    if (loading) {
        return <CircularProgress />
    }
    return getPriorityButton(currentPriority)
}
export default PriorityButton