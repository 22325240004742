import { Outlet } from "react-router"
import EmployeeTaskButtons from "./EmployeeTaskButtons"
import { Box, Paper } from "@mui/material"
import { createContext, useCallback, useState } from "react"
import UserSearchBar from "../../components/inputs/SearchBar"
import { BasicProfileDetailsComponent } from "../profile/BasicProfileDetailsComponent"
import PerformanceButtons from "./Performance/PerformanceButtons"

export const EmployeeTaskUserContext = createContext()

const EmployeeMain = ({forPerformance}) => {
    const [selectedUser, setSelectedUser] = useState(null)

    const onUserChange = useCallback((user) => {
        setSelectedUser(user)
    }, [])

    return <>

        <Box mt={3}>
            <UserSearchBar onUserChange={onUserChange} />
        </Box>


        {selectedUser && <>
            <Box
                mt={4}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        height: '100%',
                        border: '1px solid #BFBFBF',
                        boxShadow:
                            '0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)',
                        borderRadius: '4px',
                    }}
                >

                    <BasicProfileDetailsComponent data={selectedUser} />
                </Box>
            </Box>
           {!forPerformance && <EmployeeTaskButtons />}
           {forPerformance && <PerformanceButtons />}
            <Paper component={Box} mb={4} p={3} sx={(theme) => ({ border: "1px solid " + theme.palette.grey[500] })}>
                <EmployeeTaskUserContext.Provider value={selectedUser}>
                    <Outlet />
                </EmployeeTaskUserContext.Provider>
            </Paper>
        </>}

    </>
}
export default EmployeeMain