
import { Avatar, Box, Button, ButtonGroup, CircularProgress, Grid, IconButton, MenuItem, Paper, Select, Typography } from "@mui/material"
import moment from "moment"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CenteredBox } from "../../components/layouts/common/boxes"
import { REIMBURSEMENT_STATUS, REIMBURSEMENT_TYPE } from "../../utils/constants"
import { createBadge } from "../../utils/helper"
import parse from 'html-react-parser';
import ImageComponent, { PdfComponent } from "../../components/ImageComponent"
import FileDownloadComponent from "../../components/FileDownloadComponent"
import endpoints from "../../apis/endpoints"

const TravelTypeDetails = ({ state }) => {
    return <>

        <Grid xs={6} item>
            <Typography variant="h4">
                Place Of Visit:
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.place
                }
            </Typography>
        </Grid>

        <Grid xs={6} item>
            <Typography variant="h4">
                Purpose Of Visit:
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.purpose
                }
            </Typography>
        </Grid>

        <Grid xs={6} item>
            <Typography variant="h4">
                Vehichle Type
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.vehichleType.toUpperCase()
                }
            </Typography>
        </Grid>

    </>
}

const FuelTypeDetails = ({ state }) => {
    return <>

        <Grid xs={6} item>
            <Typography variant="h4">
                Place Of Visit:
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.place
                }
            </Typography>
        </Grid>

        <Grid xs={6} item>
            <Typography variant="h4">
                Purpose Of Visit:
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.purpose
                }
            </Typography>
        </Grid>

        <Grid xs={6} item>
            <Typography variant="h4">
                Vehichle Type
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.vehichleType.toUpperCase()
                }
            </Typography>
        </Grid>

        <Grid xs={6} item>
            <Typography variant="h4">
                Total KMS:
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.noOfKMS
                }
            </Typography>
        </Grid>
    </>
}
const OtherTypeDetails = ({ state }) => {
    return <>

        <Grid xs={6} item>
            <Typography variant="h4">
                Type of Expense:
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.expenseType
                }
            </Typography>
        </Grid>
    </>
}
const FoodTypeDetails = ({ state }) => {
    return <>
        <Grid xs={6} item>
            <Typography variant="h4">
                Purpose of Food :
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.purpose
                }
            </Typography>
        </Grid>
        <Grid xs={6} item>
            <Typography variant="h4">
                Type Of Food:
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.foodType
                }
            </Typography>
        </Grid>


    </>
}



const ReimberesementDetailsPageUi = ({ state, setState, fieldLoading, onUpdateField, loading, isFromModal }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()




    return <>
        {!loading && state &&
            <Box mt={isFromModal ? 0 : 3} mb={3}>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", padding: 3 }}>
                    {!isFromModal && <Typography variant="h2">Reimburesement Details</Typography>}
                    <Box mt={3} >
                        <Grid container spacing={2} rowSpacing={3}>

                            <Grid xs={6} item>
                                <Typography variant="h4">
                                    Applied By :
                                </Typography>
                                <Typography variant="h6" color="grey.main">
                                    {
                                        state.userId && (state.userId.first_name + " "+ state.userId.last_name)
                                    }
                                </Typography>
                            </Grid>


                            <Grid xs={6} item>
                                <Typography variant="h4">
                                    Date :
                                </Typography>
                                <Typography variant="h6" color="grey.main">
                                    {
                                        moment(state.date).format("DD-MM-YYYY")
                                    }
                                </Typography>
                            </Grid>


                            <Grid xs={6} item>
                                <Typography variant="h4">
                                    Type
                                </Typography>
                                <Typography variant="h6" color="grey.main">
                                    {state.type.toUpperCase()}
                                </Typography>
                            </Grid>


                            <Grid xs={6} item>
                                <Typography variant="h4">
                                    Status
                                </Typography>
                                <Typography variant="h6" color="grey.main">
                                    {!(user && state.userId && state.userId._id != user.data._id) && createBadge(state.status)}


                                    {(user && state.userId && state.userId._id != user.data._id) &&


                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={state.status}
                                            onChange={(e) => { onUpdateField('status', e.target.value) }}
                                        >
                                            {
                                                Object.keys(REIMBURSEMENT_STATUS).map((key) => {

                                                    return <MenuItem value={REIMBURSEMENT_STATUS[key]} >
                                                        {REIMBURSEMENT_STATUS[key].toUpperCase()}
                                                    </MenuItem>

                                                })
                                            }
                                        </Select>
                                        // </FormControl>


                                    }
                                </Typography>
                            </Grid>


                            {state.actionedBy && <Grid xs={6} item>
                                <Typography variant="h4">
                                    {state.status}  by
                                </Typography>
                                <Typography variant="h6" color="grey.main">
                                    
                                    {(state.actionedBy.first_name + " " + state.actionedBy.last_name)}
                                </Typography>
                            </Grid>}






                            {
                                state.type === REIMBURSEMENT_TYPE.food && <FoodTypeDetails state={state} />
                            }
                            {
                                state.type === REIMBURSEMENT_TYPE.fuel && <FuelTypeDetails state={state} />
                            }
                            {
                                state.type === REIMBURSEMENT_TYPE.travel && <TravelTypeDetails state={state} />
                            }
                            {
                                state.type === REIMBURSEMENT_TYPE.other && <OtherTypeDetails state={state} />
                            }

                            <Grid xs={12} item>
                                <Typography variant="h4">
                                    Amount:
                                </Typography>
                                <Typography variant="h6" color="grey.main">
                                    ₹ {state.amount}
                                </Typography>
                            </Grid>


                        </Grid>

                        {state.bills && Array.isArray(state.bills) && state.bills.length > 0 && <>


                            <Typography variant="h4" mt={2}>Bills:</Typography>
                            <Grid container spacing={2} rowSpacing={3}>
                                {state.bills.map((bill, index) =>
                                    <Grid md={6} xs={12} item key={index}>
                                        {/* <PdfComponent sx={{ width: "100%" }} src={parse(bill)} alt="" /> */}
                                        <FileDownloadComponent src={parse(bill)} />
                                    </Grid>
                                )
                                }
                            </Grid>

                        </>}

                    </Box>
                </Box>
            </Box>
        }
        {
            !state && !loading && <CenteredBox><Typography variant="h3">Loading Data...</Typography></CenteredBox>
        }
        {
            loading && <CenteredBox sx={{ minHeight: "300px" }}><CircularProgress /></CenteredBox>
        }

    </>
}
export default ReimberesementDetailsPageUi