import axios from "axios";
import { getHeaders, getFileHeaders } from "../utils/helper";
import endpoints from "./endpoints";

export const getFileOrImage = async (data) => {
  return await axios({
    url: endpoints.fileOrImage,
    method: "get",
    headers: getHeaders(),
    params: data,
    responseType: "blob"
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response }));
};

export const getDirectFileOrImage = async (url) => {
  return await axios({
    url,
    method: "get",
    // headers: getHeaders(),
    
    responseType: "blob"
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response }));
};

export const uploadFile = async (data, onUploadProgress = () => { }) => {
  const callResponse = await axios({
    url: endpoints.file,
    method: "post",
    data,
    onUploadProgress,
    headers: getFileHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }));

  return callResponse;
};

export const uploadImage = async (data, onUploadProgress = () => { }) => {
  const callResponse = await axios({
    url: endpoints.image,
    method: "post",
    data,
    onUploadProgress,
    headers: getFileHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }));

  return callResponse;
};

export const deletefileOrImage = async (src) => {
  const callResponse = await axios({
    url: endpoints.fileOrImage,
    method: "delete",
    headers: getHeaders(),
    data: { fileUrl: src },
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }));

  return callResponse;
};

// export const updateBranch = async (data) => {
//   const callResponse = await axios({
//     url: endpoints.branch,
//     method: "patch",
//     headers: getHeaders(),
//     data,
//   })
//     .then((response) => response.message)
//     .catch((err) => ({status:0,response:err.response,code:err.response.status}));

//   return callResponse;
// };
