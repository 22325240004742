import { useCallback, useEffect, useState } from "react";
import UserPageUi from "./UserPageUI";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { getUserApi, updateUserField } from "../../apis/user.api";
import { useNavigate, useParams } from "react-router-dom";
import { openModal } from "../../store/actions/modalAction";
import UserCreateController from "./UserCreateController";
import { useSelector } from "react-redux";
import { getUserByIdApi } from "../../apis/user.api";
import { updateUserState } from "../../apis/user.api";
import fileDownload from "js-file-download"

const UserController = () => {
  const user = useSelector((state) => state.user);
  const role = user.data.role;
  const [list, setList] = useState({});
  const [roles, setRoles] = useState([]);

  const [loading, setLoading] = useState(false);
  const [exportLoading, setexportLoading] = useState(false);

  const [listLoading, setListLoading] = useState(false);

  const dispatch = useDispatch();
  const params = useParams();
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    role: null,
    searchable: ["name", "email"],
  });

  const fetchList = (isExport) => {
    if(isExport){
      setexportLoading(true)
      dispatch(callApiAction(
          async () => await getUserApi({ ...filters, csv: true }),
          (response) => {
              fileDownload(response, "users.csv")
              setexportLoading(false)
          },
          (err) => {
              console.log(err)
              setexportLoading(false)
          },
          true
      ))
    }else{
    setListLoading(true);
    dispatch(
      callApiAction(
        async () => await getUserApi({ ...filters }),
        (response) => {
          
          setList(response);
          setListLoading(false);
        },
        (err) => {
          setListLoading(false);
        }
      )
    );}
  };
const navigate = useNavigate()
  const onCreateBtnClick = () => {
    navigate('create')
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

 
  return (
    <>
      <UserPageUi
      fetchList={fetchList}
      exportLoading={exportLoading}
        onCreateBtnClick={onCreateBtnClick}
        listLoading={listLoading}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        roles={roles}
        
      />
    </>
  );
};
export default UserController;
