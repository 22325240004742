import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserByIdApi, updateUserField } from "../../apis/user.api";
import { callApiAction } from "../../store/actions/commonAction";
import { loggedInUser } from "../../utils/helper";
import { fetchDepartments, deleteDepartment } from "../../apis/department";
import DepartmentUI from "./DepartmentUi";

const DepartmentController = ({ userId }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const onUpdateValue = () => {};
  const fetchDepartmentsApi = fetchDepartments;
  const deleteDepartmentApi = deleteDepartment;

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await fetchDepartmentsApi(),
        (response) => {
          setState(response.result);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const DeleteDepartment = (id) => {
    
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteDepartmentApi({ id }),
        (response) => {
          setLoading(false);
          
        },
        (err) => {
          setLoading(false);
          console.log(err);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <DepartmentUI
      setState={setState}
      loading={loading}
      state={state}
      callBack={fetchList}
      DeleteDepartment={DeleteDepartment}
    />
  );
};
export default DepartmentController;
