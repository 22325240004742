import {
  Box,
  Button,
  Checkbox,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slide,
  Typography,
  TextField,
  CircularProgress,
  styled,
  TableContainer,
  Item,
  Skeleton,
  TableRow,
  TableCell
} from "@mui/material";
import { useState, useEffect } from "react";
import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker"
import moment from 'moment'
import { FetchSalaryApi, fetchSalaryDetailsApi } from "../../apis/payroll.api";
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction";
import endpoints from "../../apis/endpoints";
import axios from 'axios'
import DownloadIcon from '@mui/icons-material/Download';
import jsPDF from 'jspdf'
import domtoimage from 'dom-to-image'
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";
import { CenteredBox } from "../../components/layouts/common/boxes";


const SalarySlipUi = () => {

  const data = {};
  const user = useSelector(state => state.user)
  const [date, setDate] = useState(moment())
  const [err, setErr] = useState()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const today = date.toISOString();
  const [details, setDetails] = useState({});

  data['month'] = new Date(today).getMonth()
  data['year'] = new Date(today).getFullYear()



  const url = endpoints.fetchSalary

  const fetchSalaryDetails = async () => {


    dispatch(callApiAction(
      async () => await fetchSalaryDetailsApi({
        month: data.month,
        year: data.year,
        emp_code: user.data.employee_id

      }),
      (response) => {
        setDetails(response)
        setLoading(false)
      },
      (err) => {
        setDetails({})
        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
        setLoading(false)
      }

    ))

  }

  useEffect(() => {


    fetchSalaryDetails()

  }, [date])



  const printDocument = async ({ isSend = false }) => {

    const input = document.getElementById('pdfCreate')
    return await domtoimage
      .toPng(input, { quality: 1 })
      .then(function (dataUrl) {
        /* var link = document.createElement('a')
        link.download = 'my-image-name.jpeg'
        link.href = dataUrl
        link.click() */
        var imgWidth = 300
        var pageHeight = 325
        var imgHeight = (75 * imgWidth) / 110
        var heightLeft = imgHeight
        var doc = new jsPDF('l', 'mm')
        var position = 0

        var imagData = new Image()
        imagData.src = dataUrl
        doc.addImage(
          imagData,
          'PNG',
          0,
          position,
          imgWidth,
          imgHeight,
          // undefined,
          // 'FAST',
        )
        heightLeft -= pageHeight

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          doc.addPage()
          doc.addImage(
            imagData,
            'PNG',
            0,
            position,
            // imgWidth,
            // imgHeight,
            undefined,
            undefined,
            undefined,
            'FAST',
          )
          heightLeft -= pageHeight
        }

        doc.save("salaryslip" + '.pdf')


      })

      .catch((err) => {
        alert(err.message)
        setLoading(false)
      })
  }

  const total_deduction = details.provident_fund + details.prof_tax + details.esic + details.leave_deduction



  return (
    <Box>
      <Box m={3} sx={{ height: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', width: "100%" }} >
          <DialogTitle variant="h2" >{"My Salary Slip"}</DialogTitle>
          <Button sx={{
            display: "flex",
            height: "100%",
            zIndex: 0,
            textTransform: "capitalize",
            boxShadow: "none",
            border: "2px solid #393939",
            width: "30%",
            height: "56px",
            ":hover": {
              boxShadow: "none"
            }
          }}

            onClick={printDocument}
          >
            <DownloadIcon color="primary" /> &nbsp;Download
          </Button>
        </Box>



        <CustomMonthPicker date={date} setDate={setDate} />





        {loading &&
          <>


            <TableRow ><TableCell colSpan={5}  ><Skeleton width={"100%"} height={'50%'} /></TableCell></TableRow>
            <TableRow ><TableCell colSpan={5}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
            <TableRow ><TableCell colSpan={5}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
          </>


        }

        <Box id="pdfCreate" p={3}>

          {details && details.user && <Box p={1} sx={{ border: "1px solid black" }}>

            <CenteredBox sx={{ flexDirection: "column" }}>
              <Typography align="center" variant="h5">Wehear Innovation Pvt Ltd.</Typography>
              <Typography align="center" variant="body1">805, Supath Complex, Opp. Rasranjan, Nr. Vijay Cross Road, Navrangpura, A'bad</Typography>
              <Typography align="center" variant="h5" mt={4}>SALARY SLIP FOR THE MONTH OF {date.format("MMM YYYY")}.</Typography>
            </CenteredBox>

            <Box>
              <Grid container spacing={0}>
                <Grid item xs={7} sx={{ border: 1,background:"#edcad7", borderBottom: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >{details.user.first_name+" "+ details.user.middle_name+" "+details.user.last_name}</Typography></Grid>
                <Grid item xs={2.5} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Workable Days</Typography></Grid>
                <Grid item xs={2.5} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Working Days</Typography></Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={1.75} sx={{ border: 1,background:"#edcad7", borderBottom: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Emp Code</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center"  >{details.emp_code}</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >DOB</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" >{moment(details.user.dob).format("DD-MM-YYYY")}</Typography></Grid>
                <Grid item xs={2.5} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center"  >{details.workable_days}</Typography></Grid>
                <Grid item xs={2.5} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center"  >{details.worked_days}</Typography></Grid>
              </Grid>


              <Grid container spacing={0}>
                <Grid item xs={1.75} sx={{ border: 1,background:"#edcad7", borderBottom: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Copmany Name</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center"  >Wehear Innovation Pvt. Ltd</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >DOJ</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" >{moment(details.user.join_date).format("DD-MM-YYYY")}</Typography></Grid>


                <Grid item xs={1.66} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" > Leaves Types</Typography></Grid>
                <Grid item xs={0.83} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >PH </Typography></Grid>
                <Grid item xs={0.83} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >SL</Typography></Grid>
                <Grid item xs={0.83} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >CL</Typography></Grid>
                <Grid item xs={0.83} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >PL</Typography></Grid>

              </Grid>




              <Grid container spacing={0}>
                <Grid item xs={1.75} sx={{ border: 1,background:"#edcad7", borderBottom: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Emp Type</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center"  >{details.user.employee_type && details.user.employee_type.name}</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Bank</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" >{details.user.bank_details && details.user.bank_details.bank_name}</Typography></Grid>


                <Grid item xs={1.66} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold"> Leaves Used</Typography></Grid>
                <Grid item xs={0.83} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" >{details.ph} </Typography></Grid>
                <Grid item xs={0.83} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" >{details.sl}</Typography></Grid>
                <Grid item xs={0.83} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" >{details.cl}</Typography></Grid>
                <Grid item xs={0.83} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" >{details.pl}</Typography></Grid>
              </Grid>


              <Grid container spacing={0}>
                <Grid item xs={1.75} sx={{ border: 1,background:"#edcad7", borderBottom: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Department</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center"  >{details.user.department && details.user.department.name}</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Account#</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" >{details.user.bank_details && details.user.bank_details.account_no}</Typography></Grid>


                <Grid item xs={1.25} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Leave Deduction</Typography></Grid>
                <Grid item xs={1.25} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" >{details.leave_deduction_count}</Typography></Grid>
                <Grid item xs={1.25} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Balance Leave</Typography></Grid>
                <Grid item xs={1.25} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" >{details.leave_balance}</Typography></Grid>


              </Grid>

              <Grid container spacing={0}>
                <Grid item xs={1.75} sx={{ border: 1,background:"#edcad7", borderBottom: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Designation</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center"  >{details.user.designation}</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >PAN#</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" >{details.user.pan_no}</Typography></Grid>


                <Grid item xs={1.25} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >PAF No#</Typography></Grid>
                <Grid item xs={1.25} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" >{details.user.pf && details.user.pf.pf_id ? details.user.pf.pf_id : "NA"}</Typography></Grid>


                <Grid item xs={1.25} sx={{ border: 1,background:"#edcad7", borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >ESI No#</Typography></Grid>
                <Grid item xs={1.25} sx={{ border: 1, borderBottom: 0, borderLeft: 0 }}><Typography variant="body1" align="center" >{details.user.esic && details.user.esic.esic_id ? details.user.esic.esic_id : "NA"}</Typography></Grid>

              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={1.75} sx={{ border: 1 ,background:"#edcad7",}}><Typography variant="body1" align="center" fontWeight="bold" >Branch</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1, borderLeft: 0 }}><Typography variant="body1" align="center"  >{details.user.branch && details.user.branch.name}</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1,background:"#edcad7", borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Aadhar#</Typography></Grid>
                <Grid item xs={1.75} sx={{ border: 1, borderLeft: 0 }}><Typography variant="body1" align="center" >{details.user.aadhar_no}</Typography></Grid>


                <Grid item xs={1.25} sx={{ border: 1,background:"#edcad7", borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >EPS No#</Typography></Grid>
                <Grid item xs={1.25} sx={{ border: 1, borderLeft: 0 }}><Typography variant="body1" align="center" >NA</Typography></Grid>


                <Grid item xs={1.25} sx={{ border: 1,background:"#edcad7", borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >UAN NO#</Typography></Grid>
                <Grid item xs={1.25} sx={{ border: 1, borderLeft: 0 }}><Typography variant="body1" align="center" >{details.user.pf && details.user.pf.uan_id ? details.user.pf.uan_id : "NA"}</Typography></Grid>




              </Grid>


              <Grid container spacing={0} sx={{ mt: 4 }}>
                <Grid item xs={8} sx={{ border: 1,background:"#edcad7", borderBottom: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >EARNING</Typography></Grid>
                <Grid item xs={4} sx={{ border: 1,background:"#edcad7", borderLeft: 0, borderBottom: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >DEDUCTIONS</Typography></Grid>

              </Grid>
              <Grid container spacing={0} sx={{}}>
                <Grid item xs={3} sx={{ border: 1, background:"#edcad7",}}><Typography variant="body1" align="center" fontWeight="bold" >Pay Heads</Typography></Grid>
                <Grid item xs={2} sx={{ border: 1, background:"#edcad7",}}><Typography variant="body1" align="center" fontWeight="bold" >Rate</Typography></Grid>
                <Grid item xs={1} sx={{ border: 1, background:"#edcad7",}}><Typography variant="body1" align="center" fontWeight="bold" >Arrears Amount</Typography></Grid>
                <Grid item xs={1} sx={{ border: 1, background:"#edcad7",}}><Typography variant="body1" align="center" fontWeight="bold" >Current Month Amount</Typography></Grid>
                <Grid item xs={1} sx={{ border: 1, background:"#edcad7",}}><Typography variant="body1" align="center" fontWeight="bold" >YTD Amount</Typography></Grid>

                <Grid item xs={2} sx={{ border: 1,background:"#edcad7", borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Deduction Head</Typography></Grid>
                <Grid item xs={1} sx={{ border: 1,background:"#edcad7", borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Current Month Amount</Typography></Grid>
                <Grid item xs={1} sx={{ border: 1,background:"#edcad7", borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >YTD Amount</Typography></Grid>


              </Grid>


              <Grid container spacing={0} sx={{}}>
                <Grid item xs={3} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Basic Salary</Typography></Grid>
                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.user.salary && details.user.salary.basic_salary}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.basic_salary}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.user.salary && (details.user.salary.basic_salary * 12)}</Typography></Grid>

                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Provident Fund</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.provident_fund}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}><Typography variant="body1" align="center"  >{details.provident_fund * 12}</Typography></Grid>


              </Grid>


              <Grid container spacing={0} sx={{}}>
                <Grid item xs={3} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >House Rent Allowance</Typography></Grid>
                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.user.salary && details.user.salary.house_rent_allowance}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.house_rent_allowance}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.user.salary && (details.user.salary.house_rent_allowance * 12)}</Typography></Grid>

                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Proffesional Tax</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.prof_tax}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}><Typography variant="body1" align="center"  >{details.prof_tax * 12}</Typography></Grid>


              </Grid>


              <Grid container spacing={0} sx={{}}>
                <Grid item xs={3} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Conveyence Allowance</Typography></Grid>
                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.user.salary && details.user.salary.conveyence_allowance}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.conveyance_allowance}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.user.salary && (details.user.salary.conveyence_allowance * 12)}</Typography></Grid>

                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >ESIC</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.esic}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}><Typography variant="body1" align="center"  >{details.esic * 12}</Typography></Grid>


              </Grid>



              <Grid container spacing={0} sx={{}}>
                <Grid item xs={3} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Food Allowance</Typography></Grid>
                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.user.salary && details.user.salary.food_allowance}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.food_allowance}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.user.salary && (details.user.salary.food_allowance * 12)}</Typography></Grid>

                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Leave Deduction</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.leave_deduction}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}><Typography variant="body1" align="center"  >{details.leave_deduction * 12}</Typography></Grid>


              </Grid>


              <Grid container spacing={0} sx={{}}>
                <Grid item xs={3} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Other Allowance</Typography></Grid>
                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.user.salary && details.user.salary.other_allowance}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.other_allowance}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.user.salary && (details.user.salary.other_allowance * 12)}</Typography></Grid>

                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}></Grid>


              </Grid>


              <Grid container spacing={0} sx={{}}>
                <Grid item xs={3} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Medical Allowance</Typography></Grid>
                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.user.salary && details.user.salary.medical_allowance}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.medical_allowance}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.user.salary && (details.user.salary.medical_allowance * 12)}</Typography></Grid>

                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}></Grid>


              </Grid>

              <Grid container spacing={0} sx={{}}>
                <Grid item xs={3} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Incentive</Typography></Grid>
                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.user.salary && details.user.salary.incencitive}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.incentive}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{details.user.salary && (details.user.salary.incencitive * 12)}</Typography></Grid>

                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                <Grid item xs={1} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}></Grid>


              </Grid>


              <Grid container spacing={0} sx={{}}>
                <Grid item xs={3} sx={{ border: 1, background:"#edcad7", }}><Typography variant="body1" align="center"  >Total Earning</Typography></Grid>
                <Grid item xs={2} sx={{ border: 1, borderLeft: 0,background:"#edcad7", }}><Typography variant="body1" align="center"  >{details.user.salary && (
                  details.user.salary.incencitive +
                  details.user.salary.basic_salary +
                  details.user.salary.house_rent_allowance +
                  details.user.salary.conveyence_allowance +
                  details.user.salary.food_allowance +
                  details.user.salary.other_allowance +
                  details.user.salary.medical_allowance


                )}</Typography></Grid>
                <Grid item xs={1} sx={{ border: 1, borderLeft: 0,background:"#edcad7", }}><Typography variant="body1" align="center"  ></Typography></Grid>
                <Grid item xs={1} sx={{ border: 1, borderLeft: 0,background:"#edcad7", }}><Typography variant="body1" align="center"  >{
                  details.incentive +
                  details.basic_salary +
                  details.house_rent_allowance +
                  details.conveyance_allowance +
                  details.food_allowance +
                  details.other_allowance +
                  details.medical_allowance
                }</Typography></Grid>
                <Grid item xs={1} sx={{ border: 1, borderLeft: 0,background:"#edcad7", }}><Typography variant="body1" align="center"  >{details.user.salary && ((
                  details.user.salary.incencitive +
                  details.user.salary.basic_salary +
                  details.user.salary.house_rent_allowance +
                  details.user.salary.conveyence_allowance +
                  details.user.salary.food_allowance +
                  details.user.salary.other_allowance +
                  details.user.salary.medical_allowance


                ) * 12)}</Typography></Grid>

                <Grid item xs={2} sx={{ border: 1, borderLeft: 0,background:"#edcad7", }}><Typography variant="body1" align="center"  >Total</Typography></Grid>
                <Grid item xs={1} sx={{ border: 1, borderLeft: 0,background:"#edcad7", }}><Typography variant="body1" align="center"  >
                  {details.provident_fund + details.prof_tax + details.esic + details.leave_deduction}
                </Typography></Grid>
                <Grid item xs={1} sx={{ border: 1, borderLeft: 0,background:"#edcad7", borderRight: 1 }}>

                  <Typography variant="body1" align="center"  >
                    {(details.provident_fund + details.prof_tax + details.esic + details.leave_deduction) * 12}
                  </Typography>
                </Grid>


              </Grid>
              <Box mt={2} mb={2}>
                <Typography variant="h5" align="center">
                  Net Pay = 
                   {( details.incentive +
                  details.basic_salary +
                  details.house_rent_allowance +
                  details.conveyance_allowance +
                  details.food_allowance +
                  details.other_allowance +
                  details.medical_allowance)-(details.provident_fund + details.prof_tax + details.esic + details.leave_deduction)}
                </Typography>
              </Box>

            </Box>
          </Box>

          }





















        </Box>

























































        {details && details.user && false &&
          <>


            <Box id="pdfCreate">
              <Grid container spacing={2} sx={{ height: "100%%", padding: 2 }}>
                <Grid item xs={8} sx={{ display: "flex", flexDirection: "column" }} >
                  <Grid item xs={8}>
                    <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>{details.user.name}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", marginX: "2" }}  >
                    <Grid item sx={{ border: 2, textAlign: "center" }} xs={3}>
                      Emp_code
                    </Grid>
                    <Grid item sx={{ border: 2, borderLeft: 0, textAlign: "center" }} xs={4}>
                      {details.emp_code}
                    </Grid>
                    <Grid item sx={{ border: 2, borderLeft: 0, textAlign: "center" }} xs={3}>
                      DOB
                    </Grid>
                    <Grid item sx={{ border: 2, borderLeft: 0, textAlign: "center" }} xs={4}>
                      {moment(details.user.dob).format("DD-MM-YYYY")}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", marginX: "2", flexDirection: "row" }}  >
                    <Grid item xs={3} sx={{ border: 2, textAlign: "center", borderTop: 0 }}>
                      Company Name
                    </Grid>
                    <Grid item xs={4} sx={{ border: 2, borderLeft: 0, borderTop: 0, textAlign: "center" }}>
                      Wehear Innovations Pvt Ltd
                    </Grid>
                    <Grid item xs={3} sx={{ border: 2, borderLeft: 0, textAlign: "center", borderTop: 0, }}>
                      DOJ
                    </Grid>
                    <Grid item xs={4} sx={{ border: 2, borderLeft: 0, textAlign: "center", borderTop: 0, }}>
                      {moment(details.user.join_date).format("DD-MM-YYYY")}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", marginX: "2", flexDirection: "row" }} >
                    <Grid item xs={3} sx={{ border: 2, borderTop: 0, textAlign: "center" }} >
                      Emp Type
                    </Grid>
                    <Grid item xs={4} sx={{ border: 2, borderLeft: 0, textAlign: "center", borderTop: 0, }}>
                      {details.user.employee_type && details.user.employee_type.name}
                    </Grid>
                    <Grid item xs={3} sx={{ border: 2, borderLeft: 0, textAlign: "center", borderTop: 0, }}>
                      Bank
                    </Grid>
                    <Grid item xs={4} sx={{ border: 2, borderLeft: 0, textAlign: "center", borderTop: 0, }}>
                      {details.user.bank_details.bank_name}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", marginX: "2" }}  >
                    <Grid item xs={3} sx={{ border: 2, textAlign: "center", borderTop: 0, }}>
                      Department
                    </Grid>
                    <Grid item xs={4} sx={{ border: 2, borderLeft: 0, textAlign: "center", borderTop: 0, }}>
                      {details.user.department && details.user.department.name}
                    </Grid>
                    <Grid item xs={3} sx={{ border: 2, borderLeft: 0, textAlign: "center", borderTop: 0, }}>
                      Account
                    </Grid>
                    <Grid item xs={4} sx={{ border: 2, borderLeft: 0, textAlign: "center", borderTop: 0, }}>
                      {details.user.bank_details.account_no}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", marginX: "2" }}  >
                    <Grid item xs={3} sx={{ border: 2, textAlign: "center", borderTop: 0, }}>
                      Designation
                    </Grid>
                    <Grid item xs={4} sx={{ border: 2, borderLeft: 0, borderTop: 0, textAlign: "center", borderTop: 0, }}>
                      {details.user.designation}
                    </Grid>
                    <Grid item xs={3} sx={{ border: 2, borderLeft: 0, textAlign: "center", borderTop: 0, }}>
                      PAN
                    </Grid>
                    <Grid item xs={4} sx={{ border: 2, borderLeft: 0, textAlign: "center", borderTop: 0, }}>
                      {details.user.pan_no}
                    </Grid>
                  </Grid>
                  <Grid item sx={{ display: "flex", flexDirection: "column" }}> </Grid>
                  <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", marginX: "2" }} >
                    <Grid item xs={3} sx={{ border: 2, textAlign: "center", borderTop: 0, }}>
                      Branch
                    </Grid>
                    <Grid item xs={4} sx={{ border: 2, borderLeft: 0, textAlign: "center", borderTop: 0, }}>
                      {details.user.branch && details.user.branch.name}
                    </Grid>
                    <Grid item xs={3} sx={{ border: 2, borderLeft: 0, textAlign: "center", borderTop: 0, }}>
                      Aadhar
                    </Grid>
                    <Grid item xs={4} sx={{ border: 2, borderLeft: 0, textAlign: "center", borderTop: 0, }}>
                      {details.user.aadhar_no}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} sx={{ display: "flex", flexDirection: "column" }} >

                  <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }} border={2}>
                    <Grid item xs={12} sx={{ textAlign: "center", marginX: "2", display: "flex", border: 2, borderTop: "0", borderBottom: "0", borderLeft: "0", flexDirection: "column", borderRight: "2" }} >
                      Workable Days
                      <Grid item xs={12} sx={{ textAlign: "center" }} >
                        {details.workable_days}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "center", marginX: "2", display: "flex", flexDirection: "column", borderBottom: "0" }} >
                      Worked Days
                      <Grid item xs={12} sx={{ borderBottom: "0", textAlign: "center" }}>
                        {details.worked_days}

                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", marginX: "2", }} >
                    <Grid item xs={4} border={2} sx={{ borderTop: "0", textAlign: "center" }}>Leave Type </Grid>
                    <Grid item xs={2} border={2} sx={{ borderTop: "0", borderLeft: "0", textAlign: "center" }}>PH</Grid>
                    <Grid item xs={2} border={2} sx={{ borderLeft: "0", textAlign: "center" }} >SL</Grid>
                    <Grid item xs={2} border={2} sx={{ borderLeft: "0", textAlign: "center" }}>CL</Grid>
                    <Grid item xs={2} border={2} sx={{ borderLeft: "0", textAlign: "center" }}>PL</Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", textAlign: "center" }} >
                    <Grid item xs={4} border={2} sx={{ borderRight: "0", borderTop: "0" }}>Leave Used</Grid>
                    <Grid item xs={2} border={2} sx={{ borderRight: "0", borderTop: "0" }}>{details.ph}</Grid>
                    <Grid item xs={2} border={2} sx={{ borderRight: "0", borderTop: "0" }}>{details.sl}</Grid>
                    <Grid item xs={2} border={2} sx={{ borderRight: "0", borderTop: "0" }}>{details.cl}</Grid>
                    <Grid item xs={2} border={2} sx={{ borderRight: "0", borderTop: "0", }} >{details.pl}</Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", marginX: "2", textAlign: "center" }} >
                    <Grid item xs={3} border={2} sx={{ borderRight: "0", borderTop: "0" }}>Leave Deduction</Grid>
                    <Grid item xs={3} border={2} sx={{ borderRight: "0", borderTop: "0" }}>{details.leave_deduction}</Grid>
                    <Grid item xs={3} border={2} sx={{ borderRight: "0", borderTop: "0" }}>Balance Leave</Grid>
                    <Grid item xs={3} border={2} sx={{ borderTop: "0" }}>{details.leave_balance}</Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", marginX: "2", textAlign: "center" }} >
                    <Grid item xs={2} border={2} sx={{ borderRight: "0", borderTop: "0" }}>PF no</Grid>
                    <Grid item xs={4} border={2} sx={{ borderRight: "0", borderTop: "0" }}>{details.user.pf.pf_id}</Grid>
                    <Grid item xs={2} border={2} sx={{ borderRight: "0", borderTop: "0" }}>ESI no</Grid>
                    <Grid item xs={4} border={2} sx={{ borderTop: "0" }}>{details.user.esic.esic_id}</Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", marginX: "2", textAlign: "center" }} >
                    <Grid item xs={2} border={2} sx={{ borderRight: "0", borderTop: "0" }}>EPS no</Grid>
                    <Grid item xs={4} border={2} sx={{ borderRight: "0", borderTop: "0" }}>NA</Grid>
                    <Grid item xs={2} border={2} sx={{ borderRight: "0", borderTop: "0" }}>UAN</Grid>
                    <Grid item xs={4} border={2} sx={{ borderTop: "0" }}>{details.user.pf.uan_id}</Grid>
                  </Grid>


                </Grid>


              </Grid>
              <Grid container >
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", textAlign: "center", }} >
                  <Grid item xs={8} sx={{ fontWeight: "bold" }}>
                    EARNINGS
                  </Grid>
                  <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                    DEDUCTIONS
                  </Grid>
                </Grid>

              </Grid>
              <Grid container spacing={2} sx={{ height: "100%", padding: 2 }}>
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }}  >
                  <Grid item xs={3} sx={{ display: "flex", flexDirection: "column", marginY: "2", textAlign: "center" }} border={2} >
                    <Grid item xs={12} padding={1} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0" }} >
                      Pay Heads
                    </Grid>
                    <Grid item xs={12} padding={1} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0" }}  >
                      Basic Salary
                    </Grid>
                    <Grid item xs={12} padding={1} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0" }} >
                      HOUSE RENT ALLOWANCE
                    </Grid>
                    <Grid item xs={12} padding={1} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0" }} >
                      CONVEYANCE ALLOWANCE
                    </Grid>
                    <Grid item xs={12} padding={1} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0" }} >
                      FOOD ALLOWANCE
                    </Grid>
                    <Grid item xs={12} padding={1} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0" }} >
                      OTHER ALLOWANCE
                    </Grid>
                    <Grid item xs={12} padding={1} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0" }} >
                      INCENTIVE
                    </Grid>
                    <Grid item xs={12} padding={1} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", borderBottom: "0" }} >
                      MEDICAL ALLOWANCE
                    </Grid>
                    <Grid item xs={12} padding={1} border={2} sx={{ borderLeft: "0", borderRight: "0", borderBottom: "0", fontWeight: "bold" }} >
                      Total Earnings
                    </Grid>
                  </Grid>
                  <Grid item xs={1} sx={{ display: "flex", flexDirection: "column", marginY: "2", textAlign: "center", borderLeft: "0", borderRight: "0" }} border={2} >
                    Rate
                    <Grid item xs={12} marginTop={2} border={2} sx={{ borderLeft: "0", borderRight: "0", }} >
                      {details.user.salary.basic_salary}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {details.user.salary.house_rent_allowance}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {details.user.salary.conveyence_allowance}

                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {details.user.salary.food_allowance}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {details.user.salary.other_allowance}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {details.user.salary.incencitive}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", borderBottom: "0" }} >
                      {details.user.salary.medical_allowance}
                    </Grid>
                    <Grid item xs={12} sx={{ borderLeft: "0", borderRight: "0", borderBottom: "0", fontWeight: "bold" }} border={2}   >
                      {details.user.salary.basic_salary + details.user.salary.house_rent_allowance + details.user.salary.conveyence_allowance + details.user.salary.food_allowance + details.user.salary.other_allowance + details.user.salary.incencitive + details.user.salary.medical_allowance}
                    </Grid>
                  </Grid>
                  <Grid item xs={1} sx={{ display: "flex", flexDirection: "column", textAlign: "center" }} border={2} >
                    <Grid item xs={12} border={2} sx={{ fontSize: 12, borderTop: 0, borderRight: "0", borderLeft: "0" }} >
                      Arrears Amount

                    </Grid>
                    <Grid item xs={12} >

                    </Grid>
                    <Grid item xs={12} >

                    </Grid>
                    <Grid item xs={12} >

                    </Grid>
                    <Grid item xs={12} >

                    </Grid>
                    <Grid item xs={12} >

                    </Grid>
                    <Grid item xs={12} >

                    </Grid>
                    <Grid item xs={12} >

                    </Grid>
                    <Grid item xs={12} sx={{ borderLeft: "0", borderRight: "0", borderBottom: "0", fontWeight: "bold" }} border={2}   >
                      0.00
                    </Grid>
                  </Grid>

                  <Grid item xs={2} sx={{ display: "flex", flexDirection: "column", marginY: "2", textAlign: "center", borderLeft: "0", borderRight: "0" }} border={2} >
                    Current Month Amount
                    <Grid item xs={12} marginTop={2} border={2} sx={{ borderLeft: "0", borderRight: "0", }} >
                      {details.basic_salary}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {details.house_rent_allowance}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {details.conveyence_allowance}

                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {details.food_allowance}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {details.other_allowance}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {details.incencitive}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", borderBottom: "0" }} >
                      {details.medical_allowance}
                    </Grid>
                    <Grid item xs={12} sx={{ borderLeft: "0", borderRight: "0", borderBottom: "0", fontWeight: "bold" }} border={2}   >
                      {
                        details.basic_salary + details.house_rent_allowance + details.conveyence_allowance + details.food_allowance + details.other_allowance + details.incencitive + details.medical_allowance
                      }
                    </Grid>
                  </Grid>
                  <Grid item xs={1} sx={{ fontSize: 14, display: "flex", flexDirection: "column", marginY: "2", textAlign: "center" }} border={2} >
                    YTD Amount
                    <Grid item xs={12} marginTop={2} border={2} sx={{ borderLeft: "0", borderRight: "0", }} >
                      {12 * details.basic_salary}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      0
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      0

                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      0
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      0
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      0
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", borderBottom: "0" }} >
                      0
                    </Grid>
                    {/* <Grid item xs = {4} > 
    {details.user.salary.basic_salary+ details.user.salary.house_rent_allowance + details.user.salary.conveyence_allowance + details.user.salary.food_allowance + details.user.salary.other_allowance + details.user.salary.incencitive + details.user.salary.medical_allowance  }
    </Grid> */}
                  </Grid>
                  <Grid item xs={2} sx={{ display: "flex", flexDirection: "column", textAlign: "center", borderBottom: "0" }} border={2} marginLeft={2}  >
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }}  >
                      Deduction Head </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      PROVIDENT FUND
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      PROF. TAX
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      ESIC
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      Leave Deduction
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", fontWeight: "bold" }} >
                      Total Deductions
                    </Grid>
                  </Grid>
                  <Grid item xs={1} sx={{ display: "flex", flexDirection: "column", textAlign: "center", borderLeft: "0", borderTop: "0", borderBottom: "0" }} border={2}  >
                    <Grid item xs={12} border={2} sx={{ fontSize: 13, borderLeft: "0", borderRight: "0", }}  >
                      Current Month Amount
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {details.provident_fund}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {details.prof_tax}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {details.esic}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {details.leave_deduction}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", fontWeight: "bold" }} >
                      {details.provident_fund + details.prof_tax + details.esic + details.leave_deduction}
                    </Grid>
                  </Grid>
                  <Grid item xs={1} sx={{ display: "flex", flexDirection: "column", textAlign: "center", borderLeft: "0", borderBottom: "0", borderTop: "0" }} border={2} >
                    <Grid item xs={12} border={2} sx={{ fontSize: 13, borderLeft: "0", borderRight: "0", }}  >
                      YTD Amount
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {12 * details.provident_fund}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >
                      {12 * details.prof_tax}
                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >

                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", }} >

                    </Grid>
                    <Grid item xs={12} border={2} sx={{ borderTop: "0", borderLeft: "0", borderRight: "0", fontWeight: "bold" }} >
                      {12 * details.provident_fund + 12 * details.prof_tax}
                    </Grid>
                  </Grid>
                </Grid>


                {/* total earnings */}

                {/* <Grid item xs = {8} sx = {{display: "flex", flexDirection: "row", textAlign: "center",}} border =  {2} padding = {1} >
    <Grid item xs = {8}>Total Earnings </Grid>
    <Grid item xs = {8}>{details.user.salary.basic_salary+ details.user.salary.house_rent_allowance + details.user.salary.conveyence_allowance + details.user.salary.food_allowance + details.user.salary.other_allowance + details.user.salary.incencitive + details.user.salary.medical_allowance}</Grid>
    <Grid item xs = {8}>0</Grid>
    <Grid item xs = {8}>{
      details.basic_salary+ details.house_rent_allowance + details.conveyence_allowance + details.food_allowance + details.other_allowance + details.incencitive + details.medical_allowance
    } </Grid>
    <Grid item xs = {8}>{12*details.basic_salary} </Grid> 
    </Grid>
    <Grid item xs = {4} sx = {{display: "flex", flexDirection: "row", textAlign: "center",}} border =  {2} padding = {1} > 
    <Grid item xs = {2}>Total Deductions </Grid>
    <Grid item xs = {1}> {details.provident_fund + details.prof_tax + details.esic + details.leave_deduction} </Grid>
    <Grid item xs = {1}> {12*details.provident_fund + 12*details.prof_tax} </Grid>
    </Grid> */}



              </Grid>
              <DialogTitle variant="h4" sx={{ fontWeight: "bold" }} >{`Net Pay:  ${details.basic_salary - total_deduction} `}</DialogTitle>

            </Box>




          </>
        }






      </Box>



    </Box>
  )
}

export default SalarySlipUi