import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import useValidate from '../../../store/hooks/useValidator'

import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../store/actions/commonAction'



import { useNavigate } from 'react-router-dom'


import { createTaskApi, fetchTasksApi, fetchTasksPerformanceApi, updateTasksPriorityApi } from '../../../apis/task.api'
import { callSnackBar } from '../../../store/actions/snackbarAction'
import { SNACK_BAR_VARIETNS } from '../../../utils/constants'

import PerformanceListUI from './PerformanceListUI'
import { EmployeeTaskUserContext } from '../EmployeeMain'


const PerformanceListController = ({ callBack = () => { } }) => {
  const validate = useValidate()
  const dispatch = useDispatch()
  const { leaveBalance, holiday, user } = useSelector((state) => state)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const selectedUser = useContext(EmployeeTaskUserContext)
  const [filters, setFilters] = useState({
    err: '',
    pageNo: 1,
    pageSize: 25,
    startDate: null,
    endDate: null,
    status: undefined,
    onlyMe: true
  })
  const [list, setList] = useState({})
  


  const fetchList = async (e) => {
    setLoading(true)
    dispatch(
      callApiAction(
        async () => await fetchTasksPerformanceApi({ ...filters, userId: selectedUser ? selectedUser._id : null }),
        async (response) => {
          setLoading(false)
          setList(response)
        },
        (err) => {
          setLoading(false)
          setList({})
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
        },
      ),
    )

  }



  useEffect(() => {
    fetchList()
  }, [filters, selectedUser])


  
  return (
    <PerformanceListUI
    
     
      loading={loading}
      filters={filters}
      list={list}
      fetchList={fetchList}
      
      
      setFilters={setFilters}
    />
  )
}
export default memo(PerformanceListController)
