import {
  Box,
  Button,
  Checkbox,
  Collapse,

  Grid,

  Typography,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import SubmitButton, { ResetButton } from '../../../components/button/SubmitButton'
import CustomInput from '../../../components/inputs/CustomInput'

import CustomRadioButtons from '../../../components/layouts/common/CustomRadioButtons'

import { useNavigate } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { TASK_PRIORITY, TASK_STATUS, TASK_TYPES } from '../../../utils/task.constants'
import { UserSearchBarNormal } from '../../../components/inputs/SearchBar'



const TaskCreateUi = ({
  userId,
  onSubmit,
  loading,
  fields,
  setFields,
  disableDates,
}) => {
  const { holiday, user } = useSelector((state) => state)


  const navigate = useNavigate()

  return (
    <Box p={4} mt={1} component="form" onSubmit={onSubmit}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap",width:"100%" }}>
        <Box >
          <Typography variant='h3'>
            New Task
          </Typography>
          <Typography variant='h6' color="error">
            {fields.err}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant='h4' color="grey.main">
            Application Date
          </Typography >
          <Typography variant='h4' ml={3}>
            {moment().format("DD MMMM YYYY")}
          </Typography>
        </Box>


      </Box>
      <Box sx={{ display: "flex" }} mt={4}>
        <Box sx={{ display: "flex", flex: 4, flexDirection: "column" }}>
          <Typography variant='h4' mt={3}>
            Task Title:*
          </Typography>

          <CustomInput
            name="title"
            id="title"
            disabled={loading}
            value={fields.name}
            onChange={(e) =>
              setFields({ ...fields, err: '', name: e.target.value })
            }
            type="text"
            placeholder="Write Task Title..."

          />

          <Typography variant='h4' mb={2} mt={3}>
            Task Description:*
          </Typography>


          <CKEditor

            name="description"
            id="description"
            placeholder="Write Task Description..."
            disabled={loading}
            editor={ClassicEditor}

            onInit={(editor) => {


            }}
            data={fields.description}
            onReady={(editor) => {

              editor.editing.view.change((writer) => {
                writer.setStyle(
                  "height",
                  "200px",
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            onChange={(event, editor) => {
              const data = editor.getData()
              setFields({ ...fields, err: '', description: data })
            }}
            onBlur={(event, editor) => { }}
            onFocus={(event, editor) => { }}
          />


          <Typography variant='h4' mb={2} mt={3}>
            Assigne To:*
          </Typography>

          <Box>

            <UserSearchBarNormal
            
              defaultOptions={[{ _id: user.data._id, name: "Self", profile_url: null, designation: user.data.designation }]}
              inputProps={{ placeholder: "Select Employee", multiple: true }}
              defaultVal={null}

              onUserChange={(newVal) =>
                setFields({
                  ...fields,
                  err: "",
                  assigned_to: newVal._id,
                })
              }
            />
          </Box>

          <Grid container rowSpacing={3} mt={3} columnSpacing={3}>
            <Grid item xs={12} md={6}>

              <Typography variant='h4'>
                Task Start Date:*
              </Typography>
              <Box sx={{ display: "flex", flex: "none" }} >
                <DesktopDatePicker
                  shouldDisableDate={disableDates}
                  renderInput={(props) => {
                    return <CustomInput {...props} sx={{ height: '100%' }} />
                  }}
                  inputFormat="DD-MM-yyyy"
                  disabled={
                    loading ||
                    !(holiday.data && Array.isArray(holiday.data)) ||
                    holiday.loading
                  }
                  value={fields.start_date}
                  onChange={(e) => {
                    const changedVal = {}
                    changedVal['start_date'] = e
                    if (e.valueOf() >= fields['due_date'].valueOf()) {
                      changedVal['due_date'] = e
                    }
                    setFields({ ...fields, err: '', ...changedVal })
                  }}
                  type="date"
                />

              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h4' >
                Task Due Date:*
              </Typography>
              <Box sx={{ display: "flex", flex: "none" }} >
                <DesktopDatePicker
                  shouldDisableDate={disableDates}
                  renderInput={(props) => {
                    return <CustomInput {...props} sx={{ height: '100%' }} />
                  }}
                  inputFormat="DD-MM-yyyy"
                  disabled={
                    loading ||
                    !(holiday.data && Array.isArray(holiday.data)) ||
                    holiday.loading
                  }
                  value={fields.due_date}
                  onChange={(e) => {
                    const changedVal = {}
                    changedVal['due_date'] = e

                    setFields({ ...fields, err: '', ...changedVal })
                  }}
                  type="date"
                  minDate={fields.start_date}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container rowSpacing={3} mt={3} columnSpacing={3}>
            <Grid item xs={12} md={6}>

              <Typography variant='h4'>
                Task Type:*
              </Typography>
              <Box sx={{ display: "flex", flex: "none" }} mt={3}>
                <CustomRadioButtons
                  options={Object.keys(TASK_TYPES).map((key) => ({ label: key, value: TASK_TYPES[key] }))}
                  value={fields.type}
                  onChange={(e) => {
                    setFields({ ...fields, type: e.target.value })
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h4' >
                Task Priority:*
              </Typography>
              <Box sx={{ display: "flex", flex: "none" }} mt={3}>
                <CustomRadioButtons
                  options={Object.keys(TASK_PRIORITY).map((key) => ({ label: key, value: TASK_PRIORITY[key] }))}
                  value={fields.priority}
                  onChange={(e) => {
                    setFields({ ...fields, priority: e.target.value })
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          {fields.type != TASK_TYPES.ONCE && <>
            <Typography variant='h4' mt={3}>
              Task Should Repeated Till:*
            </Typography>
            <Box sx={{ display: "flex", flex: "none" }}>
              <DesktopDatePicker
                shouldDisableDate={disableDates}
                renderInput={(props) => {
                  return <CustomInput {...props} sx={{ height: '100%' }} />
                }}
                minDate={fields.start_date}
                inputFormat="DD-MM-yyyy"
                disabled={
                  loading ||
                  !(holiday.data && Array.isArray(holiday.data)) ||
                  holiday.loading
                }
                value={fields.till_date}
                onChange={(e) => {
                  const changedVal = {}
                  changedVal['till_date'] = e

                  setFields({ ...fields, err: '', ...changedVal })
                }}
                type="date"
              />
            </Box>

          </>}

          <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>

            <Box sx={{ display: "flex" }} mr={3}>
              <ResetButton
                loading={loading}
                type="reset"
                variant="text"
                onClick={() => { navigate("/tasks/my-tasks/") }}
                title="Cancel"
              >

              </ResetButton>
            </Box>
            <Box sx={{ display: "flex" }}>
              <SubmitButton

                loading={loading}
                type="submit"
                variant="contained"
                color="primary"
                title="Assign Task"
              >

              </SubmitButton>
            </Box>
          </Box>
        </Box>
        <Box sx={{ flex: 1 }}></Box>


      </Box>
    </Box>
  )
}
export default TaskCreateUi