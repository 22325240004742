import axios from 'axios'
import { refreshToken } from '../utils/helper'
import { getHeaders } from '../utils/helper'
import endpoints from './endpoints'

export const getUserById = async (data) => {
  // console.log(1)
  const callResponse = await axios({
    url: endpoints.getUserById,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const createTask = async (data) => {
  // console.log(1)
  const callResponse = await axios({
    url: endpoints.createTask,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const getAllMyTasks = async (data) => {
  const callResponse = await axios({
    url: endpoints.getAllMyTasks,
    method: 'get',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const getAllMyTasksOtherRoles = async (data) => {
  const callResponse = await axios({
    url: endpoints.getAllMyTasksOtherRoles,
    method: 'get',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const getPeopleUnderMe = async () => {
  const callResponse = await axios({
    url: endpoints.getPeopleUnderMe,
    method: 'get',
    headers: getHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const changeTaskStatusToReject = async (data) => {
  const callResponse = await axios({
    url: endpoints.changeTaskStatusToReject,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const changeTaskStatusToApprove = async (data) => {
  const callResponse = await axios({
    url: endpoints.changeTaskStatusToApprove,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const changeTaskStatusToMarkAsCompleted = async (data) => {
  const callResponse = await axios({
    url: endpoints.changeTaskStatusToMarkAsCompleted,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const GetPendingForApproval = async () => {
  const callResponse = await axios({
    url: endpoints.GetPendingForApproval,
    method: 'get',
    headers: getHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const GetApprovedTask = async (data) => {
  const callResponse = await axios({
    url: endpoints.GetApprovedTask,
    method: 'get',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const GetApprovedTaskOtherRoles = async (data) => {
  const callResponse = await axios({
    url: endpoints.GetApprovedTaskOtherRoles,
    method: 'get',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const Mark_As_Completed_Tasks = async (data) => {
  const callResponse = await axios({
    url: endpoints.Mark_As_Completed_Tasks,
    method: 'get',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const Mark_As_Completed_TasksOtherRoles = async (data) => {
  const callResponse = await axios({
    url: endpoints.Mark_As_Completed_TasksOtherRoles,
    method: 'get',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const GetTodayDueTask = async (data) => {
  const callResponse = await axios({
    url: endpoints.GetTodayDueTask,
    method: 'get',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const GetTodayDueTaskOtherRoles = async (data) => {
  const callResponse = await axios({
    url: endpoints.GetTodayDueTaskOtherRoles,
    method: 'get',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const CurrentWeekTask = async (data) => {
  const callResponse = await axios({
    url: endpoints.CurrentWeekTask,
    method: 'get',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const CurrentWeekTaskOtherRoles = async (data) => {
  const callResponse = await axios({
    url: endpoints.CurrentWeekTaskOtherRoles,
    method: 'get',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const performenceEvaluation = async () => {
  const callResponse = await axios({
    url: endpoints.performenceEvaluation,
    method: 'get',
    headers: getHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const performenceEvaluationWithEmployeeId = async (data) => {
  const callResponse = await axios({
    url: endpoints.performenceEvaluationWithId + `${data}`,
    method: 'get',
    headers: getHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const getReopenReport = async () => {
  const callResponse = await axios({
    url: endpoints.getReopenReport,
    method: 'get',
    headers: getHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const getPerformenceCalender = async () => {
  const callResponse = await axios({
    url: endpoints.getPerformenceCalender,
    method: 'get',
    headers: getHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const getPerformenceCalenderWithId = async (data) => {
  const callResponse = await axios({
    url: endpoints.getPerformenceCalenderWithId + `${data}`,
    method: 'get',
    headers: getHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const getTaskCalender = async () => {
  const callResponse = await axios({
    url: endpoints.getTaskCalender,
    method: 'get',
    headers: getHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const getReopenReporttWithUserId = async (data) => {
  const callResponse = await axios({
    url: endpoints.getReopenReporttWithUserId + `${data}`,
    method: 'get',
    headers: getHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const getReopenReoprtWithTaskId = async (data) => {
  const callResponse = await axios({
    url: endpoints.getReopenReoprtWithTaskId + `${data._id}`,
    method: 'get',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const GetAllReopenTasks = async (data) => {
  const callResponse = await axios({
    url: endpoints.GetAllReopenTasks,
    method: 'get',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}



//////////////////////////////////


export const createTaskApi = async (data) => {  
  const callResponse = await axios({
    url: endpoints.taskBase,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const fetchTasksApi = async (params) => {  
  const callResponse = await axios({
    url: endpoints.taskBase,
    method: 'get',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const fetchTasksPerformanceApi = async (params) => {  
  const callResponse = await axios({
    url: endpoints.taskPerformance,
    method: 'get',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const fetchTasksCalendarApi = async (params) => {  
  const callResponse = await axios({
    url: endpoints.taskCalendar,
    method: 'get',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const fetchTaskByIdApi = async (params) => {  
  const callResponse = await axios({
    url: endpoints.taskById,
    method: 'get',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const updateTasksPriorityApi = async (params) => {  
  const callResponse = await axios({
    url: endpoints.taskPriority,
    method: 'patch',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const updateTasksStatusApi = async (params) => {  
  const callResponse = await axios({
    url: endpoints.taskSttaus,
    method: 'patch',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
