import { Visibility } from "@mui/icons-material"
import { Autocomplete, Button, ButtonGroup, Chip, CircularProgress, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import moment from "moment"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { center } from "../../../assets/css/theme/common"
import CustomMonthPicker from "../../../components/layouts/common/CustomMonthPicker"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import { LEAVE_RESPONSE, USER_ROLES } from "../../../utils/constants"
import { findObjectKeyByValue, leaveStatusColored } from "../../../utils/helper"
import CoffDetailsDialog from "./CoffDetailsDialog"
import LeaveDetailsDialog from "./LeaveDetailsDialog"
import { LEAVE_STATUS } from "../../../utils/leave.constants"
import CoffDetailsController from "./CoffDetailsController"
import LeaveDetailsController from "./LeaveDetailsController"


const LeaveRow = memo(
    ({ id, applicationDate, leaveType, startDate,coffDate, endDate, status, totalLeaves, callBack, isFromCoff, appliedBy, pendingAndGenral }) => {

        const { user } = useSelector(state => state)
        const dispatch = useDispatch()
        const [currentStatus, setCurrentStatus] = useState(status)
        const onOpenViewMore = () => {

            if (isFromCoff) {

                dispatch(
                    openModal(
                        {
                            component: <CoffDetailsController
                                leaveId={id}
                                callBack={(responseStatus) => { setCurrentStatus(responseStatus) }}
                                isFromModal={true}
                            />,
                            title: "Comp Off Application"
                        }
                    ),
                )
            } else {
                dispatch(


                    openModal(
                        {
                            component: <LeaveDetailsController
                                leaveId={id}
                                callBack={(responseStatus) => { setCurrentStatus(responseStatus) }}
                                isFromModal={true}
                            />,
                            title: "Leave Application"
                        }
                    ))
            }
        }
        const handleClose = () => {
            dispatch(closeModal())
        }
        return (
            <>
                <TableRow >
                    <TableCell>{applicationDate}</TableCell>
                    {pendingAndGenral && <TableCell>{appliedBy}</TableCell>}
                    {!isFromCoff && <TableCell >{leaveType}</TableCell>}
                    {!isFromCoff && <TableCell >{startDate == endDate ? startDate : `${startDate} - ${endDate}`}</TableCell>}
                    {isFromCoff && <TableCell>{coffDate}</TableCell>}
                    {!isFromCoff && <TableCell >{totalLeaves}</TableCell>}
                    <TableCell >{<Chip color={leaveStatusColored(currentStatus)} label={findObjectKeyByValue(currentStatus, LEAVE_STATUS)} />}</TableCell>
                    {<TableCell>
                        <Tooltip title={`view  details`}>
                            <IconButton onClick={onOpenViewMore}>
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                    </TableCell>}
                </TableRow>
            </>
        )
    }
)


const LeaveSummaryUi = ({ listLoading, filters, setFilters, list, callBack, isFromCoff, pendingAndGenral }) => {



    return (
        <>

            <Box mt={3} mb={3}>

                <TableContainer component={Box} mt={2} >
                    <Table aria-label="collapsible table">

                        <TableHead sx={{ background: "lightgray" }}>
                            <TableRow>
                                <TableCell sx={{ minWidth: "150px" }}>Application Date</TableCell>
                                {pendingAndGenral && <TableCell sx={{ minWidth: "150px" }}>Applied By</TableCell>}
                                {!isFromCoff && <TableCell >Leave Type</TableCell>}
                                <TableCell sx={{ minWidth: "150px" }}>{!isFromCoff && 'Leave'} Date</TableCell>
                                {!isFromCoff && <TableCell sx={{ minWidth: "150px" }}>Total {!isFromCoff ? 'Leave' : "Days"}</TableCell>}
                                <TableCell >Status</TableCell>
                                {<TableCell  ></TableCell>}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {listLoading &&

                                <>
                                    <TableRow ><TableCell colSpan={6}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                    <TableRow ><TableCell colSpan={6}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                    <TableRow ><TableCell colSpan={6}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>

                                </>
                            }
                            {!listLoading && list && list.length <= 0 && <TableRow >
                                <TableCell colSpan={6} align="Center" ><Typography sx={{ ...center }} variant="h3" >No Data</Typography> </TableCell>
                            </TableRow>}
                            {!listLoading && list && list.map((row, index) => (
                                <LeaveRow

                                    key={row._id}
                                    id={row._id}
                                    applicationDate={moment(row.applicationDate).format("DD-MM-YYYY")}
                                    leaveType={row.leave_type ? row.leave_type.short_name :row.lwp ?"LWP":  "Multi Leaves"}
                                    startDate={moment(row.from).format("DD/MM/YYYY")}
                                    endDate={moment(row.to).format("DD/MM/YYYY")}
                                    coffDate={moment(row.date).format("DD/MM/YYYY")}
                                    status={row.status}
                                    appliedBy={pendingAndGenral && row.applied_by ? (row.applied_by.first_name + " " + row.applied_by.last_name) : ''}
                                    pendingAndGenral={pendingAndGenral}
                                    isFromCoff={isFromCoff}
                                    totalLeaves={row.leave_count}
                                    callBack={callBack}
                                />
                            ))}
                        </TableBody>



                    </Table>

                </TableContainer>

            </Box>

        </>
    )
}
export default LeaveSummaryUi