import { Outlet } from "react-router"

import { Box, Paper } from "@mui/material"
import PerformanceButtons from "./PerformanceButtons"

const PerformanceMain = () => {
    return <>

    
        <PerformanceButtons />
        <Paper component={Box} mb={4} p={3} sx={(theme) => ({ border: "1px solid " + theme.palette.grey[500] })}>
            <Outlet />
        </Paper>
    </>
}
export default PerformanceMain