import { combineReducers } from "@reduxjs/toolkit"
import modalReducer from "./modalReducer";
import userReducer from "./userReducer";
import leaveBalanceReducer from "./leaveBalanceReducer";
import notificationReducer from "./notificationReducer";
import holidayReducer from "./holidayReducer";
import snackBarReducer from "./snackbarReducer";
import leaveTypeReducer from "./leaveTypeReducer";

const rootReducer = combineReducers({
    user: userReducer,
    modal: modalReducer,
    leaveBalance: leaveBalanceReducer,
    notifications: notificationReducer,
    holiday: holidayReducer,
    leaveType:leaveTypeReducer,
    snackBar: snackBarReducer
})
export default rootReducer;