import { useCallback, useState } from 'react'
import ReimberesementController from './ReimberesementController'
import { Box, Typography } from '@mui/material'
import { CenteredBox } from '../../components/layouts/common/boxes'
import UserSearchBar from '../../components/inputs/SearchBar'
import { BasicProfileDetailsComponent } from '../profile/BasicProfileDetailsComponent'

const EmployeeReimberesementController = () => {
  const [selectedUser, setSelectedUser] = useState(null)

  const onUserChange = useCallback((user) => {
    setSelectedUser(user)
  }, [])
  return (
    <>
      <Box mt={3} mb={3}>
        <UserSearchBar onUserChange={onUserChange} />
      </Box>
      {selectedUser && <BasicProfileDetailsComponent data={selectedUser} />}
      {selectedUser && (
        <ReimberesementController
          key={selectedUser._id}
          userId={selectedUser._id}
        />
      )}
      {!selectedUser && (
        <CenteredBox mt={4}>
          <Typography variant="h3" color="grey.main">
            No Employee Selected
          </Typography>
        </CenteredBox>
      )}
    </>
  )
}
export default EmployeeReimberesementController
