import { DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch } from "react-redux"
import SubmitButton from "../../components/button/SubmitButton"
import { closeModal } from "../../store/actions/modalAction"
import ProfileController from "./ProfileController"

const ProfileDialog = (props) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }} pt={2} maxHeight="100%"  >
                <DialogTitle variant="h3">
                    {'Employee Profile'}
                </DialogTitle>
                <DialogContent>
                   <Box bgcolor={"grey.300"} px={3} py={2}>

                   <ProfileController {...props} />
                   </Box>
                </DialogContent>
                <DialogActions   >
                    <SubmitButton  onClick={handleClose} title={'Close'}></SubmitButton>

                </DialogActions>
            </Box>

        </>
    )
}
export default ProfileDialog