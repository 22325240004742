import { Box, CircularProgress, Divider, Grid, Typography, styled } from "@mui/material"
import { CenteredBox } from "../../../components/layouts/common/boxes"
import moment from "moment"
import PriorityButton from "./PriorityButton"
import { TASK_STATUS } from "../../../utils/task.constants"
import StatusButton from "./StatusButton"
const HtmlViewer = styled(Box)(({ theme }) => ({
    width: "100%",
    overflowX: "scroll",
    background: theme.palette.grey[200],
    width: "100%",
    borderRadius: 1,
    "*": {
        "ul": {
            paddingLeft: theme.spacing(4)
        },
        "ol": {
            paddingLeft: theme.spacing(4)
        },
        "b": {
            fontWeight: "bold"
        },
        "a": {
            color: "blue",
            cursor: "pointer"
        },

        "td": {
            border: "1px solid black",
            minWidth: "100px"
        }
    }

}))
const TaskDetailsUi = ({ loading, list, id, selectedUser }) => {
    return <Box>
        {loading && <CenteredBox mt={4} mb={4}><CircularProgress /></CenteredBox>}
        {
            !loading && list && Object.keys(list).length > 0 && <Box>
                <Box mb={4}>


                    <Typography variant="h4" fontWeight="bold">{list.task_id}</Typography>

                </Box>
                <Box mb={4}>
                    <Grid container spacing={3}>
                        <Grid item md={4} xs={6}>
                            <Typography mb={2} fontWeight={"bold"} variant="h5">Title</Typography>
                            <Typography variant="h6">{list.name}</Typography>
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <Typography mb={2} fontWeight={"bold"} variant="h5">Start Date</Typography>
                            <Typography variant="h6">{moment(list.start_date).format("DD MMM YYYY")}</Typography>
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <Typography mb={2} fontWeight={"bold"} variant="h5">Due Date</Typography>
                            <Typography variant="h6">{moment(list.due_date).format("DD MMM YYYY")}</Typography>
                        </Grid>
                    </Grid>

                </Box>
                <Box mt={4}>
                    <Typography mb={2} fontWeight={"bold"} variant="h5">Description</Typography>
                    <HtmlViewer p={2} dangerouslySetInnerHTML={{ __html: list.description }}  >

                    </HtmlViewer>
                </Box>


                <Box mb={4} mt={4}>
                    <Grid container spacing={3}>
                        <Grid item md={4} xs={6}>
                            <Typography mb={2} fontWeight={"bold"} variant="h5">Priority</Typography>
                            <Typography variant="h6"><PriorityButton status={list.status} assignedTo={list.assigned_to ? [list.assigned_to].map((assignedUser) => assignedUser._id) : []} selectedUser={selectedUser} priority={list.priority} id={id} /></Typography>
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <Typography mb={2} fontWeight={"bold"} variant="h5">Status</Typography>
                            <Typography variant="h6">
                                <StatusButton
                                assignedBy={list.assigned_by}
                                minDate={moment(list.due_date)}
                                assignedTo={list.assigned_to}
                                selectedUser={selectedUser}
                                status={list.status} id={id} /></Typography>
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <Typography mb={2} fontWeight={"bold"} variant="h5">Is Reopend</Typography>
                            <Typography variant="h6">{(list.parent_task && list.parent_task.length > 0) || (list.child_task && list.child_task.length > 0) ? "YES" : "NO"}</Typography>
                        </Grid>
                    </Grid>

                </Box>


                <Box mb={4} mt={4}>
                    <Grid container spacing={3}>
                        <Grid item md={4} xs={6}>
                            <Typography mb={2} fontWeight={"bold"} variant="h5">Assigned By</Typography>
                            <Typography variant="h6">{list.assigned_by_name}</Typography>
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <Typography mb={2} fontWeight={"bold"} variant="h5">Assigned To</Typography>
                            <Typography variant="h6">{list.assigned_to && (list.assigned_to.first_name + " " + list.assigned_to.last_name)}</Typography>
                        </Grid>

                    </Grid>

                </Box>


                {(list.status == TASK_STATUS.COMPLETED || list.status == TASK_STATUS.REJECTED) && <Box mb={4} mt={4}>
                    <Grid container spacing={3}>
                        <Grid item md={4} xs={6}>
                            <Typography mb={2} fontWeight={"bold"} variant="h5">Actioned By:</Typography>
                            <Typography variant="h6">{list.actioned_by && (list.actioned_by.first_name + " " + list.actioned_by.last_name)}</Typography>
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <Typography mb={2} fontWeight={"bold"} variant="h5">Action Remarks:</Typography>
                            <Typography variant="h6">{list.actioned_remarks}</Typography>
                        </Grid>

                    </Grid>

                </Box>}

                {(list.status == TASK_STATUS.COMPLETED) && <Box mb={4} mt={4}>
                    <Grid container spacing={3}>
                        <Grid item md={4} xs={6}>
                            <Typography mb={2} fontWeight={"bold"} variant="h5">Completed At:</Typography>
                            <Typography variant="h6">{moment(list.completed_date).format("DD MMM YYYY")}</Typography>
                        </Grid>
                    </Grid>

                </Box>}
            </Box>
        }

        {!loading && (!list || Object.keys(list).length == 0) && <CenteredBox mt={4} mb={4}><Typography variant="h3" >No task Found</Typography></CenteredBox>}
    </Box>
}
export default TaskDetailsUi