import {
  BarChart,
  CalendarMonthOutlined,
  Home,
  People,
  PriceChangeOutlined,
} from '@mui/icons-material'
import { Box, Paper } from '@mui/material'
import { Navigate, Outlet } from 'react-router-dom'
import AppContainer from './components/layouts/common/AppContainer'
import EmployyeLeavesController from './pages/leaves/EmployyeLeavesController'
import CoffDetailsController from './pages/leaves/leaveSummary/CoffDetailsController'
import LeaveDetailsController from './pages/leaves/leaveSummary/LeaveDetailsController'
import MyLeaveController from './pages/leaves/MyLeaveController'
import ProfileController from './pages/profile/ProfileController'
import EmployeeReimberesementController from './pages/reimberesement/EmployeeReimberesementController'
import ReimberesementController from './pages/reimberesement/ReimberesementController'
import ReimberesementDetailsController from './pages/reimberesement/ReimberesementDetailsController'
import SignInController from './pages/signin/SignInController'
import UserController from './pages/user/UserController'
import { USER_ROLES } from './utils/constants'
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import ArticleIcon from '@mui/icons-material/Article'
import LeaveRaiseController from './pages/leaves/leaveRaise/LeaveRaiseController'
import CoffRaiseController from './pages/leaves/leaveRaise/CoffRaiseController'
import SummaryMainDialog from './pages/leaves/leaveSummary/SummaryMainDialog'
import ReimberesementCreateController from './pages/reimberesement/ReimberesementCreateController'
import SettingsIcon from '@mui/icons-material/Settings'
import HolidayController from './pages/Holiday/HolidayController'
import BranchController from './pages/branch/BranchController'
import DepartmentController from './pages/department/DepartmentController'
import EmployeeTypeController from './pages/employeetype/Type/EmployeeTypeController'
import GenerateSalarySlip from './pages/payrollSummary/GenerateSalarySlipUi'

import SalarySlipUI from './pages/payrollSummary/SalarySlipUi'

import DailyupdateController from './pages/tasks/DailyUpdate/DailyupdateController'

import LeaveTypeController from './pages/leaveTypes/LeaveTypeController'
import TaskMain from './pages/tasks/TaskMain'
import UserCreateController from './pages/user/UserCreateController'
import EmployeeMain from './pages/tasks/EmployeeMain'
import TaskCreateController from './pages/tasks/TaskTimeline/TaskCreateController'
import TaskListController from './pages/tasks/TaskTimeline/TaskListController'
// import TaskCalendarController from './pages/tasks/TaskTimeline/TaskCalendarController'
import TaskDetailsController from './pages/tasks/TaskTimeline/TaskDetails.controller'
import TaskCalendarController from './pages/tasks/TaskTimeline/calendar/TaskCalendarController'
import PerformanceListController from './pages/tasks/Performance/PerformanceListController'
import PerformanceMain from './pages/tasks/Performance/PerformanceMain'
import PerformanceCalendar from './pages/tasks/Performance/PerformanceCalendar'
import PendingActionTaskController from './pages/tasks/TaskTimeline/PendingActionTaskController'
import PendingMainContainer from './pages/leaves/leaveSummary/PendingMainContainer'
import PendingReimberesementController from './pages/reimberesement/PendingReimberesementController'

const defineRoutes = (user) => {
  return [
    {
      path: 'sign-in',
      element: !user.isLoggedIn ? (
        <SignInController />
      ) : (
        <Navigate replace to="/tasks/my-tasks/" />
      ),
      hideInPannel: true,
    },

    {
      path: 'profile',
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),

      children: [
        {
          path: '',
          title: 'Profile',
          element: <ProfileController />,
        },
      ],
      hideInPannel: true,
    },


    {
      path: 'tasks',
      icon: <PieChartOutlineIcon />,
      title: 'Task Management',
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      children: [

        {
          path: 'detail/:id',
          title: 'Task Details',
          hideInPannel: "true",
          element: <Paper component={Box} p={3} mt={3}>
            <TaskDetailsController />
          </Paper>
        },
        {
          path: 'create-task',
          title: 'Create Task',
          element: (
            <Paper component={Box} p={3} mt={3}>
              <TaskCreateController />
            </Paper>

          ),
        },
        {
          path: 'my-tasks',
          title: 'My Tasks',
          element: <TaskMain />,
          children: [
            {
              path: '',
              title: 'My Tasks',
              element: (

                <TaskCalendarController />

              ),
            },
            {
              path: 'calendar',
              title: 'My Tasks',
              element: (

                <TaskCalendarController />

              ),
            },
            {
              path: 'task-timeline',
              title: 'task-timeline',
              element: (

                <TaskListController />

              ),
              hideInPannel: true,
            },

            {
              path: 'daily-update',
              title: 'daily-update',
              element: (

                <DailyupdateController />

              ),
              hideInPannel: true,
            },
          ],
        },

        {
          path: 'pending',
          title: 'Pending Approvals',


          element: <PendingActionTaskController />,
          hideInPannel: false,

        },
        {
          path: 'employee-tasks',
          title: 'Employee Tasks',
          element: <EmployeeMain />,
          hideInPannel: false,
          children: [
            {
              path: '',
              title: 'My Tasks',
              element: (

                <TaskCalendarController />

              ),
            },
            {
              path: 'calendar',
              title: 'My Tasks',
              element: (

                <TaskCalendarController />

              ),
            },
            {
              path: 'task-timeline',
              title: 'task-timeline',
              element: (

                <TaskListController />

              ),
              hideInPannel: true,
            },
            {
              path: 'daily-update',
              title: 'daily-update',
              element: (

                <DailyupdateController />

              ),
              hideInPannel: true,
            },
          ],
        },
        {
          path: "",
          hideInPannel: true,
          element: <Navigate to={"/tasks/my-tasks/"} />
        }
      ],
    },
    {
      path: 'performance',
      icon: <BarChart />,
      title: 'Performance ',
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      children: [
        {
          path: 'my-performance',
          title: 'My Performance',
          element: <PerformanceMain />,
          children: [
            {
              path: '',
              title: 'My Performance',
              element: <PerformanceListController />,
            },
            {
              path: 'stats',
              title: 'My Performance',
              element: <PerformanceListController />,
            },
            {
              path: 'calendar',
              title: 'My Performance',
              element: <PerformanceCalendar />,
            }
          ]
        },

        {
          path: 'employyee',
          title: 'Employee Performance',

          element: <EmployeeMain forPerformance={true} />,
          hideInPannel: false,
          children: [
            {
              path: '',
              title: 'My Performance',
              element: <PerformanceListController />,
            },
            {
              path: 'stats',
              title: 'My Performance',
              element: <PerformanceListController />,
            },
            {
              path: 'calendar',
              title: 'My Performance',
              element: <PerformanceCalendar />,
            }
          ]
        },
        {
          path: "",
          hideInPannel: true,
          element: <Navigate to={"/performance/my-performance/"} />
        }


      ],
    },




    {
      path: 'calendar',
      icon: <EventAvailableIcon />,
      title: 'Leave Calendar',
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      children: [
        {
          path: '',
          title: 'My Calendar',
          children: [
            {
              path: '',
              title: 'My Calendar',
              element: <MyLeaveController />,
            },

            {
              path: 'leave/id/:id',
              title: 'My Calendar',
              element: <Paper sx={{ mt: 3 }}>
                <LeaveDetailsController />
              </Paper>,
            },
            {
              path: 'c-off/id/:id',
              title: 'My Calendar',
              element: <Paper sx={{ mt: 3 }}>
                <CoffDetailsController />
              </Paper>,
            },
            {
              path: 'rais-leave',
              title: 'Leave Raise',
              element: (
                <Paper sx={{ mt: 3 }}>
                  <LeaveRaiseController />
                </Paper>
              ),
              hideInPannel: true,
            },
            {
              path: 'comp-off',
              title: 'Comp Off',
              element: (
                <Paper sx={{ mt: 3 }}>
                  <CoffRaiseController />
                </Paper>
              ),
              hideInPannel: true,
            },
            {
              path: 'summary',
              title: 'Summary',
              element: (
                <Paper sx={{ mt: 3 }}>
                  <SummaryMainDialog />
                </Paper>
              ),
              hideInPannel: true,
            }

          ],
        },
        {
          path: 'pending',
          title: 'Pending Actions',
          element: (
            <Paper sx={{ mt: 3 }}>
              <PendingMainContainer />
            </Paper>
          )
        },
        {
          path: 'employee',
          title: 'Employee Calendar',
          element: <EmployyeLeavesController />,
        },

      ],
    },

    {
      path: 'reimburesement',
      icon: <PriceChangeOutlined />,
      title: 'Reimburesement',
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      // hideInPannel: user.data.role === USER_ROLES.employee,
      children: [
        {
          path: '',
          title: 'My Reimburesement',
          children: [
            {
              path: '',
              title: 'My Reimburesement',
              element: <ReimberesementController />,
            },
            {
              path: 'apply-reimbursement',
              title: 'Apply Reimbursement',
              element: (
                <Paper sx={{ mt: 3 }}>
                  <ReimberesementCreateController />
                </Paper>
              ),
              hideInPannel: true,
            },
          ],
        },
        {
          path: 'pending',
          title: 'Pending Actions',
          element: <PendingReimberesementController />
        },
        {
          path: 'employee',
          title: 'Employee Reimburesement',
          element: <EmployeeReimberesementController />
        },
        {
          path: 'get/:id',
          title: '',
          element: (
            <Paper sx={{ mt: 3 }}>
              {' '}
              <ReimberesementDetailsController />{' '}
            </Paper>
          ),
          hideInPannel: true,
        },
      ],
    },

    {
      path: 'salary',
      icon: <ArticleIcon />,
      title: 'Payroll Summary',
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      children: [
        {
          path: '',
          title: 'My Salary Slip',
          element: (
            <Paper sx={{ mt: 3 }}>
              {' '}
              <SalarySlipUI />{' '}
            </Paper>
          ),
        },
        {
          path: 'generatesalary',
          title: 'Generate Salary Slip',
          element: (
            <Paper sx={{ mt: 3 }}>
              {' '}
              <GenerateSalarySlip />{' '}
            </Paper>
          ),
          hideInPannel: !(user.data.role == USER_ROLES.hr || (user.data.role == USER_ROLES.admin)),
        },
      ],
    },
    {
      path: 'users',
      icon: <People />,
      title: 'Users',
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      children: [
        {
          path: '',
          title: 'User List',
          element: <UserController />,
        },
        {
          path: 'create',
          title: 'Create User',
          hideInPannel: !(user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.hr),
          element: <Paper sx={{ mt: 3 }}><UserCreateController /></Paper>,
        },
      ],
    },
    {
      path: 'settings',
      icon: <SettingsIcon />,
      title: 'Settings',
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      hideInPannel: !(user.data.role === USER_ROLES.hr ||
        user.data.role === USER_ROLES.admin),
      children: [
        {
          path: 'holiday',
          title: 'Holiday',
          element: (
            <Paper sx={{ mt: 3 }}>
              <HolidayController />
            </Paper>
          ),
        },
        {
          path: 'branch',
          title: 'Branch',
          element: (
            <Paper sx={{ mt: 3 }}>
              <BranchController />
            </Paper>
          ),
        },
        {
          path: 'department',
          title: 'Department',
          element: (
            <Paper sx={{ mt: 3 }}>
              <DepartmentController />
            </Paper>
          ),
        },
        {
          path: 'employee-type',
          title: 'Employee Type',
          element: (
            <Paper sx={{ mt: 3 }}>
              <EmployeeTypeController />
            </Paper>
          ),
        },
        {
          path: 'leave-type',
          title: 'Leave Types',
          element: (
            <Paper sx={{ mt: 3 }}>
              <LeaveTypeController />
            </Paper>
          ),
        },
      ],
    },


    {
      path: '*',
      element: !user.isLoggedIn ? (
        <Navigate replace to={'/sign-in'} />
      ) : (
        <Navigate replace to="/tasks/my-tasks/" />
      ),
      hideInPannel: true,
    },
  ]
}
export default defineRoutes

/* 

================================================================
Demo Object
================================================================
        {
            path: "sign-in",
            element: !user.isLoggedIn ? <SignInController /> : <Navigate replace to="/leaves" />,
            hideInPannel:true, //for showing it onSide pannel or not
            title: "Leave Management", // it will use as name in side navbar
            component:<></>


             children: [
                {
                    path: "",
                    title: "My Leaves",// it will use as name in side navbar
                    element: <MyLeaveController />,
                    hideInPannel:true,
                }
            ],
        },




================================================================
================================================================


*/
