import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Modal, Paper, Skeleton, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import { setZeroPrefix } from '../../utils/helper'
import GrayBgBox from './GrayBgBox'
import { center } from '../../assets/css/theme/common'
import moment from 'moment'

const DayTitleComponent = styled(Box)(({ theme, isFirst, isLast, isMobile }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  borderTopLeftRadius: isFirst ? theme.shape.borderRadius : 'none',
  borderTopRightRadius: isLast ? theme.shape.borderRadius : 'none',
  background: '#F9F9F9',
  border: isMobile ? "none" : `1px solid ${theme.palette.grey[600]}`,


}))
const CalendarGridBox = styled(Grid)(({ theme, isFirst, isLast, isMobile }) => ({
  border: isMobile ? "none" : '1px solid #BBBBBB',
}))

const MobileCalendarBox = ({ weekDay, date, dateObj, data, Component }) => {
  const [open, setOpen] = useState(false)
  const handleEvent = () => {
    setOpen(!open)
  }
  return <>
    <Dialog
      open={open}
      onClose={handleEvent}
      fullWidth
    >
      <DialogTitle variant='h3'>{date}  {moment(dateObj.getTime()).format("MMM YYYY")}</DialogTitle>
      <DialogContent>
        <Box sx={{width:"100%",position:"relative"}}>
        <Component weekDay={weekDay} date={date} data={data} dateObj={dateObj} />
        </Box>
        
      </DialogContent>
      {/* <Paper component={Box} sx={{}}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
{date}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>

        </Typography>
      </Paper> */}

    </Dialog>
    <Box sx={{ ...center }} >
      <IconButton onClick={handleEvent}><Typography variant='h3' color="primary">{date}</Typography></IconButton>
    </Box>
  </>
}
function Calendar({ data, calendarTimeObj, dayComponent, loading }) {
  const [date, setDate] = useState(new Date())
  const [calendarArr, setCalendarArr] = useState([
    [null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null],
  ])

  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate()
  }

  const renderCalendar = () => {
    const month = date.getMonth()
    const year = date.getFullYear()
    const daysInMonth = getDaysInMonth(year, month)

    let calendar = [
      [null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null],
    ]

    let i = 0
    let j = new Date(year, month).getDay()
    let day = 1

    let dataIndex = 0
    while (i < 6 && day <= daysInMonth) {
      while (j < 7 && day <= daysInMonth) {
        calendar[i][j] = {
          date: setZeroPrefix(day),
          propsData: [],
        }

        while (dataIndex < data.length) {
          if (data[dataIndex] && data[dataIndex].start && data[dataIndex].end) {
            const dataStartDate = new Date(data[dataIndex].start)
            const dataEndDate = new Date(data[dataIndex].end)

            if (
              day >= dataStartDate.getDate() &&
              day <= dataEndDate.getDate() &&
              dataStartDate.getMonth() == month &&
              dataStartDate.getFullYear() == year
            ) {
              const isStarting = day == dataStartDate.getDate()
              const isEnding = day == dataEndDate.getDate()

              calendar[i][j].propsData.push({
                ...data[dataIndex],
                isStarting,
                isEnding,
              })
            }
          }
          dataIndex++
        }
        dataIndex = 0
        day++
        j++
      }
      j = 0
      i++
    }

    setCalendarArr(calendar)
  }

  useEffect(() => {
    if (calendarTimeObj) setDate(calendarTimeObj)
  }, [calendarTimeObj])

  useEffect(() => {
    renderCalendar()
  }, [data, date])

  const Component = dayComponent
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container sx={{ background: 'none' }}>
      {days.map((day, index) => (
        <Grid item xs={1.7} key={day}>
          <DayTitleComponent
            isMobile={isMobile}
            isFirst={index == 0}
            isLast={index == days.length - 1}

          >
            {!isMobile && <Typography variant="h4" color="primary.calender">
              {day}
            </Typography>}

            {isMobile && <Typography fontWeight="600" variant="h4" color="primary.calender">
              {day.slice(0, 2)}
            </Typography>}
          </DayTitleComponent>
        </Grid>
      ))}

      {!loading &&
        calendarArr &&
        calendarArr.map((val) => (
          <React.Fragment key={(val[0] && val[0].date) || Math.random()}>
            {val &&
              (val[0] || val[6]) &&
              val.map((tmp, index) =>
                tmp ? (
                  <CalendarGridBox isMobile={isMobile} item xs={1.7} key={tmp.date}>

                    {
                      isMobile && <MobileCalendarBox Component={Component} weekDay={index} dateObj={calendarTimeObj} date={tmp.date} data={tmp.propsData} />
                    }
                    {!isMobile && <Box
                      sx={{
                        minHeight: '120px',
                        height: '100%',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        background:
                          'white',
                      }}
                    >
                      <Typography
                        p={1}
                        zIndex={1}
                        color="grey.main"
                        variant="h3"
                        align="right"
                      >
                        {tmp.date}
                      </Typography>
                      <Component weekDay={index} date={tmp.date} data={tmp.propsData}  />
                    </Box>}
                  </CalendarGridBox>
                ) : (
                  <CalendarGridBox isMobile={isMobile} item xs={1.7}></CalendarGridBox>
                ),
              )}
          </React.Fragment>
        ))}

      {loading &&
        calendarArr &&
        calendarArr.map((val) => (
          <React.Fragment key={(val[0] && val[0].date) || Math.random()}>
            {val &&
              (val[0] || val[6]) &&
              val.map((tmp, index) =>
                tmp ? (
                  <CalendarGridBox isMobile={isMobile} item xs={1.7} key={index + Math.random()}>
                    <Box
                      sx={{
                        minHeight: '120px',
                        width: '100%',
                        display: 'flex',
                      }}
                    >
                      <Skeleton
                        animation="wave"
                        height={'inherit'}
                        width="100%"
                      />
                    </Box>
                  </CalendarGridBox>
                ) : (
                  <CalendarGridBox isMobile={isMobile} item xs={1.7}></CalendarGridBox>
                ),
              )}
          </React.Fragment>
        ))}
    </Grid>
  )
}

export default memo(Calendar)
