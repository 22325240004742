import { memo, useContext, useEffect, useMemo, useState } from 'react'
import useValidate from '../../../store/hooks/useValidator'

import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../store/actions/commonAction'

import { closeModal, openModal } from '../../../store/actions/modalAction'

import DailyUpdateUi from './DailyUpdateUi'
import { raiseLeaveApi } from '../../../apis/leave.api'
import moment from 'moment'
import { HALF_TYPE, HALF_TYPE_OBJ, SNACK_BAR_VARIETNS } from '../../../utils/constants'
import { getDailyUpdateOnDate } from '../../../apis/dailyupdate.api'
import { EmployeeTaskUserContext } from '../EmployeeMain'
import { callSnackBar } from '../../../store/actions/snackbarAction'
import DailyUpdateEditor from './DailyUpdateEditor'

const DailyupdateController = ({ userId, callBack = () => { } }) => {

  const validate = useValidate()
  const dispatch = useDispatch()


  const [loading, setLoading] = useState(false)
  const selectedUser = useContext(EmployeeTaskUserContext)
  const [filters, setFilters] = useState({
    err: '',
    pageNo: 1,
    pageSize: 25,
    date: moment(),
  })
  const [list, setList] = useState([])



  const fetchList = async (e) => {
    setLoading(true)
    dispatch(
      callApiAction(
        async () => await getDailyUpdateOnDate({ ...filters,date:filters.date.valueOf(), userId: selectedUser ? selectedUser._id : null }),
        async (response) => {
          setLoading(false)
          setList(response)
        },
        (err) => {
          setLoading(false)
          setList({})
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
        },
      ),
    )

  }

  const onCreateButtonClick = () => {
    dispatch(openModal({ size: "md", title: "Add Update", component: <DailyUpdateEditor callBack={fetchList} date={filters.date.valueOf()} /> }))
  }

  useEffect(() => {
    fetchList()
  }, [filters, selectedUser])

  return (
    <DailyUpdateUi
      list={list}
      onCreateButtonClick={onCreateButtonClick}
      loading={loading}
      filters={filters}
      setFilters={setFilters}
    />
  )
}
export default memo(DailyupdateController)
