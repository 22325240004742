import React, { useEffect, useState } from "react";
import { createHoliday } from "../../apis/holiday.api";
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction";
import { fetchDepartments, createDepartment,updateDepartment } from "../../apis/department";
import UpdateDepartmentUI from "./UpdateDepartmentUI";
import { closeModal } from "../../store/actions/modalAction";

const UpdateDepartmentController = ({id,initialName,parents,callBack}) => {
    
    let intialParentID = ""
    let intialParentName = ""
    if (parents.length) {
        intialParentID = parents._id
        intialParentName = parents.name
    }
    const dispatch = useDispatch()
    const updateDepartmentApi = updateDepartment
    const [loading, setLoading] = useState(false)
    const [parentId,setParentID] = useState(intialParentID);
    const [parentName,setParentName] = useState(intialParentName);
    const [name,setName] = useState(initialName)
    const [err,setErr] = useState("")
    const [string,setString] = useState("")
    const [departments,setDepartmens] = useState("")
    const fetchDepartmentsApi = fetchDepartments

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchDepartmentsApi({string}),
            (response) => {
                setDepartmens(response.result)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const UpdateDepartment = (e) => {
        e.preventDefault()
        setLoading(true)
        dispatch(callApiAction(
            async () => await updateDepartmentApi({id,parentId,name}),
            (response) => {
                dispatch(closeModal())
                callBack()
                setLoading(false)
            },
            (err) => {
                setLoading(false)
                setErr(err)
            }
        ))
    }
    useEffect(()=>{
        fetchList()
    },[string])
    return <UpdateDepartmentUI loading={loading} UpdateDepartment={UpdateDepartment} parentName={parentName} setParentName={setParentName} setName={setName} name={name} departments={departments}  setString={setString} setParentID={setParentID}/>
}

export default UpdateDepartmentController;