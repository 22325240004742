import { Box, Collapse, IconButton, useTheme, Paper, useMediaQuery, Button, Typography } from "@mui/material"
import { useSelector, useDispatch } from 'react-redux'
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom"



import { useEffect, useState } from "react"
import { memo } from "react"
import AppModeLabel from "../../texts/AppModeLabel"
import { center } from "../../../assets/css/theme/common"
import PopUpModal from "../../Modal"
import { Logo } from "./Logo"
import Header from "./Header"
import Navbar from "./Navbar"

import responsive from "../../../assets/css/responsive"
import MobileNav from "./MobileNav"
import colorTheme from "../../../assets/css/theme/colorTheme"
import { fetchHolidayListAction } from "../../../store/actions/holidayListAction"
import { fetchLeaveTypeListAction } from "../../../store/actions/leaveTypeListAction"





const containerStyle = (theme) => ({ height: "100%", width: "100%", display: "flex", background: "#FFFFFF", ...responsive.containerStyle })
const mobileContainerStyle = (theme) => ({ height: "100%", width: "100%", display: "flex", background: "#FFFFFF", ...responsive.mobileContainerStyle })
const navBarOuterStyle = (theme) => ({ height: "calc(100vh-100px)", position: "relative" })

const navbarInnerStyle = (theme) => ({ width: "260px", background: "white", height: "100%", overflowY: "auto", position: "relative" })

const logoStyle = (theme) => ({ position: "sticky", top: "0px", background: "inherit", borderBottom: "1px solid " + theme.palette.grey.light, height: "88px", ...center, zIndex: 11 })
const boxStyle = (theme) => ({
  width: "100%",
  maxHeight: "100vh",
  background: theme.palette.grey['400'],
  overflow: "scroll"
})
let activePath = "";

const AppContainer = () => {
  const dispatch = useDispatch()
  const [navBar, setNavbar] = useState(true)

  const location = useLocation()
  let splittedPath = [...location.pathname.split('/').slice(1)]

  const params = useParams()
  if(params.id){
    splittedPath[splittedPath.length-1] = ""
  }

  const ActivePathComponent = (activeComponentProps) => {
    let path = '/'
    return <Box sx={{display:"flex"}} {...activeComponentProps} >
      {splittedPath.map((item) => { path += item + "/ ";return <Link to={item} key={item} ><Typography variant="h6" color="text.primary">{item ? (item + '/') : ""}</Typography> </Link> })}
    </Box>
  }

  const ActivePath = {
    textTransform: "capitalize",
    margin: "24px 0 0 24px",
    color: colorTheme.palette.text[300],
    cursor: "pointer"
  }
  const navigate = useNavigate();
  const user = useSelector(state => state.user)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    dispatch(fetchHolidayListAction())
    dispatch(fetchLeaveTypeListAction())
  }, [])

  return (
    <>
      {
        process.env.REACT_APP_APP_MODE != 'production' && <AppModeLabel />
      }

      <PopUpModal />
      {!isMobile && <Box sx={containerStyle}>
        <Box elevation={2} sx={navBarOuterStyle}>
          <Paper elevation={2} sx={navbarInnerStyle}>
            <Box sx={logoStyle} mb={4}>
              <Logo sx={{ zIndex: 1 }} />
            </Box>
            <Box sx={{ position: "relative", zIndex: 1 }}>
              <Navbar />
            </Box>



          </Paper>
        </Box>


        <Box sx={boxStyle}>
          <Header />
         
          <Box sx={ActivePath} component={ActivePathComponent} ></Box>
          <Box pb={3} pr={4} pl={4} sx={{ display: "flex", flex: 1, flexDirection: "column", overflowY: "scroll" }}>
            <Outlet />
          </Box>

        </Box>
      </Box >}

      {isMobile && <Box sx={mobileContainerStyle}>
        <Box sx={boxStyle}>
          <MobileNav />
          <Header />
          <Box pb={3} pr={4} pl={4} sx={{ display: "flex", flex: 1, flexDirection: "column", overflowY: "scroll" }}>
            <Outlet />
          </Box>
        </Box>
      </Box>}



    </>
  )
}
export default memo(AppContainer)