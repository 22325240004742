import CustomInput from '../../components/inputs/CustomInput';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import OneViewBox from '../../components/layouts/OneViewBox';
import BgGradient from '../../components/layouts/auth/BgGradient';
import CustomContainer from '../../components/layouts/CustomContainer';
import AuthLeftContainer from '../../components/layouts/auth/AuthLeftContainer';
import AuthRighttContainer from '../../components/layouts/auth/AuthRightContainer';
import SubmitButton from '../../components/button/SubmitButton';
import GradientText from '../../components/texts/GradientText';
import { center } from '../../assets/css/theme/common';
import FullScreenContainer from '../../components/layouts/FullScreenCaontainer';
import Logo from '../../assets/images/Vector.png'
import responsive from '../../assets/css/responsive';


const SignInUI = ({ state, setState, onSubmit, loading }) => {
    const outerBox = {
        ...center,
        flexDirection: "column",
        background: "#FFFFFF",
        ...responsive.box,
    }

    const box = {
        ...center,
        flexDirection: "column",
        ...responsive.input
    }

    const heading = {
        fontFamily: 'Public Sans',
        fontWeight: 600,
        fontSize: "48px",
        lineHeight: "60px",
        color: "#393939",
        ...responsive.heading
    }

    const para = {
        fontFamily: 'Public Sans',
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "23px",
        textAlign: center,
        color: "#393939",
        ...responsive.para
    }

    const avatarBG = {
        width: 69,
        height: 72,
        background: "linear-gradient(270deg, #FCE9F6 -0.06%, #FEE6EF 57.39%, #FDECE8 97.56%)",
        objectFit: "fill !important",
        ...responsive.avatarBG
    }
    return (
        <>
            <FullScreenContainer>
                <Grid container sx={{ display: "flex" }}>
                    <Grid item xs={12} md={4}>
                        <AuthLeftContainer />
                    </Grid>
                    <Grid item xs={12} md={8} sx={outerBox}>
                        <Box component={"form"} sx={box} onSubmit={onSubmit}>
                            <Avatar
                                imgProps={{ sx: { objectFit: "none" } }}
                                alt="Remy Sharp"
                                src={Logo}
                                sx={avatarBG}
                            />
                            <Box mb={"36px"} mt={"12px"}>
                                <Typography variant='display1' sx={heading}>
                                    Welcome back!
                                </Typography>
                                <Typography variant='h4' sx={para} mt={"12px"}>
                                    Please enter your details.
                                </Typography>
                            </Box>
                            <Typography variant="h4" color={"red"} mb={1}>
                                {state.err}
                            </Typography>
                            <Box>
                                <CustomInput
                                    disabled={loading}
                                    value={state.email}
                                    onChange={(e) => setState({ ...state, err: '', email: e.target.value })}
                                    type="text"
                                    label={"Email"}
                                />
                                <Box sx={box} mt={"12px"}>
                                    <CustomInput
                                        disabled={loading}
                                        value={state.password}
                                        onChange={(e) => setState({ ...state, err: '', password: e.target.value })}
                                        type="password"
                                        label={"Password"}
                                    />
                                </Box>
                                <Box mt={"20px"}>
                                    <SubmitButton variant="contained" loading={loading} disabled={loading} type='submit' title='Log In' />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </FullScreenContainer>

        </>
    )
}
export default SignInUI