import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";



export const getReimberesementApi = async params => {
    const callResponse = await axios({
        url: endpoints.reimberesementBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));

    return callResponse;
};
export const getPendingReimberesementApi = async params => {
    const callResponse = await axios({
        url: endpoints.pendingReimberesementBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));

    return callResponse;
};
export const getReimberesementCountApi = async params => {
    const callResponse = await axios({
        url: endpoints.reimberesementCount,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));

    return callResponse;
};

export const getReimberesementByIdApi = async (id) => {
    const callResponse = await axios({
        url: endpoints.reimberesementById + id,
        method: "get",
        headers: getHeaders(),

    })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));

    return callResponse;
};


export const addReimberesementApi = async data => {
    const callResponse = await axios({
        url: endpoints.reimberesementBase,
        method: "POST",
        headers: getHeaders(),

        data,
    })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));

    return callResponse;
};

export const updateReimberesementField = async data => {
    const callResponse = await axios({
        url: endpoints.reimberesementBase,
        method: "PATCH",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));

    return callResponse;
};




