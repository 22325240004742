import { CampaignOutlined, EventAvailable, EventAvailableOutlined, History, HourglassEmpty } from '@mui/icons-material'
import { Box, Button, ButtonBase, styled } from '@mui/material'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from '../../store/actions/modalAction'
import { Add } from '@mui/icons-material'
import { center } from '../../assets/css/theme/common'
import { Link, useLocation } from 'react-router-dom'

import {
  Checkbox,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slide,
  Typography,
} from '@mui/material'

const TaskButton = styled(ButtonBase)(({ theme, active, isFirst }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: "space-between",
  zIndex: 0,
  marginLeft: isFirst ? 0 : theme.spacing(3),
  textTransform: 'capitalize',
  background: active ? "linear-gradient(93.41deg, #666666 -117.39%, #000000 136.1%)" : theme.palette.light.main,
  color: !active ? theme.palette.primary.main : theme.palette.light.main,
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),


  boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.05)",
  border: '1px solid ' + theme.palette.grey[500],
  "*": {
    lineHeight: "150%",
  }

}))

const LeaveBtnBox = {
  width: '100%',

  display: 'flex',



}

const EmployeeTaskButtons = ({ userId }) => {

  const location = useLocation()
  const pathArr = location.pathname.split('/')
  const slug = pathArr[pathArr.length - 1]
  return (
    <>
      <Box sx={LeaveBtnBox} mt={5} mb={5}>

        <TaskButton
        isFirst={true}
          LinkComponent={Link}
          active={slug == '' || slug == 'calendar'}
          to="calendar"
          color="light"
          variant="contained"
          sx={{ borderRadius: '4px' }}
        >
          <Typography variant='h4'> Work Calender</Typography>&nbsp;<EventAvailable />
        </TaskButton>
        <TaskButton
          active={slug == 'daily-update'}
          color="light"
          variant="contained"
          sx={{ borderRadius: '4px' }}
          LinkComponent={Link}
          to="daily-update"
        >
          <Typography variant='h4'>Daily Update</Typography> &nbsp;<History />
        </TaskButton>

        <TaskButton
          
          active={slug == 'task-timeline'}
          color="light"
          variant="contained"
          sx={{ borderRadius: '4px' }}
          LinkComponent={Link}
          to="task-timeline"
        >
          <Typography variant='h4'> Task Timeline</Typography>  &nbsp;<HourglassEmpty />
        </TaskButton>
      
      </Box>
    </>
  )
}
export default EmployeeTaskButtons
