import React, { useState } from "react";
import { createHoliday } from "../../apis/holiday.api";
import { createBranch } from "../../apis/branch.api";
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction";
import AddBranchUI from "./AddBranchUI"
import { closeModal } from "../../store/actions/modalAction";

const AddBranchController = ({callBack}) => {
    const dispatch = useDispatch()
    const createBranchApi = createBranch
    const [loading, setLoading] = useState(false)
    const [address, setAddress] = useState("");
    const [name, setName] = useState("")
    const [err, setErr] = useState("")

    const addBranch = (e) => {
       e.preventDefault()
        setLoading(true)
        dispatch(callApiAction(
            async () => await createBranchApi({ address, name }),
            (response) => {
                callBack()
                setLoading(false)
                dispatch(closeModal())
            },
            (err) => {
                setLoading(false)
                setErr(err)
            }
        ))
    }
    return <AddBranchUI loading={loading} addBranch={addBranch} setAddress={setAddress} setName={setName} err={err} setErr={setErr} name={name} address={address}/>
}

export default AddBranchController;