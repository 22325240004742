import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { createEmployeeType,deleteEmployeeType ,fetchEmployeeTypes} from "../../../apis/employeeType.api"
import EmployeeTypeUI from "./EmployeeTypeUI"

const EmployeeTypeController = ({ userId }) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [state, setState] = useState([])
    const [loading, setLoading] = useState(false)
    const onUpdateValue = () => {

    }
    const fetchEmployeeTypesApi = fetchEmployeeTypes
    const deleteEmployeeTypeApi = deleteEmployeeType

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchEmployeeTypesApi(),
            (response) => {
                setState(response.result)
                // console.log(state)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
            ))
    }

    const DeleteEmployeeType = (id) => {
       
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteEmployeeTypeApi({id}),
            (response) => {
                setLoading(false)
                window.location.reload(true)
                // console.log(response)
            },
            (err) => {
                setLoading(false)
                console.log(err)
            }
        ))
    }


    useEffect(() => {
        
            fetchList()
        
    }, [])

    return <EmployeeTypeUI setState={setState} callBack={fetchList} loading={loading} state={state} DeleteEmployeeType={DeleteEmployeeType}/>
}
export default EmployeeTypeController