import {
    Box,
    Button,
    Checkbox,
    Collapse,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Slide,
    Typography,
  } from '@mui/material'
  import { DesktopDatePicker } from '@mui/x-date-pickers'
  import moment from 'moment'
  import { useDispatch, useSelector } from 'react-redux'
  import SubmitButton from '../../../components/button/SubmitButton'
  import CustomInput from '../../../components/inputs/CustomInput'
  import CustomRadioButtons from '../../../components/layouts/common/CustomRadioButtons'
  import { HALF_TYPE, HALF_TYPE_OBJ, LEAVE_TYPES } from '../../../utils/constants'
  import { useNavigate } from 'react-router-dom'

  
  const CoffRaiseUI = ({
    userId,
    onSubmit,
    loading,
    fields,
    setFields,
    disableDates,
  }) => {
    const { holiday } = useSelector((state) => state)
  
    const { leaveType, leaveBalance } = useSelector(state => state)

    const navigate = useNavigate()
  
    return (
      <Box p={4} mt={1}  component="form" onSubmit={onSubmit}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
          <Box >
            <Typography variant='h2'>
              Comp-Off Application
            </Typography>
            <Typography variant='h6' color="error">
              {fields.err}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography variant='h3' color="grey.main">
              Application Date
            </Typography >
            <Typography variant='h3' ml={3}>
              {moment().format("DD MMMM YYYY")}
            </Typography>
          </Box>
  
  
        </Box>
        <Box sx={{ display: "flex" }} mt={4}>
          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <Typography variant='h3' mt={3}>
            Comp-Off Date:
            </Typography>
  
  
            <Box mt={2}>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  height: '62px',
                }}
              >
                <Box sx={{ display: 'flex', width: '100%' }}>
                  <DesktopDatePicker
                    shouldDisableDate={disableDates}
                    renderInput={(props) => {
                      return <CustomInput {...props} sx={{ height: '100%' }} />
                    }}
                    inputFormat="DD-MM-yyyy"
                    disabled={
                      loading ||
                      !(holiday.data && Array.isArray(holiday.data)) ||
                      holiday.loading
                    }
                    value={fields.date}
                    onChange={(e) => {
                      const changedVal = {}
                      changedVal['date'] = e
                     
                      setFields({ ...fields, err: '', ...changedVal })
                    }}
                    type="date"
                  />
                  <Box mr={4}></Box>
                  <CustomRadioButtons
                    options={
                      !fields.moreThanOneDay
                        ? HALF_TYPE
                        : [HALF_TYPE[0], HALF_TYPE[2]]
                    }
                    value={fields.time_duration}
                    onChange={(e) => {
                      setFields({ ...fields, time_duration: e.target.value })
                    }}
                    sx={{ height: '56px', marginTop: '7px' }}
                  />
                </Box>
              </Box>
            </Box>
            
          
  
  
            <Typography variant='h3' mt={3}>
              Reason:
            </Typography>
            <CustomInput
              disabled={loading}
              value={fields.remarks}
              onChange={(e) =>
                setFields({ ...fields, err: '', remarks: e.target.value })
              }
              type="text"
              multiline
              placeholder="Write reason of leave..."
              rows={2}
            />
  
  
            <Box mt={3} sx={{display:"flex",justifyContent:"flex-end"}}>
              
              <Box sx={{display:"flex"}} mr={3}>
                <Button
                  loading={loading}
                  type="reset"
                  variant="text"
                  onClick={()=>{navigate("/calendar/")}}
                  sx={{textDecoration:"underline !important"}}
                  color="primary"
                  title=" Cancel Comp-Off"
                >Cancel Comp-Off
  
                </Button>
              </Box>
              <Box sx={{display:"flex"}}>
                <SubmitButton
                
                  loading={loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                  title=" Confirm Comp-Off"
                >
  
                </SubmitButton>
              </Box>
            </Box>
          </Box>  
        </Box>
      </Box>
    )
  }
  export default CoffRaiseUI




// import { Box, Button, Checkbox, Collapse, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, Slide, Typography } from "@mui/material"
// import { DesktopDatePicker } from "@mui/x-date-pickers"
// import moment from "moment"
// import { useDispatch, useSelector } from "react-redux"
// import SubmitButton from "../../../components/button/SubmitButton"
// import CustomInput from "../../../components/inputs/CustomInput"
// import { CenteredBox } from "../../../components/layouts/common/boxes"
// import CustomRadioButtons from "../../../components/layouts/common/CustomRadioButtons"
// import { closeModal } from "../../../store/actions/modalAction"
// import { HALF_TYPE } from "../../../utils/constants"
// import { Link, useNavigate } from "react-router-dom"

// const CoffRaiseUi = ({ userId, onSubmit, loading, fields, setFields, disableDates }) => {
//     const { holiday } = useSelector(state => state)
//     const dispatch = useDispatch()
//     const handleClose = () => {
//         dispatch(closeModal())
//     }
//     const navigate = useNavigate();

//     return <>
//         <Box component="form" sx={{ display: "flex", flexDirection: "column", overflowY: "auto", position: "relative" }} maxHeight="100%" onSubmit={onSubmit} >
//             <DialogTitle variant="h2">
//                 {'Compensatory leave (Comp-Off)'}
//                 <Box component={'div'} mt={"7px"}><Typography variant="h4" color={'red'}>{fields.err} </Typography></Box>
//             </DialogTitle>
//             <DialogContent sx={{ width: "100%", display: "flex" }}>
//                 <Box sx={{ display: "flex", position: "absolute", right: "24px", top: "24px", lineHeight: "24px" }}>
//                     <Typography fontWeight={300} variant="h4" sx={{ color: "#666666", fontSize: "24px" }}>Application Date&nbsp;&nbsp;</Typography>
//                     <Typography fontWeight={400} variant="h3" sx={{ color: "#262626", lineHeight: "32px" }}>{fields.applicationDate && fields.applicationDate.format("DD MMMM YYYY")} </Typography>
//                 </Box>
//                 <Box sx={{ marginTop: "10px", width: "75%" }}>
//                     <Box>
//                         <Typography fontWeight={500} variant="h3"> Date : </Typography>
//                     </Box>
//                     <Box sx={{ marginTop: "7px" }}>
//                         <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
//                             <Box sx={{ display: "flex", width: "100%" }}>
//                                 <DesktopDatePicker
//                                     renderInput={(props) => {
//                                         return <CustomInput {...props} sx={{ height: "100%" }} />
//                                     }}
//                                     shouldDisableDate={disableDates}
//                                     inputFormat="DD-MM-yyyy"
//                                     disabled={loading || !(holiday.data && Array.isArray(holiday.data)) || holiday.loading}
//                                     value={fields.startDate}
//                                     onChange={(e) => {
//                                         const changedVal = {}
//                                         changedVal['startDate'] = e
//                                         if (!fields.moreThanOneDay) {
//                                             changedVal['endDate'] = e
//                                         }
//                                         setFields({ ...fields, err: '', ...changedVal })

//                                     }}
//                                     type="date"

//                                 />
//                                 <Box mr={4}></Box>
//                                 <CustomRadioButtons
//                                     options={!fields.moreThanOneDay ? HALF_TYPE : [HALF_TYPE[0], HALF_TYPE[2]]}
//                                     value={fields.date_from_half}
//                                     onChange={(e) => { setFields({ ...fields, date_from_half: e.target.value }) }}
//                                     sx={{ height: "56px", marginTop: "7px" }}
//                                 />
//                             </Box>
//                         </Box>
//                     </Box>


//                     <Box sx={{ marginTop: "7px" }}>
//                         <Typography fontWeight={500} variant="h3">Remarks : </Typography>
//                     </Box>
//                     <Box sx={{ marginTop: "7px" }}>
//                         <CustomInput

//                             disabled={loading}
//                             value={fields.remarks}
//                             onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
//                             type="text"
//                             multiline
//                             placeholder="Write Remarks of leave..."
//                             rows={2}
//                         />
//                     </Box>
//                     {

//                     }
//                     <Box sx={{ float: "right", marginTop: "7px" }}>
//                         <Button sx={{ borderBottom: "1px solid #808080", borderRadius: "0", padding: "0px", marginRight: "20px" }} LinkComponent={Link}
//                             onClick={() => navigate('/calendar/')}>Cancel Leave</Button>
//                         <Button loading={loading} type="submit" variant="contained" color="primary" >Confirm Leave</Button>
//                     </Box>
//                 </Box>
//             </DialogContent>
//         </Box>
//     </>
// }
// export default CoffRaiseUi