import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import UserCreateUI from "./UserCreateUI"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { addUserApi, getUserByIdApi, updateUserField } from "../../apis/user.api"
import { closeModal } from "../../store/actions/modalAction"
import { useNavigate, useParams } from "react-router-dom"
import { USER_ROLES } from "../../utils/constants"
import { fetchEmployeeTypes } from "../../apis/employeeType.api"
import { fetchDepartments } from "../../apis/department"
import { fetchBranches } from "../../apis/branch.api"
import { uploadFile, uploadImage } from "../../apis/file.api"
import moment from "moment"
import { CenteredBox } from "../../components/layouts/common/boxes"
import { CircularProgress } from "@mui/material"

const UserCreateController = ({ callBack = () => { }, updateType, userId }) => {
    const dispatch = useDispatch()
    const params = useParams()

    const [loading, setLoading] = useState(false)
    const [userLoading, setUserLoading] = useState(false)


    const defaultFormValues = {
        err: '',
        updateType,
        id: userId,
        first_name: '',
        middle_name: '',
        last_name: '',

        email: '',
        password: '',

        phone_emergency_country_code: "+91",
        phone_country_code: "+91",
        phone_emergency: null,
        phone: null,
        pan_no: "",
        pan_url: "",
        aadhar_no: null,
        addhar_url: "",
        medical_remarks: "",
        country: null,
        state: null,
        city: null,

        address_line1: "",
        address_line2: "",
        zip_code: null,

        dob: moment().toISOString(),
        blood_group: null,
        profile_url: "",

        branch: "",
        employee_type: "",
        role: USER_ROLES.employee,
        department: "",
        designation: "",
        weekly_of: [],
        join_date: moment().toISOString(),
        parent_id: null,

        bank_name: '',
        account_no: '',
        ifsc_code: "",
        cancel_check: "",

        basic_salary: 0,
        house_rent_allowance: 0,
        conveyence_allowance: 0,
        food_allowance: 0,
        other_allowance: 0,
        incencitive: 0,
        medical_allowance: 0,

        proffesional_tax: 0,
        pf_id: "",
        uan_id: "",
        pf_percent: 0,
        esic_id: "",
        esic_percent: 0,

        nda_url: "",
        aggreement_url: "",
    }
    const [formValues, setFormValues] = useState(localStorage.getItem("user_form_value") ? JSON.parse(localStorage.getItem("user_form_value")) : defaultFormValues);



    const getUser = (userId) => {

        setUserLoading(true);
        dispatch(
            callApiAction(
                async () => await getUserByIdApi({ userId }),
                async (response) => {
                    const { bank_details, salary, taxes, pf, esic, branch, department, parent_id, employee_type, ...details } = response
                    setUserLoading(false);
                    const dropDownsData = {

                    }
                    if (branch) {
                        dropDownsData['branch'] = branch._id
                    }
                    if (department) {
                        dropDownsData['department'] = department._id
                    }
                    if (employee_type) {
                        dropDownsData['employee_type'] = employee_type._id
                    }

                    if (parent_id && Array.isArray(parent_id) && parent_id[0]) {
                        dropDownsData['parent_id'] = parent_id[0]._id
                    }


                    setFormValues({ ...formValues, ...details, ...bank_details, ...salary, ...taxes, ...pf, ...esic, ...dropDownsData });

                },
                (err) => {
                    setUserLoading(false);
                }
            )
        );
    };



    const navigate = useNavigate()

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const dataToBepassed = {}
        for (let item in formValues) {
            if (formValues[item] && formValues[item] != '') {
                dataToBepassed[item] = formValues[item]
            }
        }



        dispatch(
            callApiAction(
                async () => userId && updateType ? await updateUserField({ ...dataToBepassed, updateType, userId }) : await addUserApi(dataToBepassed),
                async (response) => {
                    setLoading(false)
                    setFormValues(defaultFormValues)
                    if (localStorage.getItem("user_form_value"))
                        localStorage.removeItem("user_form_value")
                    if (userId) {
                        dispatch(closeModal())
                        await callBack()
                    } else { await callBack(); navigate("/users/", { replace: true }) }

                },
                (err) => {
                    setLoading(false)
                    setFormValues({ ...formValues, err })
                }
            )
        )


    }
    useEffect(() => {
        if (userId && userId != '')
            getUser(userId)
    }, [userId])


    if (userLoading) {
        return <CenteredBox mt={4} mb={4} ><CircularProgress /></CenteredBox>
    }

    return <UserCreateUI
        userId={userId}
        updateType={updateType}
        loading={loading}
        onSubmit={onSubmit}
        formValues={formValues}
        setFormValues={setFormValues}


    />
}
export default memo(UserCreateController)