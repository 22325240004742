import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import AddLeaveTypesUI from "./AddLeaveTypesUI";
import { createleaveType, fetchleaveTypesById, updateleaveType } from "../../apis/leaveTypes";
import { closeModal } from "../../store/actions/modalAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";

const AddLeaveTypesContorller = ({ id, callBack = () => { } }) => {

  const dispatch = useDispatch();

  const [fetchLoading, setFetchLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const [fields, setFields] = useState({
    err: "",
    name: "",
    short_name: "",
    color: "#000",
    priority: 1,
    per_month_count: 1,
    yearly_carry_forward: false,
    identifier: ""
  })

  const fetchById = () => {
    setFetchLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchleaveTypesById({ id }),
        (response) => {
          setFields({ ...fields, ...response });
          setFetchLoading(false);
        },
        (err) => {
          setFetchLoading(false);
        }
      )
    );
  };
  const addLeaveType = (e) => {
    e.preventDefault()
    setLoading(true);

    dispatch(
      callApiAction(
        async () => id ? await updateleaveType({ ...fields, id }) : await createleaveType(fields),
        (response) => {

          setLoading(false);
          dispatch(closeModal())
          dispatch(callSnackBar(`Leave Type ${id?"Updated ":"Added "} Successfully`,SNACK_BAR_VARIETNS.suceess))
          callBack()

        },
        (err) => {
          setLoading(false);
          setFields({ ...fields, err });
        }
      )
    );
  };
  useEffect(() => {
    
    if (id)
      fetchById();
  }, [id]);
  return (
    <AddLeaveTypesUI
      loading={loading}
      setFields={setFields}
      fields={fields}
      isUpdate={id}
      fetchLoading={fetchLoading}
      addLeaveType={addLeaveType}
    />
  );
};

export default AddLeaveTypesContorller;
