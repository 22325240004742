import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserByIdApi, updateUserField } from "../../apis/user.api";
import { callApiAction } from "../../store/actions/commonAction";
import { loggedInUser } from "../../utils/helper";

import LeaveTypeUi from "./LeaveTypeUI";
import { deleteleaveType, fetchleaveTypes } from "../../apis/leaveTypes";


const LeaveTypeController = ({ userId }) => {

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const onUpdateValue = () => { };
  const fetchLeaveTypesApi = fetchleaveTypes;
  const deleteLeaveTypeApi = deleteleaveType;

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await fetchLeaveTypesApi(),
        (response) => {
          setState(response.result);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const deleteLeaveTypeFun = (id) => {
    
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteLeaveTypeApi({ id }),
        (response) => {
          setLoading(false);
          
        },
        (err) => {
          setLoading(false);
          console.log(err);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <LeaveTypeUi
      fetchList={fetchList}
      setState={setState}
      loading={loading}
      state={state}
      deleteLeaveType={deleteLeaveTypeFun}
    />
  );
};
export default LeaveTypeController;
