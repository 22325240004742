import {
  CalendarMonthOutlined,
  Circle,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material'
import { Grid, IconButton, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'

import moment from 'moment'
import { useSelector } from 'react-redux'
import UserSearchBar from '../../components/inputs/SearchBar'
import { CenteredBox } from '../../components/layouts/common/boxes'
import CustomDatePicker from '../../components/layouts/common/CustomDatePicker'
import CalendarController from '../calendar/CalendarController'
import { BasicProfileDetailsComponent } from '../profile/BasicProfileDetailsComponent'

import LeaveBalance from './LeaveBalance'
import LeaveButtons from './LeaveButtons'
import CustomMonthPicker from '../../components/layouts/common/CustomMonthPicker'

const EmployeeLeaveUi = ({
  data,
  date,
  setDate,
  onUserChange,
  selectedUser,
  callBack,
}) => {
  const { user } = useSelector((state) => state)
  return (
    <>
      <Box mt={3}>
        <UserSearchBar onUserChange={onUserChange} />
      </Box>
      {selectedUser && selectedUser != null && (
        <>
          <Box mt={3} mb={3}>
            <Grid container columnSpacing={3} rowSpacing={3}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                  mr={2}
                >
                  <Box
                    sx={{
                      height: '100%',
                      border: '1px solid #BFBFBF',
                      boxShadow:
                        '0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)',
                      borderRadius: '4px',
                    }}
                  >
                    <BasicProfileDetailsComponent data={selectedUser} />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <LeaveBalance userId={selectedUser._id} />
              </Grid>
             
              
              <Grid item xs={12}>
                <Box mb={3}>
                  <Grid container spacing={2}>
                  <Grid item md={10}><Box><CustomMonthPicker date={date} setDate={setDate} /></Box> </Grid>
                  <Grid item md={2}><LeaveButtons modal userId={selectedUser._id} /></Grid>
                  

                  </Grid>
                </Box>
                <CalendarController
                  userId={selectedUser._id}
                  weeklyOf={selectedUser.weekly_of}
                  calendarTimeObj={date && date.toDate()}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {!selectedUser && (
        <CenteredBox mt={4}>
          <Typography variant="h3" color="grey.main">
            No Employee Selected
          </Typography>
        </CenteredBox>
      )}
    </>
  )
}
export default EmployeeLeaveUi
