import { Box, Grid, Skeleton, Typography, styled } from "@mui/material"
import { makeAmountString } from "../../utils/helper"

const ReimburesementCountOuter = styled(Box)(({ theme, color }) => ({
    display: "flex",
    height: "100%",
    width: '100%',
    // borderRadius: theme.shape.borderRadius,
    position: "relative",

    backdropFilter: "opacity(0)",
    alignItems: "center",
    color: "black",
    '&::before': {
        content: '" "',
        display: 'block',
        position: "absolute",
        left: "0px",
        backgroundColor: color,
        width: "3px",
        borderTopRightRadius: "3px",
        borderBottomRightRadius: "3px",
        height: "50%",
        top: "25%"
    },

    '&::after': {
        content: '" "',
        display: 'block',
        position: "absolute",
        left: "0px",
        zIndex: -1,
        top: "0px",
        backgroundColor: color,
        borderRadius: theme.shape.borderRadius,
        opacity: 0.1,
        width: "100%",
        height: "100%",

    }

}))

const ReimburesementTotalCountOuter = styled(Box)(({ theme, color }) => ({
    display: "flex",
    height: "100%",
    width: '100%',
    padding: theme.spacing(2),
    position: "relative",
    background: theme.palette.light.main,
    border: "2px dashed " + theme.palette.grey.main,
    borderRadius: theme.shape.borderRadius,
    alignItems: "center",


}))


const ReimburesementCount = ({ title, subTitle, count, color }) => {

    return <ReimburesementCountOuter color={color} p={2}>
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }} p={1} pr={0} pl={2}>
            <Typography variant='h4' fontWeight="600">
                {title}
            </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end" }} p={1} pr={2}>
            <Typography variant='h3' fontWeight="600" lineHeight="100%">
                {makeAmountString(count)}
            </Typography>
            <Typography variant='h6' color="grey" >
                RS.
            </Typography>
        </Box>
    </ReimburesementCountOuter>
}

const ReimburesmentCounts = ({ data, loading }) => {
    const color = ["#FF9500", "#FF0000", "#27D8BA", "#001DFF"]
    const countCounts = (item) => {
        return item.approved + item.pending + item.rejected
    }
    return <Box sx={{ width: "100%" }}>
        <Grid container columnSpacing={3} rowGap={3} >
            <Grid item md={8} xs={12}>
                <Grid container columnSpacing={3}  rowGap={3} sx={{ height: "100%" }}>
                    {
                        loading && [1, 2, 3, 4].map((key) => <Grid item key={key} md={3} xs={6}> <Skeleton width="100%" height="150px"></Skeleton></Grid>)
                    }
                    {
                        !loading && data.data && data.data.length > 0 && data.data.map((item, index) => {

                            return <Grid item key={item.type} md={12 / (data.data.length)} xs={6}>
                                <ReimburesementCount title={item.type && item.type.toUpperCase()} count={countCounts(item)} color={color[index]} />


                            </Grid>
                        })
                    }
                </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
                {loading && <Skeleton width="100%" height="150px"></Skeleton>}
                {
                    !loading && data.total && <ReimburesementTotalCountOuter>
                        <Grid container  >
                            <Grid item md={4} xs={4}>
                                <Typography variant="subtitle1" align="center" mb={1}>
                                    Total Amount
                                </Typography>
                                <Typography variant="h6" align="center">
                                    {countCounts(data.total)}
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={4}>
                                <Typography variant="subtitle1" align="center" mb={1}>
                                    Approved Amount
                                </Typography>
                                <Typography variant="h6" align="center">
                                    {data.total && data.total.approved}
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={4}>
                                <Typography variant="subtitle1" align="center" mb={1}>
                                    Rejected Amount
                                </Typography>
                                <Typography variant="h6" align="center">
                                    {data.total && data.total.rejected}
                                </Typography>
                            </Grid>
                        </Grid>

                    </ReimburesementTotalCountOuter>
                }
            </Grid>
        </Grid>

    </Box>
}
export default ReimburesmentCounts