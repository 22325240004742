import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SubmitButton from '../../../components/button/SubmitButton'
import { CenteredBox } from '../../../components/layouts/common/boxes'
import { HALF_TYPE_OBJ, LEAVE_RESPONSE } from '../../../utils/constants'
import { findObjectKeyByValue, leaveStatusColored } from '../../../utils/helper'
import { LEAVE_STATUS } from '../../../utils/leave.constants'
import { CancelRounded, CheckCircle } from '@mui/icons-material'
import CustomInput from '../../../components/inputs/CustomInput'

const LeaveActionComponent = ({ state,
  setState,
  fieldLoading,
  onApprove,
  onReject,
  loading,
  isFromModal }) => {
  const [isBtnClicked, setButtonClicked] = useState('')
  const buttonClick = (title) => {
    setButtonClicked(title)
  }
  const handleClose = () => {
    setButtonClicked('')
  }
  const handleSubmit = () => {
    // if (isBtnClicked == 'approve') {
    //   onApprove()
    // }
    onReject()
  }
  return (

    <>
      <Dialog
        open={isBtnClicked != ''}
        TransitionComponent={Fade}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Add remarks for " + (isBtnClicked == "approve" ? "approval" : "rejection")}</DialogTitle>
        <DialogContent>
          <CustomInput
            multiline
            rows={4}
            disabled={fieldLoading.loading}
            value={state.action_remarks}
            onChange={(e) => setState({ ...state, action_remarks: e.target.value })}
            type="text"
            placeholder="Write remarks..."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        {  <Button variant='contained' onClick={handleSubmit}> {fieldLoading.loading && <CircularProgress color='light' size={20} />}&nbsp;{isBtnClicked == "approve" ? "Approve Leave" : "Reject Leave"}</Button>}
        </DialogActions>
      </Dialog>
      {!fieldLoading.loading && <Tooltip title="Approve">
        <IconButton onClick={() => {
          onApprove()
        }}>
          <CheckCircle color="success" />
        </IconButton>

      </Tooltip>}
      {!fieldLoading.loading && <Tooltip title="Reject">
        <IconButton onClick={() => {
          buttonClick('reject')
        }}>
          <CancelRounded color="error" />
        </IconButton>
      </Tooltip>}
      {
        fieldLoading.loading && <CircularProgress size={30} />
      }
    </>
  )
}

const LeaveDetailsUi = ({
  state,
  setState,
  fieldLoading,
  onApprove,
  onReject,
  loading,
  isFromModal,
}) => {
  const { user } = useSelector((state) => state)
  const dispatch = useDispatch()

  return (
    <>
      {!loading && state && (
        <Box mt={isFromModal ? 0 : 3} mb={3}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: 3,
            }}
          >
            {!isFromModal && (
              <Typography variant="h2">Leave Application</Typography>
            )}
            <Box mt={3}>
              <Grid container spacing={2} rowSpacing={3}>
                <Grid xs={6} item>
                  <Typography variant="h5">Applied By :</Typography>
                  <Typography variant="h6" color="grey.main">
                    {state.applied_by && (state.applied_by.first_name + " "+ state.applied_by.last_name)}
                  </Typography>
                </Grid>

                <Grid xs={6} item>
                  <Typography variant="h5">Application Date :</Typography>
                  <Typography variant="h6" color="grey.main">
                    {moment(state.createdAt).format('DD-MM-YYYY')}
                  </Typography>
                </Grid>

                <Grid xs={6} item>
                  <Typography variant="h5">Type</Typography>
                  <Typography variant="h6" color="grey.main">
                    {state.leave_type ? state.leave_type.short_name :state.lwp?"LWP": "Multi Leaves"}
                  </Typography>
                </Grid>

                <Grid xs={6} item>
                  <Typography variant="h5">Status</Typography>

                  {state.status == LEAVE_STATUS.PENDING && user.data._id != state.applied_by._id ? (
                    <>
                      <LeaveActionComponent
                        state={state}
                        setState={setState}
                        fieldLoading={fieldLoading}
                        onApprove={onApprove}
                        onReject={onReject}
                        loading={loading}
                        isFromModal={isFromModal}

                      />
                    </>
                  ) : (<Chip
                    size='small'
                    color={leaveStatusColored(state.status)}
                    label={findObjectKeyByValue(state.status, LEAVE_STATUS)}
                  />)
                  }

                </Grid>

                <Grid xs={12} item>
                  <Typography variant="h5">Reason</Typography>
                  <Typography variant="h6" color="grey.main">
                    {state.remarks}
                  </Typography>
                </Grid>

                <Grid xs={6} item>
                  <Typography variant="h5">From</Typography>
                  <Typography variant="h6" color="grey.main">
                    {moment(state.from).format('DD-MM-YYYY')}
                    &nbsp;({findObjectKeyByValue(state.from_time_duration, HALF_TYPE_OBJ)})
                  </Typography>
                </Grid>

                {(state.leave_count > 1 || moment(state.from).format('DD-MM-YYYY') != moment(state.to).format('DD-MM-YYYY')) && <Grid xs={6} item>
                  <Typography variant="h5">To</Typography>
                  <Typography variant="h6" color="grey.main">
                    {moment(state.to).format('DD-MM-YYYY')}
                    &nbsp; ({findObjectKeyByValue(state.to_time_duration, HALF_TYPE_OBJ)})

                  </Typography>
                </Grid>}

                <Grid xs={12} item>
                  <Typography variant="h5">Total Leave</Typography>
                  <Typography variant="h6" color="grey.main">
                    {state.leave_count}
                  </Typography>
                </Grid>
                {state.status != LEAVE_STATUS.PENDING && <>

                  <Grid xs={6} item>
                    <Typography variant="h5">Actioned By:</Typography>
                    <Typography variant="h6" color="grey.main">
                      {state.actioned_by && (state.actioned_by.first_name + " " + state.actioned_by.middle_name + " " + state.actioned_by.last_name)}
                    </Typography>
                  </Grid>

                  <Grid xs={6} item>
                    <Typography variant="h5">Actioned Remarks:</Typography>
                    <Typography variant="h6" color="grey.main">
                      {(state.action_remarks)}
                    </Typography>
                  </Grid>
                </>
                }
                {
                  state.deducted_leave&& state.deducted_leave.length>0 && <>
                    <Grid xs={6} item>
                      <Box>
                        <Typography variant="h5">Deduction From:</Typography>
                        <Table>
                          <TableHead sx={(theme)=>({background:theme.palette.grey.light})}>
                            <TableRow>
                              <TableCell>Leave </TableCell>
                              <TableCell>Deduction </TableCell>
                              <TableCell>Is LWP </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                          {state.deducted_leave.map((item, index) => <TableRow key={index}  >
                          <TableCell>{item.leave_type && item.leave_type.name}</TableCell>
                          <TableCell>{item.count}</TableCell>
                          <TableCell>{item.is_lwp?"Yes":"No"}</TableCell>
                        </TableRow>)}
                          </TableBody>
                        </Table>
                        

                      </Box>
                    </Grid>

                  </>
                }
              </Grid>

              {/* <Grid container mt={4} columnSpacing={4}>
                    <Grid item xs={6}>
                      <SubmitButton
                        loading={fieldLoading.fieldName == 'reject'}
                        onClick={onReject}
                        disabled={fieldLoading.loading}
                        title={'Reject'}
                        color="error"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SubmitButton
                        loading={fieldLoading.fieldName == 'accept'}
                        onClick={onApprove}
                        disabled={fieldLoading.loading}
                        title={'Approve'}
                        color="success"
                      />
                    </Grid>
                  </Grid> */}
              {/* {state.status == LEAVE_RESPONSE.pending &&
                user.data._id != state.userId._id && (
                 
                )} */}
            </Box>
          </Box>
        </Box>
      )}
      {!state && !loading && (
        <CenteredBox>
          <Typography variant="h3">Loading Data...</Typography>
        </CenteredBox>
      )}
      {loading && (
        <CenteredBox sx={{ minHeight: '300px' }}>
          <CircularProgress />
        </CenteredBox>
      )}
    </>
  )
}
export default LeaveDetailsUi
