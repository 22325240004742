import {

  Box,
  Button,

  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  ButtonBase,
  Chip,
  IconButton,
} from "@mui/material";
import { CenteredBox } from "../../components/layouts/common/boxes";
import {

  Add, Edit,
} from "@mui/icons-material";
import { center } from "../../assets/css/theme/common";
import { Skeleton } from "@mui/material";
import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";


import AddLeaveTypesContorller from "./AddLeaveTypesContorller";


const Row = memo(({ name, shortName, color, priority, allowedPerMonth, isYearlyCarryForward, identifier, id, updateLeaveType }) => {


  return <TableRow>
    <TableCell>{name}</TableCell>
    <TableCell>{shortName}</TableCell>
    <TableCell> {color}</TableCell>
    <TableCell>{priority}</TableCell>
    <TableCell>{allowedPerMonth}</TableCell>
    <TableCell>{isYearlyCarryForward ? "Yes" : 'No'}</TableCell>
    <TableCell>{identifier}</TableCell>
    <TableCell><IconButton onClick={() => updateLeaveType(id)} ><Edit /></IconButton></TableCell>

  </TableRow>
})
const LeaveTypeUi = ({ setState, loading, state, deleteLeaveTypeFun, fetchList }) => {
  const dispatch = useDispatch();
  const addLeaveType = useCallback(() => {
    dispatch(openModal({ title:"Add Leave", component: <AddLeaveTypesContorller callBack={fetchList} />, size: "sm" }));
  }, []);
  const updateLeaveType = useCallback((id) => {
    dispatch(
      openModal(
        {
          title:"Update Leave",
          component: <AddLeaveTypesContorller
            id={id}
            callBack={fetchList}
          />,
          size: "sm"
        }
      )
    );
  }, [dispatch]);

  return (
    <>
      {!state && !loading && (
        <CenteredBox>
          <Typography variant="h3">Loading Data...</Typography>
        </CenteredBox>
      )}
      <Box m={3}>
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "40px",
              color: "#0D0D0D",
            }}
          >
            Leave Types
          </Typography>

          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "12px",
              lineHeight: "12px",
            }}
            color="grey"
            mb={2}
          >
            New created leave type will apply to next year  or employee created after leave type genration.
          </Typography>
          <Button
            onClick={addLeaveType}
            sx={{
              display: "flex",
              height: "100%",
              zIndex: 0,
              textTransform: "capitalize",
              boxShadow: "none",
              border: "1px solid #393939",
              width: "100%",
              height: "56px",
              ":hover": {
                boxShadow: "none",
              },
            }}
          >
            <Add color="primary" /> &nbsp;Add Leave
          </Button>
        </Box>
        <TableContainer
          component={Box}
          mt={3}
          sx={{
            boxShadow:
              "0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)",
            borderRadius: "4px",
            border: "1px solid #BFBFBF",
          }}
        >
          <Table aria-label="collapsible table" sx={{}}>
            <TableHead sx={{ background: "#F2F2F2" }}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Short Name</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Priority</TableCell>
                <TableCell>Allowed Per Month</TableCell>
                <TableCell>Is Yearly Carry Forward</TableCell>
                <TableCell>Identifier</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <>
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Skeleton width={"100%"} height={"50px"} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Skeleton width={"100%"} height={"50px"} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Skeleton width={"100%"} height={"50px"} />
                    </TableCell>
                  </TableRow>
                </>
              )}
              {!loading &&
                state &&
                state.map((leaveType) => (
                  <Row
                    id={leaveType._id}
                    name={leaveType.name}
                    shortName={leaveType.short_name}
                    color={leaveType.color}
                    priority={leaveType.priority}
                    allowedPerMonth={leaveType.per_month_count}
                    isYearlyCarryForward={leaveType.yearly_carry_forward}
                    identifier={leaveType.identifier}
                    updateLeaveType={updateLeaveType}

                  />
                ))}
              {!loading && state.length == 0 && (
                <TableRow>
                  <TableCell colSpan={8} align="Center">
                    <Typography sx={{ ...center }} variant="h3">
                      No Data
                    </Typography>{" "}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default LeaveTypeUi;
