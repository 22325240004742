import { useCallback, useEffect, useState } from "react"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { getUserApi, updateUserField } from "../../../apis/user.api"
import { useParams } from "react-router-dom"
import { openModal } from "../../../store/actions/modalAction"
import LeaveSummaryUi from "./LeaveSummaryUi"
import { getLeavesApi, getPendingCoffApi, getPendingLeavesApi } from "../../../apis/leave.api"
import moment from "moment"

const PendingCoffController = ({ userId, pendingAndGenral }) => {

    const [list, setList] = useState([])



    const [listLoading, setListLoading] = useState(false)

    const dispatch = useDispatch()
    const params = useParams()
    const [filters, setFilters] = useState({

        date: moment(),
        userId: userId

    })

    const fetchList = () => {
        setListLoading(true)
        const parmasToPass = { ...filters }
        parmasToPass.date = parmasToPass.date.valueOf()
        dispatch(callApiAction(
            async () => await getPendingCoffApi({ ...parmasToPass }),
            (response) => {
                setList(response)

                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <LeaveSummaryUi
                pendingAndGenral={pendingAndGenral}
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                callBack={fetchList}
                list={list}
isFromCoff
            />

        </>
    )
}
export default PendingCoffController