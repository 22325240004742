import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../store/actions/commonAction'
import { useParams } from 'react-router-dom'
import { openModal } from '../../store/actions/modalAction'
import { getCoffApi, getLeavesApi, getLogsApi } from '../../apis/leave.api'
import moment from 'moment'
import Calendar from '../../components/layouts/Calendar'
import CalendarBox from './CalendarInnerBox'
import MessageDilog from '../../components/MessageDilog'
import {
  CALENDAR_ITEM_TYPES,
  LEAVE_COLORS,
  LEAVE_RESPONSE,

} from '../../utils/constants'
import LeaveDetailsController from '../leaves/leaveSummary/LeaveDetailsController'
import CoffDetailsController from '../leaves/leaveSummary/CoffDetailsController'
import { Box, Typography } from '@mui/material'
import { Circle } from '@mui/icons-material'
import { findObjectKeyByValue } from '../../utils/helper'
import { LEAVE_STATUS } from '../../utils/leave.constants'

const CalendarController = ({ userId, weeklyOf, calendarTimeObj }) => {
  const { holiday } = useSelector((state) => state)

  const [list, setList] = useState([])
  const [listLoading, setListLoading] = useState(false)

  const holidayList = holiday.data
  const [leaveList, setLeaveList] = useState(null)
  const [cOffList, setCOFFList] = useState(null)
  const [logsList, setLogsList] = useState(null)

  const [leavesCount, setLeavesCount] = useState({})
  const dispatch = useDispatch()

  const [filters, setFilters] = useState({
    userId: userId,
  })

  const fetchList = () => {
    setListLoading(true)
    setCOFFList(null)
    setLeaveList(null)
    setLogsList(null)

    const parmasToPass = { ...filters }

    dispatch(
      callApiAction(
        async () =>
          await getLeavesApi({
            ...parmasToPass,
            userId,
            date: calendarTimeObj.valueOf(),
          }),
        (response) => {
          setLeaveList(response)
        },
        (err) => {
          dispatch(
            openModal(
              {
                component: <MessageDilog title="Something went wrong" message={err} />, size: 'md'
              }

            )
          )
        },
      ),
    )

    dispatch(
      callApiAction(
        async () =>
          await getLogsApi({
            ...parmasToPass,
            userId,
            date: calendarTimeObj.toISOString(),
          }),
        (response) => {
          setLogsList(response)
        },
        (err) => {
          dispatch(
            openModal(
              {
                component: <MessageDilog title="Something went wrong" message={err} />, size: 'md'
              }

            )
          )
        },
      ),
    )
    dispatch(
      callApiAction(
        async () =>
          await getCoffApi({
            ...parmasToPass,
            userId,
            date: calendarTimeObj.valueOf(),
          }),
        (response) => {
          setCOFFList(response)
        },
        (err) => {
          dispatch(
            openModal(
              {
                component: <MessageDilog title="Something went wrong" message={err} />, size: 'md'
              }

            )
          )
        },
      ),
    )
  }

  useEffect(() => {
    fetchList()
  }, [filters, userId, calendarTimeObj.valueOf()])

  useEffect(() => {
    if (cOffList && logsList && holidayList && leaveList) {
      let temp = []
      if (logsList && Array.isArray(logsList) && logsList.length > 0) {
        logsList.forEach((dayLog) => {
          if (
            dayLog.data &&
            Array.isArray(dayLog.data) &&
            dayLog.data.length > 0
          ) {
            dayLog.data.forEach((log) => {
              temp.push({
                title: moment(log.logTime).format('HH:mm'),
                start: new Date(log.logTime),
                end: new Date(log.logTime),
                relatedId: log._id,
                type: log.logType,
              })
            })
          }
        })
      }
      if (cOffList && Array.isArray(cOffList)) {
        cOffList.forEach((coff) => {
          temp.push({
            title: 'Comp-OFF',
            start: new Date(coff.date),
            end: new Date(coff.date),
            relatedId: coff._id,
            fromDuration:coff.time_duration,
            toTimeDuration:coff.time_duration,
            type: CALENDAR_ITEM_TYPES.coff,
            color: LEAVE_COLORS['COFF_RAISE' + '_' + findObjectKeyByValue(coff.status, LEAVE_STATUS)],
            onClick: () => {

              dispatch(
                openModal(
                  {
                    component: <CoffDetailsController
                      leaveId={coff._id}
                      callBack={() => { }}
                      isFromModal={true}
                    />,
                    title: "Comp Off Application"
                  }
                ),
              )
            },
          })
        })
      }
      if (leaveList && Array.isArray(leaveList)) {
        leaveList.forEach((leave) => {

          temp.push({
            title: leave.leave_type ? leave.leave_type.short_name :leave.lwp ?"LWP": "Multi Leaves",
            start: new Date(leave.from),
            end: new Date(leave.to),
            relatedId: leave._id,
            fromDuration:leave.from_time_duration,
            toTimeDuration:leave.to_time_duration,
            type: CALENDAR_ITEM_TYPES.leave,
            color:
              LEAVE_COLORS[
              (leave.leave_type ? leave.leave_type.short_name.toUpperCase() : 'AUTO') + '_' + findObjectKeyByValue(leave.status, LEAVE_STATUS)],
            onClick: () => {
              dispatch(


                openModal(
                  {
                    component: <LeaveDetailsController
                      leaveId={leave._id}
                      callBack={() => { }}
                      isFromModal={true}
                    />,
                    title: "Leave Application"
                  }
                ),
              )
            },
          })
        })
      }

      if (holidayList && Array.isArray(holidayList)) {
        holidayList.forEach((leave) => {
          temp.push({
            title: leave.name,
            start: new Date(leave.date),
            end: new Date(leave.date),
            relatedId: leave._id,
            type: CALENDAR_ITEM_TYPES.holiday,
            color: LEAVE_COLORS.HOLIDAY,
            onClick: () => { },
          })
        })
      }      

      setList(temp)
      setListLoading(false)
    }
  }, [cOffList, logsList, holidayList, leaveList])

  useEffect(() => {
    if (leaveList && Array.isArray(leaveList)) {
      const temp = {}
      leaveList.forEach((leave) => {
        if (leave.leave_type) {
          if (temp[leave.leave_type.short_name]) {
            temp[leave.leave_type.short_name]['count'] += leave.leave_count
          } else {
            temp[leave.leave_type.short_name] = {
              leaveType: leave.leave_type.short_name,
              count: leave.leave_count,
              color:
                LEAVE_COLORS[
                leave.leave_type.short_name.toUpperCase() + '_' + findObjectKeyByValue(leave.status, LEAVE_STATUS)
                ],
            }
          }
        }


      })

      setLeavesCount(temp)
    } else {
      setLeavesCount({})
    }
  }, [leaveList])

  return (
    <>
      <Calendar

        loading={listLoading}
        data={list}
        dayComponent={(props) => <CalendarBox weeklyOf={weeklyOf ?? [0]}  {...props} />}
        calendarTimeObj={calendarTimeObj}
      />
      {/* {leavesCount &&
        Object.keys(leavesCount) &&
        Object.keys(leavesCount).length > 0 && (
          <Box mt={3} p={2} sx={{ display: 'flex' }}>
            <Typography variant="h4" mr={3}>
              Current month leaves{' '}
            </Typography>

            {Object.keys(leavesCount).map((leave) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center' }} mr={3}>
                  <Circle sx={{ color: leavesCount[leave].color }} />
                  <Typography variant="h5" color="grey.main" mr={2} ml={2}>
                    {leavesCount[leave].leaveType.toUpperCase()}
                  </Typography>
                  <Typography variant="h5">
                    {leavesCount[leave].count}
                  </Typography>
                </Box>
              )
            })}

            
          </Box>
        )} */}
    </>
  )
}
export default CalendarController
