import {
  Box,
  Button,
  Checkbox,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Slide,
  Typography,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import SubmitButton, { ResetButton } from '../../../components/button/SubmitButton'
import CustomInput from '../../../components/inputs/CustomInput'
import { CenteredBox } from '../../../components/layouts/common/boxes'
import CustomRadioButtons from '../../../components/layouts/common/CustomRadioButtons'
import { closeModal } from '../../../store/actions/modalAction'
import { HALF_TYPE, HALF_TYPE_OBJ, LEAVE_TYPES } from '../../../utils/constants'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { fetchLeaveBalanceCountAction } from '../../../store/actions/leaveBalanceAction'
import { useEffect } from 'react'

const LeaveBalanceComponent = ({ selectedLeave, leaveCount, userId, from }) => {

  const { leaveBalance } = useSelector(state => state)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchLeaveBalanceCountAction({ userId, date: from.valueOf() }))
  }, [userId, from.get("months")])
  const totalCount = (leaveBalance && leaveBalance.data && leaveBalance.data.data && leaveBalance.data.data.leaves && leaveBalance.data.data.leaves.reduce((previosVal, item) => {
    return previosVal + item.count
  }, 0))
  return <Box sx={(theme) => ({ background: theme.palette.grey['calender'], border: (totalCount - leaveCount < 0) ? ("2px solid " + theme.palette.error.main) : "unset", borderRadius: 1 })} p={3} >
    <Typography variant='subtitle1' fontWeight="bold" mb={3}>
      Total leaves remaining (days)
    </Typography>

    <Box>
      {leaveBalance && leaveBalance.data && leaveBalance.data.data && leaveBalance.data.data.leaves && leaveBalance.data.data.leaves.map((item) => {

        return (
          <Box key={item.leave_type.name} sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "space-between", background: selectedLeave == item.leave_type._id ? "white" : "unset", transition: "all 0.1s linear", px: 2, transform: selectedLeave == item.leave_type._id ? "scaleY(1.2)" : "unset" }} mb={2}>
            <Typography variant='subtitle1' fontWeight={selectedLeave == item.leave_type._id ? "800" : "unset"}   >{item.leave_type.short_name}</Typography>
            <Typography variant='subtitle1' fontWeight="bold">{item.count}</Typography>
          </Box>
        )
      })}


      <Box sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "space-between", borderTop: 1 }} pt={2} mb={2}>
        <Typography variant='subtitle1' color={totalCount - leaveCount < 0 ? "error" : "dark"} fontWeight="bold">Total</Typography>
        <Typography variant='subtitle1' color={totalCount - leaveCount < 0 ? "error" : "dark"} fontWeight="bold">{totalCount} - {leaveCount} = {(totalCount - leaveCount)}</Typography>
      </Box>


    </Box>
  </Box>
}

const LeaveRaiseUi = ({
  userId,
  onSubmit,
  loading,
  fields,
  setFields,
  disableDates,
}) => {
  const { holiday } = useSelector((state) => state)

  const { leaveType, leaveBalance } = useSelector(state => state)
  const navigate = useNavigate()

  return (
    <Box p={4} mt={1} component="form" onSubmit={onSubmit}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
        <Box >
          <Typography variant='h2'>
            Leave Application
          </Typography>
          <Typography variant='h6' color="error">
            {fields.err}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant='h3' color="grey.main">
            Application Date
          </Typography >
          <Typography variant='h3' ml={3}>
            {moment().format("DD MMMM YYYY")}
          </Typography>
        </Box>


      </Box>
      <Box sx={{ display: "flex" }} mt={4}>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Typography variant='h3' mt={3}>
            Leave Date:
          </Typography>


          <Box mt={2}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                height: '62px',
              }}
            >
              <Box sx={{ display: 'flex', width: '100%' }}>
                <DesktopDatePicker
                  // disablePast
                  shouldDisableDate={disableDates}
                  renderInput={(props) => {
                    return <CustomInput {...props} sx={{ height: '100%' }} />
                  }}
                  inputFormat="DD-MM-yyyy"
                  disabled={
                    loading ||
                    !(holiday.data && Array.isArray(holiday.data)) ||
                    holiday.loading
                  }
                  value={fields.from}
                  onChange={(e) => {
                    const changedVal = {}
                    changedVal['from'] = e
                    changedVal['to'] = e
                    setFields({ ...fields, err: '', ...changedVal })
                  }}
                  type="date"
                />
                <Box mr={4}></Box>
                <CustomRadioButtons
                  options={
                    !fields.moreThanOneDay
                      ? HALF_TYPE
                      : [HALF_TYPE[0], HALF_TYPE[2]]
                  }
                  value={fields.from_time_duration}
                  onChange={(e) => {
                    setFields({ ...fields, from_time_duration: e.target.value })
                  }}
                  sx={{ height: '56px', marginTop: '7px' }}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <Checkbox
              checked={fields.moreThanOneDay}

              disabled={
                loading ||
                !(holiday.data && Array.isArray(holiday.data)) ||
                holiday.loading
              }
              onChange={(e) => {
                const changedVal = {}
                changedVal['moreThanOneDay'] = e.target.checked

                if (
                  e.target.checked &&
                  fields.from_time_duration == HALF_TYPE_OBJ.FIRST_HALF
                ) {
                  changedVal['from_time_duration'] = HALF_TYPE_OBJ.SECOND_HALF
                }

                if (e.target.checked) {
                  changedVal['to'] = moment(fields.from).add(
                    1,
                    'days',
                  )
                } else {
                  changedVal['to'] = moment(fields.from)
                }

                changedVal['leave_type'] = null
                changedVal['to_time_duration'] = HALF_TYPE_OBJ.FULL_DAY
                changedVal['lwp'] = false
                setFields({ ...fields, ...changedVal })
              }}
            />
            <Typography variant="h6">Leave more than one day</Typography>
          </Box>
          {
            <Collapse in={fields.moreThanOneDay}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <DesktopDatePicker
                  minDate={
                    fields.moreThanOneDay
                      ? moment(fields.from).add(1, 'days')
                      : undefined
                  }

                  maxDate={
                    fields.moreThanOneDay
                      ? moment(fields.from).endOf("month")
                      : undefined
                  }
                  renderInput={(props) => {
                    return <CustomInput {...props} sx={{ height: '100%' }} />
                  }}
                  shouldDisableDate={disableDates}
                  inputFormat="DD-MM-yyyy"
                  disabled={
                    loading ||
                    !(holiday.data && Array.isArray(holiday.data)) ||
                    holiday.loading
                  }
                  value={fields.to}
                  onChange={(e) =>
                    setFields({ ...fields, err: '', to: e })
                  }
                  type="date"
                />
                <Box mr={4}></Box>
                <CustomRadioButtons
                  options={[...HALF_TYPE].splice(0, 2)}
                  value={fields.to_time_duration}
                  onChange={(e) => {
                    setFields({ ...fields, to_time_duration: e.target.value })
                  }}
                  sx={{ height: '56px', marginTop: '7px' }}
                />
              </Box>
            </Collapse>
          }


          {fields.leave_count <= 1 && <>

            <Typography variant='h3' mt={3}>
              Leave Type:
            </Typography>
            <Box sx={{ display: "flex", flex: "none" }} mt={3}>
              <CustomRadioButtons
                options={leaveBalance && leaveBalance.data && leaveBalance.data.data && leaveBalance.data.data.leaves ? leaveBalance.data.data.leaves.map((item) => ({ disabled: (fields.leave_count > item.count), label: item.leave_type.short_name + "(" + item.count + ")", value: item.leave_type._id })) : []}
                value={fields.leave_type}
                onChange={(e) => {
                  setFields({ ...fields, leave_type: e.target.value, lwp: false })
                }}
              />
            </Box>
          </>}
          {
            leaveBalance && leaveBalance.data && leaveBalance.data.data && leaveBalance.data.data.leaves && leaveBalance.data.data.leaves.reduce((accumulator, currentValue) => (accumulator + currentValue.count), 0) == 0 ?

              <> <Typography variant='h5' color="error" mt={3}>
                OOPS! No Leave Remained.
              </Typography>
                <Typography variant='h3' mt={3}>
                  Apply For LWP
                </Typography>
                <Box>
                  <Checkbox checked={fields.lwp} onChange={(e) => { setFields({ ...fields, leave_type: null, lwp: !fields.lwp }) }} />
                </Box>


              </>
              : <></>
          }
          <Typography variant='h3' mt={3}>
            Reason:
          </Typography>
          <CustomInput
            disabled={loading}
            value={fields.remarks}
            onChange={(e) =>
              setFields({ ...fields, err: '', remarks: e.target.value })
            }
            type="text"
            multiline
            placeholder="Write reason of leave..."
            rows={2}
          />


          <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>

            <Box sx={{ display: "flex" }} mr={3}>
              <ResetButton
                loading={loading}
                type="reset"
                variant="text"
                onClick={() => { navigate("/calendar/") }}
                // color="primary"
                title=" Cancel Leave"
              >

              </ResetButton>
            </Box>
            <Box sx={{ display: "flex" }}>
              <SubmitButton

                loading={loading}
                type="submit"
                variant="contained"
                color="primary"
                title=" Confirm Leave"
              >

              </SubmitButton>
            </Box>
          </Box>
        </Box>


        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }} pl={3} pr={3}>
          <LeaveBalanceComponent userId={fields.userId} from={fields.from} selectedLeave={fields.leave_type} leaveCount={fields.leave_count} />

        </Box>
      </Box>
    </Box>
  )
}
export default LeaveRaiseUi
/*  <>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          position: 'relative',
        }}
        maxHeight="100%"
        onSubmit={onSubmit}
      >
        <DialogTitle variant="h2">
          {'Leave Application'}
          <Box component={'div'} mt={'7px'}>
            <Typography variant="h4" color={'red'}>
              {fields.err}{' '}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ width: '100%', display: 'flex' }}>
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              right: '24px',
              top: '24px',
              lineHeight: '24px',
            }}
          >
            <Typography
              fontWeight={300}
              variant="h4"
              sx={{ color: '#666666', fontSize: '24px' }}
            >
              Application Date&nbsp;&nbsp;
            </Typography>
            <Typography
              fontWeight={400}
              variant="h4"
              sx={{ color: '#262626', lineHeight: '32px' }}
            >
              {fields.applicationDate &&
                fields.applicationDate.format('DD MMMM YYYY')}{' '}
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px', width: '75%' }}>
            <Box>
              <Typography fontWeight={300} variant="h3">
                Leave Date :{' '}
              </Typography>
            </Box>
            <Box sx={{ marginTop: '7px' }}>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  height: '62px',
                }}
              >
                <Box sx={{ display: 'flex', width: '100%' }}>
                  <DesktopDatePicker
                    shouldDisableDate={disableDates}
                    renderInput={(props) => {
                      return <CustomInput {...props} sx={{ height: '100%' }} />
                    }}
                    inputFormat="DD-MM-yyyy"
                    disabled={
                      loading ||
                      !(holiday.data && Array.isArray(holiday.data)) ||
                      holiday.loading
                    }
                    value={fields.from}
                    onChange={(e) => {
                      const changedVal = {}
                      changedVal['from'] = e
                      if (!fields.moreThanOneDay) {
                        changedVal['to'] = e
                      }
                      setFields({ ...fields, err: '', ...changedVal })
                    }}
                    type="date"
                  />
                  <Box mr={4}></Box>
                  <CustomRadioButtons
                    options={
                      !fields.moreThanOneDay
                        ? HALF_TYPE
                        : [HALF_TYPE[0], HALF_TYPE[2]]
                    }
                    value={fields.from_time_duration}
                    onChange={(e) => {
                      setFields({ ...fields, from_time_duration: e.target.value })
                    }}
                    sx={{ height: '56px', marginTop: '7px' }}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
              <Checkbox
                checked={fields.moreThanOneDay}
                disabled={
                  loading ||
                  !(holiday.data && Array.isArray(holiday.data)) ||
                  holiday.loading
                }
                onChange={(e) => {
                  const changedVal = {}
                  changedVal['moreThanOneDay'] = e.target.checked

                  if (
                    e.target.checked &&
                    fields.from_time_duration == HALF_TYPE_OBJ.FIRST_HALF
                  ) {
                    changedVal['from_time_duration'] = HALF_TYPE_OBJ.SECOND_HALF
                  }

                  if (e.target.checked) {
                    changedVal['to'] = moment(fields.from).add(
                      1,
                      'days',
                    )
                  } else {
                    changedVal['to'] = moment(fields.from)
                  }

                  changedVal['to_time_duration'] = HALF_TYPE_OBJ.FULL_DAY

                  setFields({ ...fields, ...changedVal })
                }}
              />
              <Typography variant="h6">Leave more than one day</Typography>
            </Box>
            {
              <Collapse in={fields.moreThanOneDay}>
                <Box sx={{ display: 'flex', width: '100%' }}>
                  <DesktopDatePicker
                    minDate={
                      fields.moreThanOneDay
                        ? moment(fields.from).add(1, 'days')
                        : undefined
                    }
                    renderInput={(props) => {
                      return <CustomInput {...props} sx={{ height: '100%' }} />
                    }}
                    shouldDisableDate={disableDates}
                    inputFormat="DD-MM-yyyy"
                    disabled={
                      loading ||
                      !(holiday.data && Array.isArray(holiday.data)) ||
                      holiday.loading
                    }
                    value={fields.to}
                    onChange={(e) =>
                      setFields({ ...fields, err: '', to: e })
                    }
                    type="date"
                  />
                  <Box mr={4}></Box>
                  <CustomRadioButtons
                    options={[...HALF_TYPE].splice(0, 2)}
                    value={fields.to_time_duration}
                    onChange={(e) => {
                      setFields({ ...fields, to_time_duration: e.target.value })
                    }}
                    sx={{ height: '56px', marginTop: '7px' }}
                  />
                </Box>
              </Collapse>
            }
            <Box>
              <Typography fontWeight={300} variant="h3">
                Leave Type :{' '}
              </Typography>
            </Box>
            <Box sx={{ marginTop: '7px' }}>
              
              <CustomRadioButtons
                options={leaveType.data.map((leave)=>({label:leave.short_name,value:leave._id}))}
                value={fields.leaveType}
                onChange={(e) => {
                  setFields({ ...fields, leaveType: e.target.value })
                }}
              />
            </Box>

            <Box sx={{ marginTop: '7px' }}>
              <Typography fontWeight={300} variant="h3">
                Reason :{' '}
              </Typography>
            </Box>
            <Box>
              <CustomInput
                disabled={loading}
                value={fields.remarks}
                onChange={(e) =>
                  setFields({ ...fields, err: '', remarks: e.target.value })
                }
                type="text"
                multiline
                placeholder="Write reason of leave..."
                rows={2}
              />
            </Box>
            <Box sx={{ float: 'right', marginTop: '7px' }}>
              <Button
                sx={{
                  borderBottom: '1px solid #808080',
                  borderRadius: '0',
                  padding: '0px',
                  marginRight: '20px',
                }}
                LinkComponent={Link}
                onClick={() => navigate('/calendar/')}
              >
                Cancel Leave
              </Button>
              <Button
                loading={loading}
                type="submit"
                variant="contained"
                color="primary"
              >
                Confirm Leave
              </Button>
            </Box>
          </Box>
          <Box sx={{ width: '25%' }}>
            {fields.leaveType && (
              <>
                <Grid container mt={4}>
                  <Grid item xs={12}>
                    <Typography fontWeight={500} variant="h5">
                      Total Applied
                    </Typography>
                    <Typography fontWeight={500} variant="h5">
                      {fields.leaveCount}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography fontWeight={500} variant="h5">
                      Available {fields.leaveType.toUpperCase()}{' '}
                    </Typography>
                    <Typography fontWeight={500} variant="h5">
                      {fields.availableLeave}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography fontWeight={500} variant="h5">
                      Remained {fields.leaveType.toUpperCase()}{' '}
                    </Typography>

                    <Typography
                      fontWeight={500}
                      color={
                        fields.availableLeave - fields.leaveCount < 0
                          ? 'error'
                          : ''
                      }
                      variant="h5"
                    >
                      {fields.availableLeave - fields.leaveCount}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </DialogContent>
      </Box>
    </>
    */