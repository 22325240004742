import { useCallback, useEffect, useState } from "react"
import ReimberesementPageUI from "./ReimberesementPageUI"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { useParams } from "react-router-dom"
import { openModal } from "../../store/actions/modalAction"

import { getReimberesementApi, getReimberesementCountApi, updateReimberesementField } from "../../apis/reimbersement.api"
import ReimberesementCreateController from "./ReimberesementCreateController"
import moment from "moment"
import MessageDilog from "../../components/MessageDilog"
const ReimberesementController = ({ userId }) => {

    const [list, setList] = useState({})


    const [loading, setLoading] = useState(false)
    const [counts, setCounts] = useState({})

    const [listLoading, setListLoading] = useState(false)

    const dispatch = useDispatch()
    const params = useParams()

    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',

        date: moment(),
        userId: userId ?? '',
        status: null

    })

    const fetchList = () => {

        setLoading(true)

        const passFilters = { ...filters }
        passFilters['date'] = passFilters['date'].toISOString()

        dispatch(callApiAction(
            async () => await getReimberesementCountApi({ ...passFilters }),
            (response) => {
                setCounts(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(callApiAction(
            async () => await getReimberesementApi({ ...passFilters }),
            (response) => {
                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }

    

    // const onCreateBtnClick = () => {
    //     dispatch(openModal(<ReimberesementCreateController userId={userId} callBack={async () => { fetchList() }} />, "sm"))
    // }

    useEffect(() => {
        fetchList()

    }, [filters])

    const onUpdateField = useCallback(
        (fieldName, value, id, callBack = () => { }) => {



            dispatch(callApiAction(
                async () => await updateReimberesementField({ id, [fieldName]: value }),
                (response) => {
                    callBack(response)
                },
                (err) => {
                    dispatch(openModal({component:<MessageDilog title="server Error" message={err} />}))
                }
            ))

        }
        , [dispatch])

    return (
        <>
            <ReimberesementPageUI
                counts={counts}
                onUpdateField={onUpdateField}
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                isForEmployee={userId}

            />

        </>
    )
}
export default ReimberesementController