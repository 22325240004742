import { Avatar, Box, Button, ButtonGroup, CircularProgress, Grid, IconButton, Link, Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography, TableBody, ButtonBase } from "@mui/material"
import { CenteredBox } from "../../../components/layouts/common/boxes"
import { Cancel, CheckBox, CheckCircle, Visibility, Add } from "@mui/icons-material"
import DeleteIcon from '@mui/icons-material/Delete'
import { center } from "../../../assets/css/theme/common"
import { Skeleton } from "@mui/material"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import AddEmployeeTypeController from "../AddEmployeeTypeController"
import UpdateEmployeeTypeController from "../UpdateEmployeeTypeController"
import LaunchIcon from '@mui/icons-material/Launch';

const EmployeeTypeUI = ({ setState,callBack, loading, state, DeleteEmployeeType }) => {
    const dispatch = useDispatch();
    const addholiday = useCallback(() => {
        dispatch(openModal({
            title:"Add Employee Type",
            component: <AddEmployeeTypeController  callBack={callBack}/>, size: 'sm'
        }))
    }, [])
    const UpdateEmployeeType = useCallback((id, initialName) => {
        dispatch(openModal({ title:"Update Employee Type",component: <UpdateEmployeeTypeController callBack={callBack} id={id} initialName={initialName} />, size: 'sm' }))
    }, [])
    return <>
        {
            !state && !loading && <CenteredBox><Typography variant="h3">Loading Data...</Typography></CenteredBox>
        }
        <Box m={3}>
            <Box>
                <Typography sx={{
                    fontWeight: 700,
                    fontSize: "24px",
                    lineHeight: "40px",
                    color: "#0D0D0D"
                }}>Employee Type</Typography>
                <Button onClick={addholiday} sx={{
                    display: "flex",
                    height: "100%",
                    zIndex: 0,
                    textTransform: "capitalize",
                    boxShadow: "none",
                    border: "1px solid #393939",
                    width: "100%",
                    height: "56px",
                    ":hover": {
                        boxShadow: "none"
                    }
                }}>
                    <Add color="primary" /> &nbsp;Add Employee Type
                </Button>
            </Box>
            <TableContainer component={Box} mt={3} sx={{
                boxShadow: "0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)",
                borderRadius: "4px",
                border: "1px solid #BFBFBF"
            }}>
                <Table aria-label="collapsible table" sx={{}}>
                    <TableHead sx={{ background: "#F2F2F2" }}>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            loading &&
                            <>
                                <TableRow ><TableCell colSpan={5}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                <TableRow ><TableCell colSpan={5}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                <TableRow ><TableCell colSpan={5}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                            </>
                        }
                        {!loading && state && state.map((employeeType) => (
                            <TableRow
                                key={employeeType._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{employeeType.name}</TableCell>
                                <TableCell>
                                    <ButtonBase onClick={() => {
                                        DeleteEmployeeType(employeeType._id)
                                    }}>
                                        <DeleteIcon />
                                    </ButtonBase>
                                    <ButtonBase onClick={() => {
                                        UpdateEmployeeType(employeeType._id, employeeType.name)
                                    }}>
                                        <LaunchIcon />
                                    </ButtonBase>
                                </TableCell>
                            </TableRow>
                        ))}
                        {!loading && state.length == 0 &&
                            <TableRow >
                                <TableCell colSpan={5} align="Center" ><Typography sx={{ ...center }} variant="h3" >No Data</Typography> </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </>
}
export default EmployeeTypeUI