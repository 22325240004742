import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { useDispatch, useSelector } from 'react-redux'
import SubmitButton from "../../../components/button/SubmitButton"
import { closeModal } from "../../../store/actions/modalAction"
import LeaveSummaryController from './LeaveSummaryController';
import CompOffSummaryController from './CompOffSummaryController';



export default function SummaryMainDialog({ userId }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { user } = useSelector(state => state)
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(closeModal())
  }

  return (



    <Box sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }} maxHeight="100%" >
      <DialogTitle variant="h3">
        <Tabs
          value={value}
          onChange={handleChange}
        >
          <Tab value={0} label="Leaves" />
          <Tab value={1} label="Availed Comp-off" />
        </Tabs>
      </DialogTitle>
      <DialogContent>
        {value == 0 && <LeaveSummaryController userId={userId || user.data._id} />}
        {value == 1 && <CompOffSummaryController userId={userId || user.data._id} />}
      </DialogContent>      
    </Box>
  );
}