import EmployeeLeaveUi from './EmployeeLeaveUI'
import moment from 'moment'
import { useCallback, useState } from 'react'

const EmployyeLeavesController = () => {
  const [date, setDate] = useState(moment())
  const [selectedUser, setSelectedUser] = useState(null)
  const data = [
    {
      title: 'Today',
      start: new Date(),
      end: new Date(),
    },
    {
      title: 'Tommorrw',
      start: new Date(2023, 1, 28),
      end: new Date(2023, 1, 28),
    },

    {
      title: 'Tommorrw2',
      start: new Date(2023, 1, 28),
      end: new Date(2023, 1, 28),
    },
  ]
  const onUserChange = useCallback((user) => {
    setSelectedUser(user)
  }, [])
  return (
    <EmployeeLeaveUi
      date={date}
      selectedUser={selectedUser}
      setDate={setDate}
      onUserChange={onUserChange}
      data={data}
    />
  )
}
export default EmployyeLeavesController
