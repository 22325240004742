import axios from 'axios'
import { getHeaders } from '../utils/helper'
import endpoints from './endpoints'

export const fetchBranches = async () => {
  const callResponse = await axios({
    url: endpoints.branch,
    method: 'get',
    headers: getHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const createBranch = async (data) => {
  const callResponse = await axios({
    url: endpoints.branch,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const deleteBranch = async (data) => {
  const callResponse = await axios({
    url: endpoints.branch,
    method: 'delete',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const updateBranch = async (data) => {
  const callResponse = await axios({
    url: endpoints.branch,
    method: 'patch',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
