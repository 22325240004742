import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Avatar, Badge, Box, ButtonBase, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import { KeyboardArrowDown, NotificationsNone, PictureAsPdf } from '@mui/icons-material';
import { center } from '../../assets/css/theme/common';
import responsive from '../../assets/css/responsive';

export default function CompanyDocuments() {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const document = {
        backgroundColor:"#F2F2F2",
        borderRadius:"4px",
        padding:"12px 24px",
        ...responsive.document
    }
    const companyDocument = {
        ...responsive.companyDocument,
    }
    return (
        <>

            <ButtonBase  aria-describedby={id} onClick={handleClick} sx={document}>
                <Typography variant='h4' sx={companyDocument}>Company Documents</Typography>
                <KeyboardArrowDown color='red' />
            </ButtonBase>
            <Popover
                // sx={(theme) => ({ marginTop: theme.spacing(3) })}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

            >
                <List

                    sx={{ width: '380px', maxWidth: '100%', bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader  sx={(theme) => ({ color: theme.palette.text.primary })} color='text.primary'>

                            <Typography variant='h5'>
                            Company Documents
                            </Typography>

                        </ListSubheader>
                    }
                >
                    <ListItemButton href='https://firebasestorage.googleapis.com/v0/b/wehear-ox.appspot.com/o/payrolldocuments%2FHoliday%20List%20-%202023%20(1).pdf?alt=media&token=4dee5723-25aa-4249-ac42-08cfb4b6a591' target="_blank" >

                        <PictureAsPdf />
                        <Box ml={3} sx={{ flexDirection: "column", ...center, alignItems: "flex-start" }}>
                            <Typography variant='h6' sx={{ lineHeight: "100%" }}>Company Holidays</Typography>
                        </Box>

                    </ListItemButton>

                    <ListItemButton href='https://firebasestorage.googleapis.com/v0/b/wehear-ox.appspot.com/o/payrolldocuments%2FWEHWAR%20EMPLOYEE%20LEAVE%20POLICY.pdf?alt=media&token=5542f1fd-1082-400a-94fe-2ac58124b704' target="_blank" >

                        <PictureAsPdf />
                        <Box ml={3} sx={{ flexDirection: "column", ...center, alignItems: "flex-start" }}>
                            <Typography variant='h6' sx={{ lineHeight: "100%" }}>Leave Policy</Typography>
                        </Box>

                    </ListItemButton>

                    <ListItemButton href='https://firebasestorage.googleapis.com/v0/b/wehear-ox.appspot.com/o/payrolldocuments%2FWehear%20General%20Policies.pdf?alt=media&token=40cd8df5-c17c-43b4-9c3e-a584a551a853' target="_blank" >

                        <PictureAsPdf />
                        <Box ml={3} sx={{ flexDirection: "column", ...center, alignItems: "flex-start" }}>
                            <Typography variant='h6' sx={{ lineHeight: "100%" }}>Attendence Policy</Typography>
                        </Box>

                    </ListItemButton>



                </List>
            </Popover>
        </>
    );
}