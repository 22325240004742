import { LockOpen, Visibility } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  List,
  ListSubheader,
  ListItemButton,
  styled,
  ListItem,
  ListItemText,
  ButtonBase,
} from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { center } from "../../assets/css/theme/common";

import SubmitButton from "../../components/button/SubmitButton";
import {
  StyledSearchBar,

} from "../../components/inputs/SearchBar";

import { openModal } from "../../store/actions/modalAction";
import {
  SNACK_BAR_VARIETNS,
  USER_ROLES,

  USER_ROLES_NUMBER,
  USER_UPDATE_TYPE,
} from "../../utils/constants";

import ProfileDialog from "../profile/ProfileDialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import FileCopyIcon from "@mui/icons-material/FileCopy";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import UpdateUserPasswordController from "./update/UpdateUserPasswordController";
import UserCreateController from "./UserCreateController";
import { updateUserState } from "../../apis/user.api";
import { callApiAction } from "../../store/actions/commonAction";
import { callSnackBar } from "../../store/actions/snackbarAction";

const UserRow = memo(
  ({
    totalList,
    id,
    // name,
    // employeeId,
    // team,
    // email,
    // phone,
    // role,
    // parentName,
    // designation,
    // enable,

  }) => {
    const { user } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [fields, setFields] = useState({
      ...totalList
    })
    
    const [disableLoading, setdisableLoading] = useState(false);
    
    const onOpenViewMore = () => {
      dispatch(openModal({
        component: <ProfileDialog userId={id} />, size: "lg"
      }));
    };
    const updatePersonalDetails = () => {
      dispatch(
        openModal({
          title: "Update Personal Details",
          component: <UserCreateController userId={id} updateType={USER_UPDATE_TYPE.personalDetails} />,
          size: "lg"
        }
        )
      );
    };
    const updateCompanyDetails = () => {
      dispatch(
        openModal({
          title: "Update Company Details",
          component: <UserCreateController userId={id} updateType={USER_UPDATE_TYPE.companyDetails} />,
          size: "lg"
        }
        )
      );
    };
    const updateBankDetails = () => {
      dispatch(
        openModal({
          title: "Update Bank Details",
          component: <UserCreateController userId={id} updateType={USER_UPDATE_TYPE.bankDetails} />,
          size: "lg"
        }
        )
      );
    };
    const updateSalaryDetails = () => {
      dispatch(
        openModal({
          title: "Update Salary Details",
          component: <UserCreateController userId={id} updateType={USER_UPDATE_TYPE.salaryDetails} />,
          size: "lg"
        }
        )
      );
    };

    const updateDocumentDetails = () => {
      dispatch(
        openModal({
          title: "Update Document Details",
          component: <UserCreateController userId={id} updateType={USER_UPDATE_TYPE.documents} />,
          size: "lg"
        }
        )
      );
    };

    const updatePassword = () => {
      dispatch(
        openModal({
          title: "Update Password",
          component: <UpdateUserPasswordController userId={id} list={totalList} />,
          size: "sm"
        }
        )
      );
    };

    const enableChange = () => {

        setdisableLoading(true);
        dispatch(
          callApiAction(
            async () => await updateUserState({ userId: id }),
            (response) => {

              setdisableLoading(false);
              setFields({...fields,enabled:!fields.enabled})
              dispatch(callSnackBar("User active status changed.",SNACK_BAR_VARIETNS.suceess))
            },
            (err) => {
              setdisableLoading(false);
              dispatch(callSnackBar("User active status cant be changed.",SNACK_BAR_VARIETNS.error))
            }
          )
        );
      
    };
    return (
      <>
        <TableRow>
          <TableCell>{fields.employee_id}</TableCell>
          <TableCell>{fields.name}</TableCell>
          <TableCell>{fields.designation}</TableCell>
          <TableCell>{fields.team}</TableCell>
          <TableCell>{fields.email}</TableCell>
          <TableCell>{fields.phone}</TableCell>
          <TableCell>{ fields.first_parent && fields.first_parent.name ? fields.first_parent.name : "NA"}</TableCell>
          <TableCell>{USER_ROLES_NUMBER[fields.role].toUpperCase()}</TableCell>

          {(user.data.role === USER_ROLES.admin ||
            user.data.role === USER_ROLES.hr) && (
              <TableCell>
                {disableLoading?<CircularProgress />:<Tooltip title={`Desable User`}>
                  <FormControlLabel
                    sx={{
                      display: "block",
                    }}
                    control={
                      <Switch
                        checked={fields.enabled}
                        value
                        name="loading"
                        color="primary"
                        onClick={(e) => {

                          enableChange();
                        }}
                      />
                    }
                  />
                </Tooltip>}
              </TableCell>
            )}
          {(user.data.role === USER_ROLES.admin ||
            user.data.role === USER_ROLES.hr) && (
              <TableCell >
                <Box component="span" sx={{display:"flex"}}>
                <Tooltip title={`view ${fields.fir} details`}>
                  <IconButton onClick={onOpenViewMore}>
                    <Visibility />
                  </IconButton>
                </Tooltip>
                {user.data.role === USER_ROLES.hr && (
                  <>
                    <PopupState variant="popover" popupId="demo-popup-popover">
                      {(popupState) => (
                        <>
                          <Tooltip title={`Update Details`}>
                            <IconButton {...bindTrigger(popupState)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <Typography sx={{ p: 2 }}>
                              <List
                                sx={{
                                  width: "100%",
                                  maxWidth: "100%",
                                  bgcolor: "background.paper",
                                }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                  <ListSubheader
                                    component="div"
                                    variant="subtitle1"
                                    //sx={(theme) => ({ color: theme.palette.text.primary })}
                                    color="text.primary"
                                    id="nested-list-subheader"
                                  >
                                    <Typography variant="h5">
                                      Update Details
                                    </Typography>
                                  </ListSubheader>
                                }
                              >
                                <ListItemButton onClick={updatePersonalDetails}>

                                  <PersonOutlineIcon variant={"primary.main"} />
                                  &nbsp;&nbsp;Personal Details

                                </ListItemButton>
                                <ListItemButton onClick={updatePassword}>

                                  <LockOpen variant={"primary.main"} />
                                  &nbsp;&nbsp;Update Password

                                </ListItemButton>
                                <ListItemButton onClick={updateCompanyDetails}>

                                  <LocationCityIcon variant={"primary.main"} />
                                  &nbsp;&nbsp;Company Details

                                </ListItemButton>
                                <ListItemButton onClick={updateBankDetails}>

                                  <AccountBalanceIcon
                                    variant={"primary.main"}
                                  />
                                  &nbsp;&nbsp;Bank Details

                                </ListItemButton>
                                <ListItemButton onClick={updateSalaryDetails}>

                                  <AttachMoneyIcon variant={"primary.main"} />
                                  &nbsp;&nbsp;Salary Details

                                </ListItemButton>

                                <ListItemButton onClick={updateDocumentDetails}>

                                  <FileCopyIcon variant={"primary.main"} />
                                  &nbsp;&nbsp;Documents

                                </ListItemButton>
                              </List>
                            </Typography>
                          </Popover>
                        </>
                      )}
                    </PopupState>
                  </>
                )}
                </Box>
              </TableCell>
            )}
        </TableRow>
      </>
    );
  }
);

const UserPageUi = ({
  listLoading,
  filters,
  setFilters,
  list,
  roles,
  loading,
  onCreateBtnClick,
  fetchList,
  exportLoading

}) => {
  const user = useSelector((state) => state.user);
  
  return (
    <>
      <Box mt={3} mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box
            mb={4}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h3">Users</Typography>
            <Box
              pl={2}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <StyledSearchBar
                placeholder={"Search Name | Email | Usercode"}
                value={filters.search}
                onChange={(e) => {
                  setFilters({ ...filters, search: e.target.value });
                }}
              />

              {user.data &&
                (user.data.role === USER_ROLES.admin ||
                  user.data.role === USER_ROLES.hr) && (
                  <>
                    <Box pl={2} sx={{ display: "flex" }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        onChange={(e, newVal) => {
                          setFilters({
                            ...filters,
                            role: parseInt(newVal._id),
                          });
                        }}
                        options={[
                          { label: "All", _id: null },
                          ...Object.keys(USER_ROLES).map((key) => ({
                            label: key,
                            _id: USER_ROLES[key],
                          })),
                        ]}
                        sx={{
                          width: 300,
                          display: "flex",
                          "*": { display: "flex", justifyContent: "center" },
                        }}
                        renderInput={(params) => (
                          <StyledSearchBar
                            placeholder="Select Role"
                            {...params}
                          />
                        )}
                      />
                    </Box>

                    <Box pl={2} sx={{ display: "flex" }}>
                      <SubmitButton
                      
                        variant="contained"
                        onClick={onCreateBtnClick}
                        title={"Create User"}
                      />
                    </Box>
                  </>
                )}
            </Box>
          </Box>
          <Box sx={{display:"flex"}} mt={2} mb={4}>
         <Box> <SubmitButton
          loading={exportLoading}
                        variant="contained"
                        onClick={()=>fetchList(true)}
                        title={"Export"}
                      /></Box>
          </Box>
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead sx={{ background: "lightgray" }}>
                <TableRow>
                  <TableCell sx={{ minWidth: "200px" }}>Employee Id</TableCell>
                  <TableCell sx={{ minWidth: "200px" }}>Name</TableCell>
                  <TableCell>Designation</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell sx={{ minWidth: "200px" }}>Reporting Person</TableCell>
                  <TableCell>Role</TableCell>
                  {(user.data.role === USER_ROLES.admin ||
                    user.data.role === USER_ROLES.hr) && (
                      <TableCell>Active User</TableCell>
                    )}
                  {(user.data.role === USER_ROLES.admin ||
                    user.data.role === USER_ROLES.hr) && (
                      <TableCell>Action</TableCell>
                    )}
                </TableRow>
              </TableHead>

              <TableBody>
                {listLoading && (
                  <>
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Skeleton width={"100%"} height={"50px"} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Skeleton width={"100%"} height={"50px"} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Skeleton width={"100%"} height={"50px"} />
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {!listLoading &&
                  list &&
                  list.result &&
                  list.result.length <= 0 && (
                    <TableRow>
                      <TableCell colSpan={10} align="Center">
                        <Typography sx={{ ...center }} variant="h3">
                          No Data
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                {!listLoading &&
                  list &&
                  list.result &&
                  list.result.map((row, index) => (
                    <UserRow
                     
                      totalList={list.result[index]}
                      key={row._id}
                      id={row._id}
                     

                    />
                  ))}
              </TableBody>
            </Table>

            {list && list.total && list.total > 0 && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={list.total}
                rowsPerPage={parseInt(filters.pageSize)}
                page={parseInt(filters.pageNo) - 1}
                onPageChange={(e, pageNo) => {
                  setFilters({ ...filters, pageNo: pageNo + 1 });
                }}
                onRowsPerPageChange={(e) => {
                  setFilters({ ...filters, pageSize: e.target.value });
                }}
              />
            )}
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};
export default UserPageUi;
