import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Slide,
  Typography,
  styled,
} from '@mui/material'
import { List, ListItem, ListItemText } from '@mui/material'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-react'
import { useDispatch, useSelector } from 'react-redux'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import SubmitButton from '../../../components/button/SubmitButton'
import CustomInput from '../../../components/inputs/CustomInput'
import { CenteredBox } from '../../../components/layouts/common/boxes'
import CustomRadioButtons from '../../../components/layouts/common/CustomRadioButtons'
import { closeModal } from '../../../store/actions/modalAction'
import { HALF_TYPE, HALF_TYPE_OBJ, LEAVE_TYPES } from '../../../utils/constants'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getDailyUpdateOnDate } from '../../../apis/dailyupdate.api'
import { callApiAction } from '../../../store/actions/commonAction'
import { useCallback } from 'react'
import CustomDayPicker from '../../../components/layouts/common/CustomDayPicker'
import { openModal } from '../../../store/actions/modalAction'
import DailyUpdateEditor from './DailyUpdateEditor'


import { Add } from '@mui/icons-material'

const ListContainer = styled(Box)(({ theme }) => ({
  width: "100%",


}))

const ListItemContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  overflowX: "scroll",
  background: theme.palette.grey[200],
  width: "100%",
  borderRadius: 1,
  "*": {
    "ul": {
      paddingLeft: theme.spacing(4)
    },
    "ol": {
      paddingLeft: theme.spacing(4)
    },
    "b": {
      fontWeight: "bold"
    },
    "a": {
      color: "blue",
      cursor: "pointer"
    },

    "td": {
      border: "1px solid black",
      minWidth: "100px"
    }
  }

}))

const DailyUpdateUi = ({

  list,
  loading,
  filters,
  setFilters,
  onCreateButtonClick
}) => {

  const dispatch = useDispatch()


  const date = filters.date
  const setDate = (newDate) => { setFilters({ ...filters, date: newDate }) }
  const navigate = useNavigate()
  const [state, setState] = useState([])

  const user = useSelector((state) => state.user)
  return (
    <>

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", overflow: "hidden" }}>

        <CustomDayPicker date={date} setDate={setDate} />
        {(date.valueOf() <= moment().valueOf() && date.valueOf() >= moment().add(-2, "days").valueOf()) && <Button
          onClick={onCreateButtonClick}
          size='small'

          variant='outlined'
        >
          <Add color="primary" /> &nbsp;Add Update
        </Button>}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: "hidden",
          position: 'relative',
          width: "100%"
        }}
        maxHeight="100%"
      >
        {
          loading && <CenteredBox><CircularProgress></CircularProgress></CenteredBox>
        }
        {!loading && <ListContainer>
          {list.length > 0 ? (
            list.map((item) => {
              return (
                <ListItemContainer mt={3}>
                  <Box dangerouslySetInnerHTML={{
                    __html: item.description,
                  }} sx={(theme) => ({})} p={2}>

                  </Box>

                </ListItemContainer>
              )
            })
          ) : (
            <CenteredBox
              mt={5}
              mb={5}
            >
              <Typography variant="h3" >
                No Updates Found
              </Typography>
            </CenteredBox>
          )}
        </ListContainer>}
      </Box>
    </>
  )
}
export default DailyUpdateUi
