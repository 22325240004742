import {
    Box,
    Grid,
    Typography,
    Input
} from "@mui/material";
import { CenteredBox } from "../../components/layouts/common/boxes";


const SalaryDetails = ({ loading,
    onSubmit,
    errors,
    formValues,
    setFormValues,
    getErrorMessage }) => {
    const totalSalary = (parseInt(formValues.basic_salary) + parseInt(formValues.house_rent_allowance) + parseInt(formValues.conveyence_allowance) + parseInt(formValues.food_allowance) + parseInt(formValues.other_allowance) + parseInt(formValues.incencitive) + parseInt(formValues.medical_allowance))
    const totalDeduction = (parseInt(formValues.proffesional_tax) + parseInt(formValues.pf_percent) + parseInt(formValues.esic_percent))
    return <>

        <Box>
            <Box m={3} sx={{ height: "100%" }}>
                <Box p={3}>
                    {<Box p={1} sx={{ border: "1px solid black" }}>

                        <CenteredBox sx={{ flexDirection: "column" }}>
                            <Typography align="center" variant="h5">Wehear Innovation Pvt Ltd.</Typography>
                            <Typography align="center" variant="body1">805, Supath Complex, Opp. Rasranjan, Nr. Vijay Cross Road, Navrangpura, A'bad</Typography>
                        </CenteredBox>

                        <Box>
                            <Grid container spacing={0} sx={{ mt: 4 }}>
                                <Grid item xs={4} sx={{ border: 1, background: "rgba(0,0,0,.2)", borderBottom: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >PF Id</Typography></Grid>
                                <Grid item xs={4} sx={{ border: 1, background: "rgba(0,0,0,.2)", borderLeft: 0, borderBottom: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >UAN ID</Typography></Grid>
                                <Grid item xs={4} sx={{ border: 1, background: "rgba(0,0,0,.2)", borderLeft: 0, borderBottom: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >ESIC ID</Typography></Grid>
                            </Grid>
                            <Grid container spacing={0} >
                                <Grid item xs={4} sx={{ border: 1 }}>
                                    <Typography variant="body1" align="center" fontWeight="bold" >
                                        <Input
                                            size="small"
                                            fullWidth
                                            disableUnderline
                                            type="text"
                                            sx={{ "*": { textAlign: "center !important" } }}
                                            value={formValues.pf_id}
                                            onChange={(e) => { setFormValues({ ...formValues, err: "", pf_id: e.target.value }) }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ border: 1, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >
                                    <Input
                                        size="small"
                                        fullWidth
                                        disableUnderline
                                        type="text"
                                        sx={{ "*": { textAlign: "center !important" } }}
                                        value={formValues.uan_id}
                                        onChange={(e) => { setFormValues({ ...formValues, err: "", uan_id: e.target.value }) }}
                                    />

                                </Typography></Grid>
                                <Grid item xs={4} sx={{ border: 1, borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >
                                    <Input
                                        size="small"
                                        fullWidth
                                        disableUnderline
                                        type="text"
                                        sx={{ "*": { textAlign: "center !important" } }}
                                        value={formValues.esic_id}
                                        onChange={(e) => { setFormValues({ ...formValues, err: "", esic_id: e.target.value }) }}
                                    />

                                </Typography></Grid>
                            </Grid>



                            <Grid container spacing={0} sx={{ mt: 4 }}>
                                <Grid item xs={8} sx={{ border: 1, background: "rgba(0,0,0,.2)", borderBottom: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >EARNING</Typography></Grid>
                                <Grid item xs={4} sx={{ border: 1, background: "rgba(0,0,0,.2)", borderLeft: 0, borderBottom: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >DEDUCTIONS</Typography></Grid>

                            </Grid>
                            <Grid container spacing={0} sx={{}}>
                                <Grid item xs={2} sx={{ border: 1, background: "rgba(0,0,0,.2)", }}><Typography variant="body1" align="center" fontWeight="bold" >Pay Heads</Typography></Grid>
                                <Grid item xs={2} sx={{ border: 1, background: "rgba(0,0,0,.2)", }}><Typography variant="body1" align="center" fontWeight="bold" >Monthly Amount </Typography></Grid>
                                <Grid item xs={2} sx={{ border: 1, background: "rgba(0,0,0,.2)", }}><Typography variant="body1" align="center" fontWeight="bold" >YTD Amount</Typography></Grid>

                                <Grid item xs={2} sx={{ border: 1, background: "rgba(0,0,0,.2)", borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Deduction Head</Typography></Grid>
                                <Grid item xs={2} sx={{ border: 1, background: "rgba(0,0,0,.2)", borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >Monthly Amount</Typography></Grid>
                                <Grid item xs={2} sx={{ border: 1, background: "rgba(0,0,0,.2)", borderLeft: 0 }}><Typography variant="body1" align="center" fontWeight="bold" >YTD Amount</Typography></Grid>


                            </Grid>


                            <Grid container spacing={0} sx={{}}>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Basic Salary</Typography></Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}>
                                    <Typography variant="body1" align="center"  >
                                        <Input
                                            size="small"
                                            fullWidth
                                            disableUnderline
                                            type="number"
                                            min="0"
                                            inputProps={{ min: 0 }}
                                            inputMode="number"
                                            sx={{ "*": { textAlign: "center !important" } }}
                                            value={formValues.basic_salary}
                                            onChange={(e) => { setFormValues({ ...formValues, err: "", basic_salary: e.target.value }) }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{formValues.basic_salary * 12}</Typography></Grid>


                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Provident Fund</Typography></Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}>
                                    <Typography variant="body1" align="center"  >
                                        <Input
                                            size="small"
                                            fullWidth
                                            disableUnderline
                                            type="number"
                                            min="0"
                                            inputProps={{ min: 0 }}
                                            inputMode="number"
                                            sx={{ "*": { textAlign: "center !important" } }}
                                            value={formValues.pf_percent}
                                            onChange={(e) => { setFormValues({ ...formValues, err: "", pf_percent: e.target.value }) }}
                                        />
                                    </Typography></Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}><Typography variant="body1" align="center"  >{formValues.pf_percent * 12}</Typography></Grid>


                            </Grid>


                            <Grid container spacing={0} sx={{}}>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >House Rent Allowance</Typography></Grid>

                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}>
                                    <Typography variant="body1" align="center"  >
                                        <Input
                                            size="small"
                                            fullWidth
                                            disableUnderline
                                            type="number"
                                            min="0"
                                            inputProps={{ min: 0 }}
                                            inputMode="number"
                                            sx={{ "*": { textAlign: "center !important" } }}
                                            value={formValues.house_rent_allowance}
                                            onChange={(e) => { setFormValues({ ...formValues, err: "", house_rent_allowance: e.target.value }) }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >{formValues.house_rent_allowance * 12}</Typography></Grid>

                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Proffesional Tax</Typography></Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}>
                                    <Typography variant="body1" align="center"  >
                                        <Input
                                            size="small"
                                            fullWidth
                                            disableUnderline
                                            type="number"
                                            min="0"
                                            inputProps={{ min: 0 }}
                                            inputMode="number"
                                            sx={{ "*": { textAlign: "center !important" } }}
                                            value={formValues.proffesional_tax}
                                            onChange={(e) => { setFormValues({ ...formValues, err: "", proffesional_tax: e.target.value }) }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}><Typography variant="body1" align="center"  >{formValues.proffesional_tax * 12}</Typography></Grid>


                            </Grid>


                            <Grid container spacing={0} sx={{}}>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Conveyence Allowance</Typography></Grid>

                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}>
                                    <Typography variant="body1" align="center"  >
                                        <Input
                                            size="small"
                                            fullWidth
                                            disableUnderline
                                            type="number"
                                            min="0"
                                            inputProps={{ min: 0 }}
                                            inputMode="number"
                                            sx={{ "*": { textAlign: "center !important" } }}
                                            value={formValues.conveyence_allowance}
                                            onChange={(e) => { setFormValues({ ...formValues, err: "", conveyence_allowance: e.target.value }) }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}><Typography variant="body1" align="center"  >{formValues.conveyence_allowance * 12}</Typography></Grid>


                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >ESIC</Typography></Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}>
                                    <Typography variant="body1" align="center"  >
                                        <Input
                                            size="small"
                                            fullWidth
                                            disableUnderline
                                            type="number"
                                            min="0"
                                            inputProps={{ min: 0 }}
                                            inputMode="number"
                                            sx={{ "*": { textAlign: "center !important" } }}
                                            value={formValues.esic_percent}
                                            onChange={(e) => { setFormValues({ ...formValues, err: "", esic_percent: e.target.value }) }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}><Typography variant="body1" align="center"  >{formValues.esic_percent * 12}</Typography></Grid>


                            </Grid>



                            <Grid container spacing={0} sx={{}}>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Food Allowance</Typography></Grid>

                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}>
                                    <Typography variant="body1" align="center"  >
                                        <Input
                                            size="small"
                                            fullWidth
                                            disableUnderline
                                            type="number"
                                            min="0"
                                            inputProps={{ min: 0 }}
                                            inputMode="number"
                                            sx={{ "*": { textAlign: "center !important" } }}
                                            value={formValues.food_allowance}
                                            onChange={(e) => { setFormValues({ ...formValues, err: "", food_allowance: e.target.value }) }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}><Typography variant="body1" align="center"  >{formValues.food_allowance * 12}</Typography></Grid>

                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}></Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}></Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}></Grid>


                            </Grid>


                            <Grid container spacing={0} sx={{}}>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Other Allowance</Typography></Grid>

                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}>
                                    <Typography variant="body1" align="center"  >
                                        <Input
                                            size="small"
                                            fullWidth
                                            disableUnderline
                                            type="number"
                                            min="0"
                                            inputProps={{ min: 0 }}
                                            inputMode="number"
                                            sx={{ "*": { textAlign: "center !important" } }}
                                            value={formValues.other_allowance}
                                            onChange={(e) => { setFormValues({ ...formValues, err: "", other_allowance: e.target.value }) }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}><Typography variant="body1" align="center"  >{formValues.other_allowance * 12}</Typography></Grid>


                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}></Grid>


                            </Grid>


                            <Grid container spacing={0} sx={{}}>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Medical Allowance</Typography></Grid>

                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}>
                                    <Typography variant="body1" align="center"  >
                                        <Input
                                            size="small"
                                            fullWidth
                                            disableUnderline
                                            type="number"
                                            min="0"
                                            inputProps={{ min: 0 }}
                                            inputMode="number"
                                            sx={{ "*": { textAlign: "center !important" } }}
                                            value={formValues.medical_allowance}
                                            onChange={(e) => { setFormValues({ ...formValues, err: "", medical_allowance: e.target.value }) }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}><Typography variant="body1" align="center"  >{formValues.medical_allowance * 12}</Typography></Grid>


                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}></Grid>


                            </Grid>

                            <Grid container spacing={0} sx={{}}>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  >Incentive</Typography></Grid>

                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}>
                                    <Typography variant="body1" align="center"  >
                                        <Input
                                            size="small"
                                            fullWidth
                                            disableUnderline
                                            type="number"
                                            min="0"
                                            inputProps={{ min: 0 }}
                                            inputMode="number"
                                            sx={{ "*": { textAlign: "center !important" } }}
                                            value={formValues.incencitive}
                                            onChange={(e) => { setFormValues({ ...formValues, err: "", incencitive: e.target.value }) }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}><Typography variant="body1" align="center"  >{formValues.incencitive * 12}</Typography></Grid>


                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1 }}><Typography variant="body1" align="center"  ></Typography></Grid>
                                <Grid item xs={2} sx={{ border: 0, borderLeft: 1, borderRight: 0 }}></Grid>


                            </Grid>


                            <Grid container spacing={0} sx={{}}>
                                <Grid item xs={2} sx={{ border: 1, borderRight: 0, background: "rgba(0,0,0,.2)", }}><Typography variant="body1" align="center"  >Total Earning</Typography></Grid>
                                <Grid item xs={2} sx={{ border: 1, borderRight: 0, background: "rgba(0,0,0,.2)", }}><Typography variant="body1" align="center"  >{totalSalary}</Typography></Grid>
                                <Grid item xs={2} sx={{ border: 1, borderRight: 0, background: "rgba(0,0,0,.2)", }}><Typography variant="body1" align="center"  >{totalSalary * 12}</Typography></Grid>
                                <Grid item xs={2} sx={{ border: 1, borderRight: 0, background: "rgba(0,0,0,.2)", }}><Typography variant="body1" align="center"  >Total Deduction</Typography></Grid>
                                <Grid item xs={2} sx={{ border: 1, background: "rgba(0,0,0,.2)", }}><Typography variant="body1" align="center"  >{totalDeduction}</Typography></Grid>
                                <Grid item xs={2} sx={{ border: 1, background: "rgba(0,0,0,.2)", }}><Typography variant="body1" align="center"  >{totalDeduction * 12}</Typography></Grid>






                            </Grid>
                            <Box mt={2} mb={2}>
                                <Typography variant="h5" align="center">
                                    Net Pay = {(totalSalary * 12) - (totalDeduction * 12)}&nbsp;PA
                                </Typography>
                            </Box>

                        </Box>
                    </Box>

                    }
                </Box>
            </Box>



        </Box>

    </>

}
export default SalaryDetails